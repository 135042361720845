import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ProductGrid from '@yesplz/core-web/modules/products/ProductGrid';
import { NEXT_BEST_MATCH_SCORE } from '@yesplz/visualfilter/src/consts';
import { PRODUCTS_MAX_PRODUCTS } from 'config/consts';

export default function List({ counts = {}, categoryId, products, onScrollBottom }) {
  if (!products) return null;

  function renderProduct(product) {
    return (
      <ProductGrid
        key={product.product.productId}
        {...{
          categoryId,
          id: product.product.productId,
          brand: product.product.brandName,
          name: product.product.productName,
          imgSrc: product.product.frontImgSrc,
          price: product.product.salePrice,
          originalPrice: product.product.originalPrice,
        }}
      />
    );
  }

  const bestMatches = (products || [])
    .filter(product => product.score > NEXT_BEST_MATCH_SCORE)
    .map(renderProduct);

  const nextMatches = (products || [])
    .filter(product => product.score < NEXT_BEST_MATCH_SCORE)
    .map(renderProduct);


  let bestMatchesCount = 0;
  let nextMatchesCount = 0;
  if (counts.bestMatch)
    bestMatchesCount = (
      counts.bestMatch < PRODUCTS_MAX_PRODUCTS
        ? counts.bestMatch
        : PRODUCTS_MAX_PRODUCTS
    );
  if (counts.total) {
    if (counts.total > PRODUCTS_MAX_PRODUCTS && bestMatchesCount < PRODUCTS_MAX_PRODUCTS) {
      nextMatchesCount = PRODUCTS_MAX_PRODUCTS - bestMatchesCount;
    }
    else if (bestMatchesCount === PRODUCTS_MAX_PRODUCTS) {
      nextMatchesCount = 0;
    }
    else {
      nextMatchesCount = counts.total - counts.bestMatch;
    }
  }

  return (
    <div>
      <PerfectScrollbar
        className='ProductsList'
        style={{ height: '100%' }}
        onYReachEnd={onScrollBottom}
      >
        <div className="ProductsPage-ProductList ColTypeDouble">
          <div className="ProductsPage-products">
            <div className="container">
              {
                bestMatches.length > 0
                &&
                <>
                  <h4>
                    Top Search Results
                  </h4>
                  <p>{bestMatchesCount} items found</p>
                  <div className="ProductList-wrapper">
                    {bestMatches}
                  </div>
                </>
              }
              {
                nextMatches.length > 0
                &&
                <>
                  <h4>
                    {
                    bestMatches.length === 0
                      ? <>
                          Sorry, no exact matches are found.<br />
                          How about the next best matches?
                        </>
                      : 'How about the next best matches?'
                    }
                  </h4>
                  <p>{nextMatchesCount} items found</p>
                  <div className="ProductList-wrapper">
                    {nextMatches}
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
}
