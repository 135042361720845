class VfCatCfg {
  constructor(categoryCfg) {
    this.category = categoryCfg.category
    this.svgSource = categoryCfg.vfSvgSource
    this.svgUpdate = categoryCfg.vfSvgUpdate
    
    // Overriding
    this.overrideBodyPartGroupName =  {};
    if (typeof categoryCfg.overrideBodyPartGroupName === 'object') {
      this.overrideBodyPartGroupName = categoryCfg.overrideBodyPartGroupName;
    }
    else if (typeof categoryCfg.overrideBodyPartGroupName === 'function') {
      this.overrideBodyPartGroupName_DEPRECATED = categoryCfg.overrideBodyPartGroupName
    }
    if (typeof categoryCfg.overrideBodyPartGroupName_DEPRECATED === 'function') {
      this.overrideBodyPartGroupName_DEPRECATED = categoryCfg.overrideBodyPartGroupName_DEPRECATED
    }
    this.touchpointGroupName = categoryCfg.touchpointGroupName
    this.sanitizePropChange = categoryCfg.sanitizePropChange
    this.evaluateDisabledParts = categoryCfg.evaluateDisabledParts

    this.api_extra_param = categoryCfg['api_extra_param'] || ''

    // TODO: REMOVE
    this.partList = categoryCfg['partList']
    this.partListLabels = categoryCfg['partListLabels']

    this.parts = categoryCfg['parts']

    // TODO: Add config for other filters like design/materials, etc
    this.propDefaultVal = categoryCfg['defaultVal']
    this.tn = categoryCfg['tn']
    this.tnOptionAll = categoryCfg['tnOptionAll'] || {}

    this.presetsList = categoryCfg['presetsList']
    this.extraBodyGroups = categoryCfg['extraBodyGroups']
  }
  maxVal (prop) {
    return this.propMaxVal[prop]
  }
  defaultVal (prop) {
    return this.propDefaultVal[prop]
  }
  getTnClass(bodyPart, value) {
    return (
      this.tn[bodyPart]
      &&
      this.tn[bodyPart][value]
      &&
      this.tn[bodyPart][value].tnClass
        ? this.tn[bodyPart][value].tnClass
        : ''
    );
  }
}

export function getCatCfg (categoryCfg) {
  return new VfCatCfg(categoryCfg)
}
