import { findLabelByLng } from '@yesplz/core';
import Widget from '@yesplz/visualfilter/src/modules/Widget';

const CONTAINER_CLASS_NAME = `simple-presets-list`;

class SimpleSubtypesList extends Widget {
  defaultParams = {
  }

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.subtypes = {};
    this.labelElements = {};
  }

  handleLabelClick = (e) => {
    const button = e.target;
    const newSubtype = button.getAttribute('data-subtype');
    const { params } = this.state.filter;

    this.setFilter({
      params: {
        ...params,
        subtype: newSubtype !== params.subtype ? newSubtype : null,
      }
    });
  }

  didUpdate(prevState) {
    if (this.state.filter.categoryId !== prevState.filter.categoryId) {
      this.renderItems();
    }
    else if (this.state.filter.params && this.state.filter.params.subtype !== prevState.filter.params.subtype) {
      const subtype = this.state.filter.params.subtype;
      const prevSubtype = prevState.filter.params.subtype;

      if (subtype) {
        const button = this.element.querySelector(`[data-subtype="${subtype}"]`);
        if (button) button.classList.add('is-selected');
      }
      if (prevSubtype) {
        const button = this.element.querySelector(`[data-subtype="${prevSubtype}"]`)
        if (button) button.classList.remove('is-selected');
      }
    }
  }

  renderItems() {
    const { lng } = this.state.config;
    const { categoryId } = this.state.filter;
    const categorySettings = this.main.categories[categoryId];

    if (!categorySettings) return;

    this.element.className = CONTAINER_CLASS_NAME;
    this.element.innerHTML = '';
    this.subtypes = categorySettings.subtypesList || [];

    const presetsHtml = this.subtypes.map(({ label, value }) => {
      return `
        <li data-subtype="${value}">
          ${findLabelByLng(label, lng)}
        </li>
      `;
    });

    const html = `
      <ul>
        ${presetsHtml.join('')}
      </ul>
    `;

    this.element.insertAdjacentHTML('beforeend', html);

    this.element.querySelectorAll('[data-subtype]').forEach(button => {
      button.addEventListener('click', this.handleLabelClick);
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(this.subtypes);
    }
  }

  render() {
    this.renderItems();
    return this.element;
  }
}

export default (params) => {
  return new SimpleSubtypesList(params);
};
