import Handlebars from 'handlebars';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import Widget from '@yesplz/visualfilter/src/modules/Widget';
import { findLabelByLng } from '@yesplz/core';

const { document } = window;

const TEMPLATE = `
<div>
  {{#if displayChips}}
    <div id="preset-occasions-chips"></div>
  {{/if}}
  <ul>
    {{#if displayAll}}
      <li data-type="preset" data-preset-id="all">All</li>
    {{/if}}
    {{#each items}}
      <li data-remove data-type="{{this.type}}" data-{{this.type}}-id="{{this.id}}">
        {{this.label}}
      </li>
    {{/each}}
    {{#each items}}
      <li data-type="{{this.type}}" data-{{this.type}}-id="{{this.id}}">
        {{this.label}}
      </li>
    {{/each}}
  </ul>
</div>
`

class SimplePresetsOccasionsList extends Widget {
  defaultParams = {
    containerClassName: 'simple-presets-occasions-tags',
    displayPresets: true,
    displayOccasions: true,
    displayAll: true,
    displayChips: true,
  };

  constructor(params) {
    super(params);

    this.template = Handlebars.compile(TEMPLATE);

    const element = document.createElement('div');
    element.className = this.params.containerClassName;
    this.mainElement = element;
  }

  assignEventHandlers() {
    const childElements = this.mainElement.querySelectorAll('[data-preset-id], [data-occasion-id], [data-vibe-id]');
    childElements.forEach(childElement => {
      childElement.addEventListener('click', () => {
        const type = childElement.getAttribute('data-type');
        const id = childElement.getAttribute(`data-${type}-id`);
        const { presetsList, defaultVal } = this.main.categories[this.state.filter.categoryId];

        const { presetIndex, occasion, params } = this.state.filter;
        const vibe = params.vibe || null;
        const currentValue = { preset: presetIndex, occasion, vibe };

        const newId = (
          id !== currentValue[type]
            ? id
            : null
        );

        const paramsAll = {
          ...(defaultVal || {}),
          design: [],
          color: [],
          material: [],
        };

        this.setFilter({
          presetIndex: type === 'preset' ? newId : null,
          occasion: type === 'occasion' ? newId : null,
          params: {
            vibe: type === 'vibe' ? newId : null,
            ...paramsAll,
            ...(
              type === 'preset' && newId
                ? find(presetsList, { value: newId }).preset
                : {}
            ),
          },
      });
      });
    });
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.renderItems();
    }

    if (!isEqual(prevState.filter.params.vibe, this.state.filter.params.vibe)) {
      if (prevState.filter.params.vibe) {
        const vibes = prevState.filter.params.vibe;
        (Array.isArray(vibes) ? vibes : [vibes]).forEach(
          vibe => this.toggleChild('vibe', vibe, 'remove')
        );
      }
      if (this.state.filter.params.vibe) {
        const vibes = this.state.filter.params.vibe;
        (Array.isArray(vibes) ? vibes : [vibes]).forEach(
          vibe => this.toggleChild('vibe', vibe)
        );
      }
    }

    if (!isEqual(prevState.filter.occasion, this.state.filter.occasion)) {
      if (prevState.filter.occasion) {
        const items = prevState.filter.occasion;
        (Array.isArray(items) ? items : [items]).forEach(
          item => this.toggleChild('occasion', item, 'remove')
        );
      }
      if (this.state.filter.occasion) {
        const items = this.state.filter.occasion;
        (Array.isArray(items) ? items : [items]).forEach(
          item => this.toggleChild('occasion', item)
        );
      }
    }

    if (prevState.filter.presetIndex !== this.state.filter.presetIndex) {
      this.toggleChild('preset', prevState.filter.presetIndex, 'remove');
      this.toggleChild('preset', this.state.filter.presetIndex);
    }
  }

  toggleChild(type, id, action = 'add') {
    if (!id) return;

    const elements = this.mainElement.querySelectorAll(`[data-type="${type}"][data-${type}-id="${id}"]`);

    if (elements.length) {
      elements.forEach((element) => {
        if (element.hasAttribute('data-remove')) {
          // element.classList[action]('is-active');
        }
        else {
          element.classList[action]('is-active');
        }
      });
    }
  }

  renderItems() {
    const { lng } = this.state.config;
    const category = this.main.categories[this.state.filter.categoryId];

    if (!category) return;

    const items = [
      ...(
        category.presetsList && this.params.displayPresets
          ? category.presetsList.map(({ label, value: id }) => ({
              type: 'preset',
              id,
              label: findLabelByLng(label, lng)
            }))
          : []
      ),
      ...(
        category.occasionsList && this.params.displayOccasions
          ? category.occasionsList.map(({ label, value: id }) => ({
              type: 'occasion',
              id,
              label: findLabelByLng(label, lng)
            }))
          : []
      ),
      ...(
        category.vibesList && this.params.displayOccasions
          ? category.vibesList.map(({ label, value: id }) => ({
              type: 'vibe',
              id,
              label: findLabelByLng(label, lng)
            }))
          : []
      ),
    ];

    this.mainElement.innerHTML = this.template({
      items,
      displayAll: this.params.displayAll,
      displayChips: this.params.displayChips,
    });

    this.assignEventHandlers();
    
    if (this.params.displayChips) {
      setTimeout(() => {
        this.main.addWidget(
          this.main.widgets.Chips({
            container: '#preset-occasions-chips',
          })
        );
      }, 10);
    }
  }

  render() {
    this.renderItems();
    return this.mainElement;
  }
}

export default (params) => {
  return new SimplePresetsOccasionsList(params);
};
