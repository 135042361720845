import { extendedCategories as predefinedCategories, patternsFilterParams, materialsFilterParams } from '@yesplz/visualfilter/src/extended-configuration';
import axios from 'axios';
import { sortCategories, fillPublishedListItem } from '@yesplz/core';

let categories = {};
let remoteConfigStatus = 'loading';

function setConfiguration(baseCategories) {
  categories = sortCategories(baseCategories);
}

async function fetchRemoteConfiguration() {
  try {
    const { data: configuration } = await axios.get(process.env.REACT_APP_REMOTE_CONFIG_URL);

    const baseCategories = Object.entries(configuration).reduce((categories, [categoryId, category]) => {
      if (category.isPublished) {
        categories[categoryId] = {
          ...predefinedCategories[category.baseCategory],
          ...category,
          presetsList: category.presetsList || [],
          occasionsList: category.occasionsList || [],
          vibesList: category.vibesList || [],
          moodsList: category.moodsList || [],
          patterns: category.patterns || [],
          materials: category.materials || [],
          wash: fillPublishedListItem(predefinedCategories.wash, category.wash),
        };
      }
      return categories;
    }, {});

    setConfiguration(baseCategories);

    remoteConfigStatus = 'loaded';
  } catch(e) {
    remoteConfigStatus = 'error';
  }
}

setConfiguration(predefinedCategories);

fetchRemoteConfiguration();

const designFilterParams = patternsFilterParams;
const materialFilterParams = materialsFilterParams;

export {
  remoteConfigStatus,
  categories,
  patternsFilterParams,
  designFilterParams,
  materialsFilterParams,
  materialFilterParams,
};
