import React, {Component} from 'react'
import {Link, NavLink, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames';

import YESPLZ from '@yesplz/core-web/assets/svg/Y_E_S_P_L_Z.svg'
import YESPLZb from '@yesplz/core-web/assets/svg/Y_E_S_P_L_Z_black.svg'
import close from '@yesplz/core-web/assets/svg/close.svg'
import DefaultPNG from 'assets/images/landing-intro.jpg'
import womenPNG from '@yesplz/core-web/assets/images/global-menu-women.png'
import menPNG from '@yesplz/core-web/assets/images/global-menu-men.png'

import instagramLogoImg from '@yesplz/core-web/assets/images/instagram.svg';

import withOnboarding from '@yesplz/core-web/hoc/withOnboarding';

// import menuItems from 'config/menuItems';

import './GlobalMenu.scss'

class GlobalMenu extends Component {
  static propTypes = {
    isOpenMenu: PropTypes.bool,
    isHomePage: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    onToggleGlobalMenu: PropTypes.func,
    closeGlobalMenu: PropTypes.func,
  }

  static defaultProps = {
    isOpenMenu: false,
    isHomePage: false,
    onToggleGlobalMenu () {
    }
  }

  constructor (props) {
    super(props)
    this.state = {
      isHomePage: false,
      expanded: false,
      slidingUp: false,
      resetAndTranslated: false,
      slidingDown: false,
      bitMap: {
        women: womenPNG,
        men: menPNG
      }
    }

    this.redirectTimeout = null;
    this.containerRef = React.createRef();
    this.buttonsContainerRef = null;
  }

  componentDidMount() {
    if (this.props.isHomePage) {
      this.initiateRedirectTimeout();
      this.setState({
        isHomePage: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.isHomePage && this.props.isHomePage)
      ||
      (this.isExpanded(prevProps, prevState) && !this.isExpanded() && this.props.isHomePage)
    ) {
      this.initiateRedirectTimeout();
    }
    else if (
      (prevProps.isHomePage && !this.props.isHomePage && this.redirectTimeout)
      ||
      (!this.isExpanded(prevProps, prevState) && this.isExpanded())
    ) {
      this.clearRedirectTimeout();
    }

    if (prevProps.isHomePage && !this.props.isHomePage) {
      this.homeSlideUp();
    }
    else if (!prevProps.isHomePage && this.props.isHomePage) {
      this.homeslidingDown();
    }
  }

  clearRedirectTimeout() {
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout);
    }
  }

  initiateRedirectTimeout() {
    this.clearRedirectTimeout();
    this.redirectTimeout = setTimeout(() => {
      this.goToWomen();
    }, 10000);
  }

  onToggleGlobalMenu = (e) => {
    e.stopPropagation();
    if (this.props.isHomePage) {
      this.goToWomen()
    } else {
      this.props.onToggleGlobalMenu()
    }
  }

  onToggleHomeMenu = (e) => {
    e.stopPropagation();
    const {expanded} = this.state
    if (!expanded) {
      this.setState({expanded: !expanded})
    } else {
      this.setState({expanded: !expanded})
    }
  }

  goToWomen = (filters) => {
    this.props.closeGlobalMenu()
    this.setState({expanded: false})
    this.props.history.push('/women');
  }

  onExpand = () => {
    this.setState({expanded: true})
  }

  onWheel = () => {
    const {isHomePage} = this.props
    if (isHomePage && !this.isExpanded()) {
      this.props.history.push('/women');
    }
  }

  isExpanded(props, state) {
    const {isOpenMenu, isHomePage} = props || this.props;
    const { expanded } = state || this.state;
    return (!isHomePage && isOpenMenu) || (isHomePage && expanded);
  }

  homeSlideUp() {
    const handleTransitionEnd = () => {
      this.setState({
        slidingUp: false,
        isHomePage: false,
        resetAndTranslated: true,
      });
      this.containerRef.current.removeEventListener('transitionend', handleTransitionEnd);

      setTimeout(() => {
        this.setState({
          resetAndTranslated: false,
        });
      }, 100);
    }

    this.containerRef.current.addEventListener('transitionend', handleTransitionEnd);
    this.setState({
      slidingUp: true,
    });
  }

  homeslidingDown() {
    const handleTransitionEnd = () => {
      this.setState({
        slidingDown: false,
      });
    }

    this.containerRef.current.addEventListener('transitionend', handleTransitionEnd);
    this.setState({
      resetAndTranslated: true,
      isHomePage: true,
    });

    setTimeout(() => {
      this.setState({
        resetAndTranslated: false,
        slidingDown: true,
      });
    }, 100);
  }

  render () {
    const {isOpenMenu, isOnboarding} = this.props;
    const {slidingUp, resetAndTranslated, slidingDown, isHomePage} = this.state;
    const isexpanded = this.isExpanded();

    return (
      <div
        className={classnames('Global-Menu', {
          'show': isOpenMenu,
          'home': isHomePage,
          'not-home': !isHomePage,
          'expanded': isexpanded,
          'slidingUp': slidingUp,
          'resetAndTranslated': resetAndTranslated,
          'slidingDown': slidingDown,
        })}
        ref={this.containerRef}
        onWheel={this.onWheel} onClick={() => {
          if (isHomePage && !isexpanded)
            this.goToWomen();
        }}>
        <div className={`left-menu ${isexpanded ? 'expanded' : ''}`}>
          <div className='header'>
            <button
              onClick={isHomePage ? this.onToggleHomeMenu : this.onToggleGlobalMenu}
              className={classnames('menu-icon menu-icon-sidebar', {
                'expanded': isexpanded,
                isOnboarding: isOnboarding,
              })}>
              {isexpanded && <img className='btn-close' alt='' src={close}/>}
              {!isexpanded && <div>=</div>}
            </button>
            <Link to={isHomePage ? '/women' : '/'} onClick={this.goToWomen}>
              <img alt='YESPLZ' src={YESPLZ}/>
            </Link>
          </div>

          <div className='content-menu'>
            <div className={`menu-container ${isexpanded ? 'expanded' : ''}`}>
              <div className="menu">
                {/* {menuItems.map((topItem, topIndex) => (
                  <div className='top-container' key={topIndex}>
                    <h2>{topItem.label}</h2>
                    <ul>
                      {topItem.children.map((item, index) => (
                        <li key={index}>
                          {item && <Link
                            to={item.to}
                            onClick={isHomePage ? this.onToggleHomeMenu : this.onToggleGlobalMenu}
                          >{item.label}</Link>}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))} */}

                <div className="company-info">
                  <p>
                    Do you want to try our Style Filter and You May Also Like on your website?
                    <br />
                    Contact us for a free trial or 30 min free consultation!
                  </p>
                  <p>
                    <a href="mailto:hello@yesplz.ai">hello@yesplz.ai</a>
                  </p>
                  <p className="instagram-line">
                    <a target="__blank" href="https://www.instagram.com/yesplz.ai/">
                      <img className="instagram-logo" src={instagramLogoImg} alt="" />
                    </a>
                  </p>
                  <p className="back-to-website">
                    <a href="https://yesplz.ai/">Back to YesPlz.AI Company Website</a>
                  </p>
                </div>
              </div>
            </div>
            <div className={`content-image ${isexpanded || isHomePage ? 'show' : ''} ${isexpanded ? 'expanded' : ''}`}>
              <img alt='' src={DefaultPNG}/>
            </div>
          </div>
        </div>

        {isHomePage && <div className={`right-menu ${isexpanded ? 'expanded' : ''} ${isexpanded ? 'expanded' : ''}`}>
          <div className='left'>
            <img alt='YESPLZ' src={YESPLZb}/>
            <p>CLOTHES JUST FOR YOU</p>
            <span>Our Artificial Intelligence will find the matching items across from fashion websites just for you.</span>
            <div className='right-menu__btns'>
              <NavLink to='/women' onClick={(e) => {
                // e.preventDefault();
                e.stopPropagation();
              }}>Women</NavLink>
              <NavLink to='/men' onClick={(e) => {
                // e.preventDefault();
                e.stopPropagation();
              }}>Men</NavLink>
            </div>
          </div>
          <div className='right'>
          </div>
        </div>}
      </div>
    )
  }
}

export default withRouter(withOnboarding(GlobalMenu))
