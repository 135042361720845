import React from 'react'
import PropTypes from 'prop-types'
import AngleLeft from '@yesplz/core-web/assets/svg/angle-left.svg'
import AngleRight from '@yesplz/core-web/assets/svg/angle-right.svg'
import './FilterNavigation.scss'

const FilterNavigation = ({ onPrev, onNext }) => (
  <div className='FilterNavigation'>
    <div className='FilterNavigation-prevButton' onClick={onPrev}>
      <img src={AngleLeft} alt='Previous' />
    </div>
    <div className='FilterNavigation-nextButton' onClick={onNext}>
      <img src={AngleRight} alt='Next' />
    </div>
  </div>
)

FilterNavigation.propTypes = {
  onPrev: PropTypes.func,
  onNext: PropTypes.func
}

export default FilterNavigation
