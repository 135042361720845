import { useState, useEffect } from 'react';
import { IS_MOBILE } from '@yesplz/core-web/config/constants'

var originalSetItem = localStorage.setItem;

localStorage.setItem = function(key, value) {
  var event = new Event('storageItemInserted');

  event.value = value;
  event.key = key;

  document.dispatchEvent(event);

  originalSetItem.apply(this, arguments);
};

function getStoredOnboardingValue(type) {
  return window.localStorage.getItem(`${type}_onboarding`) !== 'true';
}

export default () => {
  const type = IS_MOBILE ? 'mobile' : 'desktop';
  const [isOnboarding, setIsOnboarding] = useState(
    getStoredOnboardingValue(type)
  );

  function finishOnboarding() {
    window.localStorage.setItem(`${type}_onboarding`, 'true');
  }

  useEffect(() => {
    function hanleStorageChange(e) {
      if (e.key === `${type}_onboarding`) {
        setIsOnboarding(e.value !== 'true');
      }
    }
    document.addEventListener('storageItemInserted', hanleStorageChange);

    return () => {
      document.removeEventListener('storageItemInserted', hanleStorageChange);
    }
  }, []);

  return [
    isOnboarding,
    finishOnboarding,
  ];
}
