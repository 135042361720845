import Handlebars from 'handlebars';
import pick from 'lodash/pick';
import find from 'lodash/find';
import { findLabelByLng } from '@yesplz/core';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const { document } = window;

const persistentParams = ['brands', 'price'];

const TEMPLATE = `
<ul>
  {{#if displayAll}}
  <li data-preset-id="defaultValue">
    All
  </li>
  {{/if}}
  {{#each presets}}
    <li data-preset-id="{{this.id}}">
      {{this.label}}
    </li>
  {{/each}}
</ul>
`

class SimplePresetsList extends Widget {
  defaultParams = {
    containerClassName: 'simple-presets-list',
    displayAll: true,
    template: TEMPLATE,
  };

  constructor(params) {
    super(params);

    this.template = Handlebars.compile(this.params.template);

    const element = document.createElement('div');
    element.className = this.params.containerClassName;
    this.mainElement = element;

    this.prevPresetName = null;
  }

  didMount() {
    this.initClicks();
  }

  didUpdate(prevState) {
    const { categoryId: prevCategoryId } = prevState.filter;
    const { categoryId } = this.state.filter;
    if (categoryId !== prevCategoryId) {
      this.render();
      this.initClicks();
    }
    this.updateSelected();
  }

  initClicks() {
    const { categoryId } = this.state.filter;
    const presetElements = this.mainElement.querySelectorAll('[data-preset-id]');
    presetElements.forEach(presetElement => {
      presetElement.addEventListener('click', () => {
        const presetName = presetElement.getAttribute('data-preset-id');
        this.setPreset(presetName.replace(`${categoryId}-`, ''));
      });
    });
  }

  setPreset(newPresetName) {
    const { presetIndex: currentPresetIndex, categoryId, params, occasion } = this.state.filter;
    const { presetsList, defaultVal } = this.main.categories[categoryId];

    let presetName = currentPresetIndex === newPresetName ? 'defaultValue' : newPresetName;

    const preset = presetName === 'defaultValue' ? { preset: defaultVal || {} } : find(presetsList, { value: presetName });

    EventEmitter.emit('presetClick', {
      categoryId,
      preset: presetName,
    });

    this.setFilter({
      presetIndex: presetName !== 'defaultValue' ? presetName : null,
      occasion: presetName !== 'defaultValue' ? null : occasion,
      ...(
        preset ? {
          params: {
            ...pick(params, persistentParams),
            ...preset.preset,
          }
        } : {}
      ),
    });
  }

  updateSelected() {
    const { presetIndex } = this.state.filter;
    if (this.prevPresetName === presetIndex) return;

    if (this.prevPresetName) {
      this.toggle(this.prevPresetName);
    }

    this.toggle(presetIndex);

    this.prevPresetName = presetIndex;
  }

  toggle(presetIndex) {
    const { categoryId } = this.state.filter;
    const presetElement = this.mainElement.querySelector(`[data-preset-id="${categoryId}-${presetIndex}"]`);
    if (presetElement) {
      presetElement.classList.toggle('is-selected');
    }
  }

  render() {
    const { categoryId } = this.state.filter;
    const { lng } = this.state.config;

    if (!this.main.categories[categoryId]) return this.mainElement;

    const presets = (
      this.main.categories[categoryId].presetsList
      &&
      this.main.categories[categoryId].presetsList.length
        ? this.main.categories[categoryId].presetsList
            .map(preset => ({
              ...preset,
              id: `${categoryId}-${preset.value}`,
              label: findLabelByLng(preset.label, lng),
            }))
        : []
    );

    this.mainElement.innerHTML = this.template({
      presets,
      displayAll: this.params.displayAll,
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(presets);
    }

    return this.mainElement;
  }
}

export default (params) => {
  return new SimplePresetsList(params);
};
