import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import history from '@yesplz/core-web/config/history'
import { Button } from '@yesplz/core-web/ui-kits/buttons'
import withTrackingProvider from '@yesplz/core-web/hoc/withTrackingProvider'
import { ProductList } from '@yesplz/core-web/modules/products'
import { AdvancedPresetList } from '@yesplz/core-web/modules/presets'
import { SectionTitle } from '@yesplz/core-web/ui-kits/misc'
import { Input } from '@yesplz/core-web/ui-kits/forms'

import { setActiveCategory } from '@yesplz/core-redux/ducks/products'
import { setFilter } from '@yesplz/core-redux/ducks/filters'
import { setFAQPopupStatus, setSizePopupStatus } from '@yesplz/core-redux/ducks/popups'

import {
  CATEGORY_WPANTS,
  CATEGORY_WSHOES,
  CATEGORY_WTOP,
  CATEGORIES_LABELS,
  YESPLZ_VIDEO_LINK,
  INSTAGRAM_LINK
} from '@yesplz/core-web/config/constants'

import InstagramSvg from '@yesplz/core-web/assets/svg/instagram-glyph.svg'

import './home.scss'

class Home extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  }

  handleClickExpore = category => () => {
    const { setActiveCategory } = this.props
    setActiveCategory(category)
    history.push(
      `/products/${category}/list?listingView=single&page=new`
    )
  }

  handleClickEditorPick = (preset) => () => {
    const { setFilter, setActiveCategory } = this.props
    setActiveCategory(preset.category)
    setFilter(preset.category, this.getPresetFilter(preset))
    // `/products/${preset.category}/list?listingView=single&page=editorspick&preset=${formatPresetName(preset.name)}`
    history.push(
      `/products/${preset.category}/list?listingView=single&page=all`
    )
  }

  getPresetFilter = preset => {
    const customFilter = { ...preset }
    // ToDo: customFilter['preset'] = formatPresetName(preset.name)
    delete customFilter.category
    delete customFilter.name

    return customFilter
  }

  handleClickSendEmail = () => { }

  handleSizePopup = type => () => {
    const { setSizePopupStatus, setFAQPopupStatus } = this.props
    if (type === 'size') {
      setSizePopupStatus(true)
    } else if (type === 'faq') {
      setFAQPopupStatus(true)
    }
  }

  render () {
    return (
      <div id='MainScroll' className='Home' style={{ paddingBottom: 100 }}>
        {/* new arrival section */}
        <div className='Home-section'>
          <SectionTitle
            title='New Arrivals'
          />
          {
            [CATEGORY_WTOP, CATEGORY_WPANTS, CATEGORY_WSHOES].map(category => (
              <React.Fragment key={category}>
                <SectionTitle
                  title={CATEGORIES_LABELS[category]}
                  small
                  onClick={this.handleClickExpore(category)}
                />
                <ProductList
                  category={category}
                  className='Recommended-products'
                  customFilters={{ new: 1 }}
                  limitPerPage={3}
                  showOriginalPrice
                  show
                  combined
                  style={{ overflow: 'hidden' }}
                />
                <Button kind='secondary' onClick={this.handleClickExpore(category)} >
                  Explore Smart Filter
                </Button>
                <br />
              </React.Fragment>
            ))
          }
        </div>

        {/* editor's pick section */}

        <div className='Home-section'>
          <SectionTitle
            title='Editor Picks'
          />
          <div className='container-wide'>
            <AdvancedPresetList
              presetMatchesCount={4}
              useMinimalPreset
              titleBellowPreset
              defaultViewBoxSvg={[0, 0, 304, 214]}
              onClickGroupTitle={this.handleClickEditorPick}
            />
          </div>

        </div>

        <div className='Home-section'>
          <SectionTitle
            title='How it works'
          />
          <div className='container-wide'>
            <div className='Home-video'>
              <iframe width='100%' height='100%'
                      src={YESPLZ_VIDEO_LINK}
                      frameBorder='0'
                      title='Home Video'
                      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
              />
            </div>
          </div>
        </div>

        <div className='Home-section'>
          <div className='container-wide'>
            <div className='Home-Footer'>
              <div className='Home-Footer-Menu'>
                <div className='Title Title-main'><Link to={'#'}>YESPLZ</Link></div>
                <div className='Title Title-sub'><button onClick={this.handleSizePopup('faq')}>FAQ</button></div>
                <div className='Title Title-sub'><button onClick={this.handleSizePopup('size')}>MY SIZE</button></div>

                <div className='Icons'>
                  <a href={INSTAGRAM_LINK} target='_blank' rel='noopener noreferrer'><img src={InstagramSvg} alt='InstagramSvg' /></a>
                </div>
              </div>
              <div className='Home-Footer-MailForm'>
                <div className='Title'><h1>Let us know what you think</h1></div>
                <form action=''>
                  <Input type='text' label='YOUR EMAIL' name='email' customStyle={{
                    width: '50%'
                  }} />
                  <Input type='text' label='YOUR MESSAGE' name='message' />
                  <Button kind='secondary' onClick={this.handleClickSendEmail} >
                    SEND
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  connect(
      null,
      {
        setFilter,
        setSizePopupStatus,
        setFAQPopupStatus,
        setActiveCategory
      }
  ),
  withTrackingProvider('Home')
)(Home)
