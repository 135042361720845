import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import memoize from 'lodash/memoize'
import debounce from 'lodash/debounce'

import { FILTER_OCCASIONS, FILTER_SALES, FILTER_PRICES, SIZES } from '@yesplz/core-web/config/constants'
import { setSecondaryFilter } from '@yesplz/core-redux/ducks/filters'
import history from '@yesplz/core-web/config/history'
import FilterGroup from '@yesplz/core-web/modules/filters/FilterGroup'
import TextButton from '@yesplz/core-web/ui-kits/buttons/TextButton'
import SidebarItem from './SidebarItem'

const SidebarSecondaryFilters = ({ filters, activeCategory, redirect, onSubmit }) => {
  const [ localFilters, changeFilters ] = useState(filters)

  const filterSizes = getFilterSizes(activeCategory)

  useEffect(() => {
    if (redirect) {
      history.push(redirect)
    } else {
      changeFilters({
        ...filters
      })
    }
  }, [filters, redirect])

  const handleFilterChange = (name, values) => {
    const updatedFilters = {
      ...localFilters,
      [name]: values
    }
    // change local state for better performance
    changeFilters(updatedFilters)

    // update redux state (debounced)
    onSubmit(updatedFilters)
  }

  const clearFilter = () => (
    changeFilters({})
  )

  return (
    <div className='SidebarSecondaryFilters'>
      <SidebarItem title='Occasions'>
        <FilterGroup
          name='occasions'
          options={[
            {
              name: 'all',
              label: 'All Occasions'
            },
            ...FILTER_OCCASIONS
          ]}
          values={localFilters['occasions']}
          onChange={handleFilterChange}
        />
      </SidebarItem>

      <SidebarItem title='Sales'>
        <FilterGroup
          name='sale'
          options={[
            {
              name: 'all',
              label: 'All Sales'
            },
            ...FILTER_SALES
          ]}
          values={localFilters['sale']}
          onChange={handleFilterChange}
        />
      </SidebarItem>

      <SidebarItem title='Prices'>
        <FilterGroup
          name='price'
          options={FILTER_PRICES}
          values={localFilters['price']}
          onChange={handleFilterChange}
        />
      </SidebarItem>

      <SidebarItem title='Sizes'>
        <FilterGroup
          name='sizes'
          options={filterSizes}
          values={localFilters['sizes']}
          onChange={handleFilterChange}
        />
      </SidebarItem>

      <TextButton onClick={clearFilter} style={{ margin: '0 40px 70px' }}>Clear all</TextButton>
    </div>
  )
}

const getFilterSizes = memoize((category) => (
  [
    ...SIZES[category].ids.map(id => ({ name: id, label: id })),
    /**
     * @todo: let's keep "My Sizes" disabled for now until api finished
     */
    {
      name: 'mysizes',
      label: 'My Sizes',
      disabled: true,
      fallbackURL: `/profile/sizes/${category}/regular`
    }
  ]
))

SidebarSecondaryFilters.propTypes = {
  filters: PropTypes.shape({}),
  activeCategory: PropTypes.string,
  redirect: PropTypes.string,
  onSubmit: PropTypes.func
}

SidebarSecondaryFilters.defaultProps = {
  filters: {},
  onSubmit: (productListConfig) => { console.debug('Unhandled `onSubmit` prop in `ProductsFilter`', productListConfig) }
}

const mapStateToProps = (state) => ({
  filters: state.filters.secondary
})

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: debounce((filters) => {
    // set secondary filters
    dispatch(setSecondaryFilter(filters))

    // redirect to chosen category
    history.push(`/products/${props.activeCategory}/list`)
  }, 1000)
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarSecondaryFilters)
