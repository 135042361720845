const persistMigrations = {
  1: (state) => ({
    ...state,
    favoritesStorage: {
      products: (
        state.favoritesStorage && state.favoritesStorage.items
          ? state.favoritesStorage.items
          : state.favoritesStorage && state.favoritesStorage.products
            ? state.favoritesStorage.products
            : {}
      ),
      styles: (
        state.favoritesStorage && state.favoritesStorage.styles
          ? state.favoritesStorage.styles
          : {}
      ),
    },
  })
};

export default persistMigrations;