import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import capitalize from 'lodash/capitalize';
// import { findLabelByLng } from '@yesplz/core';
// import client from '@yesplz/client';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

function convertList(list = {}) {
  return Object.values(list)
    .map(({ value, isActive }) => ({ label: value, value, isActive }))
    .filter(({ value }) => value)
    .sort((a, b) => (
      a.isActive === b.isActive
        ? 0
        : a.isActive
          ? -1
          : 1
    ));
}

class ListFilter extends Widget {
  defaultParams = {
    title: 'List',
    filterParamName: null,
    listParamName: null,
    listMiddleware: null,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.ul = document.createElement('ul');

    this.mainElement.appendChild(this.ul);
  }

  didUpdate(prevState) {
    const { listParamName, filterParamName } = this.params;

    if (!isEqual(
      prevState.search?.filter?.[listParamName],
      this.state.search?.filter?.[listParamName]
    )) {
      this.renderItems();
    }
    else if (
      !isEqual(prevState.filter[filterParamName], this.state.filter[filterParamName])
    ) {
      const selectedItems = this.state.filter[filterParamName];
      const prevSelectedItems = prevState.filter[filterParamName];

      const selected = difference(selectedItems, prevSelectedItems);
      const deselected = difference(prevSelectedItems, selectedItems);

      selected.forEach(value => {
        const button = this.mainElement.querySelector(`[data-item-value="${value}"]`);
        if (button) button.classList.add('is-selected');
      });
      deselected.forEach(value => {
        const button = this.mainElement.querySelector(`[data-item-value="${value}"]`)
        if (button) button.classList.remove('is-selected');
      });
    }
  }

  handleItemClick = (e) => {
    const button = e.target;
    if (!button.className.includes('is-disabled')) {
      const newValue = button.getAttribute('data-item-value');
      this.main.toggleList(this.params.filterParamName, newValue);

      EventEmitter.emit(
        `txtSidebar${
          capitalize(this.params.filterParamName)
        }${(
          this.state.filter[this.params.filterParamName].includes(newValue)
            ? 'Applied'
            : 'Removed'
        )}`,
        newValue
      );
    }
  }

  renderItems() {
    const { listParamName, filterParamName  } = this.params;
    const items = (
      this.params.items
        ? this.params.items
        : typeof this.params.listMiddleware === 'function'
          ? this.params.listMiddleware(this.state.search?.filter?.[listParamName])
          : convertList(this.state.search?.filter?.[listParamName])
    );
    this.ul.innerHTML = '';
    // console.log(listParamName, 'items', items);
    items.forEach((item) => {
      const li = document.createElement('li');
      li.innerHTML = `${item.label}`;
      li.setAttribute('data-item-value', item.value);
      this.ul.appendChild(li);

      if (this.state.filter[filterParamName] && this.state.filter[filterParamName].includes(item.value)) {
        li.classList.add('is-selected');
      }

      if (!item.isActive) {
        li.classList.add('is-disabled');
      }

      li.addEventListener('click', this.handleItemClick);
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(items);
    }
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    this.renderItems();
    return this.mainElement;
  }
}

export default (params) => {
  return new ListFilter(params);
};
