import React from 'react'
import PropTypes from 'prop-types'

const TextButton = ({ children, onClick, style }) => (
  <button style={{...defaultStyle, ...style}} onClick={onClick}>{children}</button>
)

TextButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object
}

TextButton.defaultProps = {
  children: 'Clear all',
  style: {}
}

const defaultStyle = {
  fontSize: 14,
  fontWeight: 900,
  letterSpacing: 1,
  textDecoration: 'underline',
  backgroundColor: 'transparent',
  padding: 0,
  margin: '22px 0',
  border: 'none',
  outline: 'none'
}

export default TextButton
