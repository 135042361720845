import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import { VisualFilter } from '@yesplz/core-models'
import { LikeButton, CloseButton } from '@yesplz/core-web/ui-kits/buttons'
// import { FILTER_CATEGORY } from '@yesplz/core-web/config/constants'
import FilterNavigation from './FilterNavigation'

import './filter-panel.css'

export default class FilterPanel extends Component {
  static propTypes = {
    category: PropTypes.string.isRequired,
    isUnique: PropTypes.any,
    filters: PropTypes.object,
    favorite: PropTypes.bool,
    lastBodyPart: PropTypes.string,
    className: PropTypes.string,
    onInit: PropTypes.func,
    onFilterChange: PropTypes.func,
    onFilterLike: PropTypes.func,
    onClose: PropTypes.func,
    onBodyPartChange: PropTypes.func,
    onFinishedOnboarding: PropTypes.func,
    closable: PropTypes.bool,
    debugTouchArea: PropTypes.bool,
    disableFavorite: PropTypes.bool,
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired
  }

  static defaultProps = {
    filters: {},
    favorite: false,
    className: '',
    closable: true,
    disableFavorite: false,
    onInit: (visualFilter) => {},
    onFilterChange: (filters) => { console.debug('FilterPanel - filter changed', filters) },
    onFilterLike: (filters, favorite) => { console.debug('FilterPanel - filter like changed', filters, favorite) },
    onClose: () => { console.debug('FilterPanel - close button clicked') }
  }

  constructor (props) {
    super(props)
    this.state = {
      svgLoaded: false
    }
  }

  componentDidMount () {
    const {
      category, filters, debugTouchArea, lastBodyPart, onInit,
      onBodyPartChange, onFinishedOnboarding, isUnique
    } = this.props

    const svgId = isUnique ? `VisualFilter_${isUnique}` : 'VisualFilter'
    // initialize visual filter
    this.visualFilter = new VisualFilter(`#${svgId}`, {
      category: category,
      defaultState: filters,
      hideArrow: true,
      customViewBox: [30, -5, 250, 170],
      onFilterChange: this.handleBodyPartFilter,
      onPropChange: onBodyPartChange,
      onSVGLoaded: this.handleSVGLoaded,
      onFinishedOnboarding: onFinishedOnboarding,
      debugTouchArea: debugTouchArea,
      showHighlightOnBuild: true
    })

    this.visualFilter.setLastBodyPart(lastBodyPart)

    onInit(this.visualFilter)
  }

  componentDidUpdate (prevProps, prevState) {
    const { filters, lastBodyPart } = this.props
    const { svgLoaded } = this.state
    if (!isEqual(svgLoaded, prevState.svgLoaded) || !isEqual(filters, prevProps.filters)) {
      this.visualFilter.updateState(filters)
    }
    // when body part changed update visual filter lastBodyPart
    if (lastBodyPart !== prevProps.lastBodyPart) {
      this.visualFilter.setLastBodyPart(lastBodyPart)
    }
  }

  get handleSVGLoaded () {
    return () => {
      this.setState({
        svgLoaded: true
      })
    }
  }

  get fabricFilters () {
    const { details, pattern, solid, color } = this.props.filters
    return { details, pattern, solid, color }
  }

  get handleBodyPartFilter () {
    return (bodyPartFilters) => {
      const { filters, onFilterChange } = this.props
      onFilterChange({ ...filters, ...bodyPartFilters })
    }
  }

  get handleFabricFilter () {
    const { filters, onFilterChange } = this.props
    return (fabricFilters) => {
      onFilterChange({ ...filters, ...fabricFilters })
    }
  }

  get toggleLike () {
    const { filters, onFilterLike, favorite } = this.props

    return (e) => {
      e.preventDefault()
      e.stopPropagation()

      onFilterLike(filters, !favorite)
    }
  }

  render () {
    const {
      favorite,
      onClose,
      className,
      closable,
      category,
      disableFavorite,
      isUnique,
      onPrev,
      onNext
    } = this.props

    const svgId = isUnique ? `VisualFilter_${isUnique}` : 'VisualFilter'

    return (
      <div className={`FilterPanel ${className}`}>
        {/*{!disableFavorite && <div className='FilterCategory'>{FILTER_CATEGORY[category]}</div>}*/}
        {!disableFavorite && <LikeButton active={favorite} onClick={this.toggleLike} />}
        {
          !closable ? null : (
            <div className='FilterPanel-close' onClick={onClose}>
              <CloseButton />
            </div>
          )
        }
        <div className='FilterPanel-roundBackground' />
        <svg id={svgId}/>
        <FilterNavigation onPrev={onPrev} onNext={onNext} />
      </div>
    )
  }
}
