import axios from 'axios';
import { sortCategories, fillCategoriesByParent } from '@yesplz/core';

import designSolidSvg from '@yesplz/core-models/assets/svg/design-solid.svg';
import designPatternSvg from '@yesplz/core-models/assets/svg/design-pattern.svg';
import designDetailSvg from '@yesplz/core-models/assets/svg/patterns-detail.svg';
import designDestroyedSvg from '@yesplz/core-models/assets/svg/design-destroyed.svg';
// import designLightSvg from '@yesplz/core-models/assets/svg/design-light.svg';
import designLeatherSvg from '@yesplz/core-models/assets/svg/design-leather.svg';
import designDenimSvg from '@yesplz/core-models/assets/svg/design-denim.svg';
import designSlacksSvg from '@yesplz/core-models/assets/svg/design-slacks.svg';
import designWoolSvg from '@yesplz/core-models/assets/svg/design-wool.svg';
import designDownSvg from '@yesplz/core-models/assets/svg/design-down.svg';
// import designWorkoutSvg from '@yesplz/core-models/assets/svg/design-workout.svg';
// import designLeggingsSvg from '@yesplz/core-models/assets/svg/design-leggings.svg';
import designSuedeSvg from '@yesplz/core-models/assets/svg/design-suede.svg';
import designAnimalSvg from '@yesplz/core-models/assets/svg/pattern-animal.svg';
import designCottonSvg from '@yesplz/core-models/assets/svg/design-cotton.svg';
import patternFloralSvg from '@yesplz/core-models/assets/svg/pattern-floral.svg';
// import materialFleeceSvg from '@yesplz/core-models/assets/svg/material-fleece.svg';

import patternWashedDarkSvg from '@yesplz/core-models/assets/svg/pattern-washed_dark.png';
import patternWashedMediumSvg from '@yesplz/core-models/assets/svg/pattern-washed_medium.png';
import patternWashedLightSvg from '@yesplz/core-models/assets/svg/pattern-washed_light.png';
import patternStripeSvg from '@yesplz/core-models/assets/svg/pattern-stripe.svg';
import patternCheckerSvg from '@yesplz/core-models/assets/svg/pattern-checker.svg';
import patternGraphicSvg from '@yesplz/core-models/assets/svg/pattern-graphic.svg';
import patternColorBlockSvg from '@yesplz/core-models/assets/svg/pattern-color_block.svg';

import patternDot from '@yesplz/core-models/assets/svg/patterns-dot.svg';
import patternFruit from '@yesplz/core-models/assets/svg/patterns-fruit.svg';
import patternSparkle from '@yesplz/core-models/assets/svg/patterns-sparkle.svg';
import patternAbstract from '@yesplz/core-models/assets/svg/patterns-abstract.svg';

import materialGoldSvg from '@yesplz/core-models/assets/svg/material-gold.svg';
import materialRoseGoldSvg from '@yesplz/core-models/assets/svg/material-rose-gold.svg';
import materialSilverSvg from '@yesplz/core-models/assets/svg/material-silver.svg';
import materialPearlSvg from '@yesplz/core-models/assets/svg/material-pearl.svg';
import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';

export const patternTypes = [
  { type: 'solid', label: [{ "lng": "en", "label": "Solid" }, { "lng": "ko", "label": "민무뉘" }], image: designSolidSvg, remove: 'pattern' },
  { type: 'pattern', label: [{ "lng": "en", "label": "Pattern" }, { "lng": "ko", "label": "패턴" }], image: designPatternSvg, remove: 'solid', className: 'patterns-pattern' },
  { type: 'detail', label: [{ "lng": "en", "label": "Details" }, { "lng": "ko", "label": "디테일" }], image: designDetailSvg, className: 'patterns-detail' },
  { type: 'ripped-off', label: [{ "lng": "en", "label": "Distressed" }, { "lng": "ko", "label": "디스트레스드" }], image: designDestroyedSvg },
  { type: 'animal', label: [{ "lng": "en", "label": "Animal" }, { "lng": "ko", "label": "애니멀" }], image: designAnimalSvg },
  { type: 'floral', label: [{ "lng": "en", "label": "Floral" }, { "lng": "ko", "label": "플로럴" }], image: patternFloralSvg, className: 'patterns-floral' },
  { type: 'dot', label: [{ "lng": "en", "label": "Dots" }, { "lng": "ko", "label": "도트" }], image: patternDot, className: 'wide-image' },
  { type: 'fruit', label: [{ "lng": "en", "label": "Fruit" }, { "lng": "ko", "label": "Fruit" }], image: patternFruit, className: 'wide-image' },
  { type: 'repeat', label: [{ "lng": "en", "label": "Abstract" }, { "lng": "ko", "label": "추상적" }], image: patternAbstract, className: 'wide-image' },
  { type: 'sparkle', label: [{ "lng": "en", "label": "Sparkle" }, { "lng": "ko", "label": "시퀸" }], image: patternSparkle },
  { type: 'stripe', label: [{ "lng": "en", "label": "Stripe" }, { "lng": "ko", "label": "스트라이프" }], image: patternStripeSvg },
  { type: 'checker', label: [{ "lng": "en", "label": "Checker" }, { "lng": "ko", "label": "체크" }], image: patternCheckerSvg },
  { type: 'graphic', label: [{ "lng": "en", "label": "Graphic" }, { "lng": "ko", "label": "그래픽" }], image: patternGraphicSvg },
  { type: 'colorblock', label: [{ "lng": "en", "label": "Color Block" }, { "lng": "ko", "label": "컬러블락" }], image: patternColorBlockSvg },
].map(pattern => ({
  value: pattern.type,
  ...pattern,
}));

export const patternsFilterParams = {
  patternTypes,
  designTypes: patternTypes,
};

export const materialTypes = [
  // {type: 'light', label: 'Light', image: designLightSvg, activeIn: ['wouter', 'mouter']},
  { type: 'leather', label: 'Leather', image: designLeatherSvg },
  { type: 'denim', label: 'Denim', image: designDenimSvg },
  { type: 'cotton', label: 'Cotton', image: designCottonSvg },
  { type: 'wool', label: 'Wool', image: designWoolSvg },
  { type: 'down', label: 'Down/Puffer', image: designDownSvg },
  // {type: 'workout', label: '운동/아웃도어', image: designWorkoutSvg, activeIn: ['wpants', 'mpants']},
  // {type: 'polyester', label: 'Polyester', image: designWoolSvg, activeIn: ['wpants']}, // TODO: Update image
  { type: 'suede', label: 'Suede', image: designSuedeSvg },
  { type: 'fabric', label: 'Fabric', image: designSlacksSvg },
  // {type: 'fleece', label: 'Fleece', image: materialFleeceSvg, activeIn: ['mouter', 'wouter']},
  { type: 'gold', label: 'Gold', image: materialGoldSvg, activeIn: ['wearring'] },
  { type: 'rosegold', label: 'Rose Gold', image: materialRoseGoldSvg, activeIn: ['wearring'] },
  { type: 'silver', label: 'Silver', image: materialSilverSvg, activeIn: ['wearring'] },
  { type: 'pearl', label: 'Pearl', image: materialPearlSvg, activeIn: ['wearring'] },
];

export const materialsFilterParams = {
  materialTypes: materialTypes,
};

const baseCategories = {
  "women": {
    "label": "Women Global",
    "category": "women",
  },
  "wtop": {
    "label": "Women Tops",
    "category": "wtop",
    "partList": ["neckline", "sleeves", "waist", "length"],
    "partListLabels": ["Neckline", "Sleeves", "Waist", "Length"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Neckline" }, { "lng": "ko", "label": "네크라인" }], "name": "neckline" },
      { "label": [{ "lng": "en", "label": "Sleeves" }, { "lng": "ko", "label": "소매 기장" }], "name": "sleeves" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
    ],
    "defaultVal": { "neckline": "all", "sleeves": "all", "waist": "all", "length": "all" },
    "presetsList": {
      "Comfy": { "neckline": "round", "sleeves": "all", "waist": "wide", "length": "all", "design": [] },
      "Shirt": { "neckline": "collar", "sleeves": "all", "waist": "all", "length": "all", "design": [] },
      "Cami": { "neckline": "all", "sleeves": "thin_strap", "waist": "all", "length": "all", "design": [] },
      "Tank": { "neckline": "all", "sleeves": "wide_strap", "waist": "all", "length": "all", "design": [] },
      "Floral Print": { "neckline": "all", "sleeves": "all", "waist": "all", "length": "all", "design": ["floral"] },
    },
    "occasionsList": [
      { "label": "Ethnic", "value": "boho" },
      { "label": "Casual", "value": "casual" },
      { "label": "Night Out", "value": "nightout" },
      { "label": "Work", "value": "work" },
      { "label": "Workout", "value": "workout" },
      { "label": "Vacation", "value": "vacation" },
    ],
    "subtypesList": [
      { "label": "Shirts", "value": "shirts" },
      { "label": "T-Shirts", "value": "tshirts" },
      { "label": "Blouses", "value": "blouse" },
    ],
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wtop_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Tops-2.0_OptionAll.svg?raw' ),
    "tn": {
      "length": {
        "cropped": { "label": [{ "lng": "en", "label": "Cropped" }, { "lng": "ko", "label": "크롭" }], "tnClass": "tn-3-0" },
        "short": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "크롭/하프" }], "tnClass": "tn-3-1" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-3-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-3" }
      },
      "neckline": {
        "open": { "label": [{ "lng": "en", "label": "Open" }, { "lng": "ko", "label": "오픈" }], "tnClass": "tn-0-0" },
        "v_neck": { "label": [{ "lng": "en", "label": "V-neck" }, { "lng": "ko", "label": "브이넥" }], "tnClass": "tn-0-1" },
        "round": { "label": [{ "lng": "en", "label": "Round" }, { "lng": "ko", "label": "라운드넥" }], "tnClass": "tn-0-2" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-3" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-4" },
        "hoodie": { "label": [{ "lng": "en", "label": "Hood" }, { "lng": "ko", "label": "후드" }], "tnClass": "tn-0-5" },
        "wide_collar": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "세일러" }], "tnClass": "tn-0-6" },
        "mandarin": { "label": [{ "lng": "en", "label": "Split" }, { "lng": "ko", "label": "칼라리스" }], "tnClass": "tn-0-7" },
        "square": { "label": [{ "lng": "en", "label": "Square" }, { "lng": "ko", "label": "스퀘어넥" }], "tnClass": "tn-0-8" },
        "boatneck": { "label": [{ "lng": "en", "label": "Boatneck" }, { "lng": "ko", "label": "보트넥" }], "tnClass": "tn-0-9" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-10" }
      },
      "sleeves": {
        "thin_strap": { "label": [{ "lng": "en", "label": "Cami" }, { "lng": "ko", "label": "캐미" }], "tnClass": "tn-1-0" },
        "wide_strap": { "label": [{ "lng": "en", "label": "Tank" }, { "lng": "ko", "label": "탱크" }], "tnClass": "tn-1-1" },
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "반팔" }], "tnClass": "tn-1-2" },
        "elbow": { "label": [{ "lng": "en", "label": "Elbow" }, { "lng": "ko", "label": "긴반팔" }], "tnClass": "tn-1-3" },
        "three_quarters": { "label": [{ "lng": "en", "label": "3/4" }, { "lng": "ko", "label": "칠부" }], "tnClass": "tn-1-4" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "긴팔" }], "tnClass": "tn-1-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-6" }
      },
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Fitted" }, { "lng": "ko", "label": "슬림핏" }], "tnClass": "tn-2-0" },
        "straight": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러핏" }], "tnClass": "tn-2-1" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-2-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-3" }
      }
    },
    "overrideBodyPartGroupName": {
      "neckline": [
        {
          "on": [
            ["neckline", ["collar", "band"]],
            ["length", ["all"]],
          ],
          "to": ["neckline", "length_long"],
        },
        {
          "on": [
            ["neckline", ["all", "round", "v_neck", "boatneck"]],
            ["sleeves", ["thin_strap"]],
          ],
          "to": ["neckline", "sleeves"],
        },
        {
          "on": [
            ["neckline", ["collar", "band"]],
          ],
          "to": ["neckline", "length"],
        },
        {
          "on": [
            ["sleeves", ['thin_strap', 'wide_strap']],
            ["neckline", ["open"]],
          ],
          "to": "no_display_group",
        },
      ],
      "sleeves": [{
        "on": [
          ["sleeves", ['thin_strap', 'wide_strap']],
          ["neckline", ["open"]],
        ],
        "to": ["sleeves", "neckline"],
      }],
      "length": [{
        "to": ["length", "waist"],
      }],
      "waist": [{
        "to": "no_display_group",
      }],
    }
  },
  "wdress": {
    "label": "Women Dresses",
    "category": "wdress",
    "partList": ["neckline", "sleeves", "length", "style", "waist"],
    "partListLabels": ["Neckline", "Sleeves", "Length", "Style", "Waist"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Neckline" }, { "lng": "ko", "label": "네크라인" }], "name": "neckline" },
      { "label": [{ "lng": "en", "label": "Sleeves" }, { "lng": "ko", "label": "소매 기장" }], "name": "sleeves" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
      { "label": [{ "lng": "en", "label": "Style" }, { "lng": "ko", "label": "스타일" }], "name": "style" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
    ],
    "defaultVal": { "neckline": "all", "waist": "all", "sleeves": "all", "length": "all", "style": "all" },
    "presetsList": {
      "Shirt Dress": { "neckline": "collar", "sleeves": "all", "waist": "all", "style": "all", "length": "all", "design": [] },
      "Tshirt Dress": { "neckline": "round", "sleeves": "all", "waist": "all", "style": "all", "length": "all", "design": [] },
      "Mini Dress": { "neckline": "all", "sleeves": "all", "waist": "all", "style": "all", "length": "short", "design": [] },
      "Midi Dress": { "neckline": "all", "sleeves": "all", "waist": "all", "style": "all", "length": "semi_long", "design": [] },
      "Maxi": { "neckline": "all", "sleeves": "all", "waist": "all", "style": "all", "length": "very_long", "design": [] },
    },
    "occasionsList": [
      { "label": "Casual", "value": "casual" },
      { "label": "Formal", "value": "formal" },
      { "label": "Night Out", "value": "nightout" },
      { "label": "Work", "value": "work" },
      { "label": "Vacation", "value": "vacation" },
    ],
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wdress_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Dress.svg?raw' ),
    "tn": {
      "neckline": {
        "open": { "label": [{ "lng": "en", "label": "Open" }, { "lng": "ko", "label": "오픈 숄더" }], "tnClass": "tn-0-0" },
        "v_neck": { "label": [{ "lng": "en", "label": "V-neck" }, { "lng": "ko", "label": "브이넥" }], "tnClass": "tn-0-1" },
        "round": { "label": [{ "lng": "en", "label": "Round" }, { "lng": "ko", "label": "라운드넥" }], "tnClass": "tn-0-2" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-3" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-4" },
        "hoodie": { "label": [{ "lng": "en", "label": "Hood" }, { "lng": "ko", "label": "후드" }], "tnClass": "tn-0-5" },
        "wide_collar": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "세일러" }], "tnClass": "tn-0-6" },
        "mandarin": { "label": [{ "lng": "en", "label": "Split" }, { "lng": "ko", "label": "칼라리스" }], "tnClass": "tn-0-7" },
        "square": { "label": [{ "lng": "en", "label": "Square" }, { "lng": "ko", "label": "스퀘어" }], "tnClass": "tn-0-8" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-10" }
      },
      "sleeves": {
        "thin_strap": { "label": [{ "lng": "en", "label": "Cami" }, { "lng": "ko", "label": "캐미" }], "tnClass": "tn-1-0" },
        "wide_strap": { "label": [{ "lng": "en", "label": "Tank" }, { "lng": "ko", "label": "탱크" }], "tnClass": "tn-1-1" },
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "반팔" }], "tnClass": "tn-1-2" },
        "elbow": { "label": [{ "lng": "en", "label": "Elbow" }, { "lng": "ko", "label": "긴반팔" }], "tnClass": "tn-1-3" },
        "three_quarters": { "label": [{ "lng": "en", "label": "3/4" }, { "lng": "ko", "label": "칠부" }], "tnClass": "tn-1-4" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "긴팔" }], "tnClass": "tn-1-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-6" }
      },
      "length": {
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "미니" }], "tnClass": "tn-3-0" },
        "knees": { "label": [{ "lng": "en", "label": "Knees" }, { "lng": "ko", "label": "무릎" }], "tnClass": "tn-3-1" },
        "semi_long": { "label": [{ "lng": "en", "label": "Midi" }, { "lng": "ko", "label": "미디" }], "tnClass": "tn-3-2" },
        "very_long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-3-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-4" }
      },
      "style": {
        "plain": { "label": [{ "lng": "en", "label": "Plain" }, { "lng": "ko", "label": "플레인" }], "tnClass": "tn-4-0" },
        "pleated": { "label": [{ "lng": "en", "label": "Pleated" }, { "lng": "ko", "label": "플리츠" }], "tnClass": "tn-4-1" },
        "slit": { "label": [{ "lng": "en", "label": "Slit" }, { "lng": "ko", "label": "슬릿" }], "tnClass": "tn-4-2" },
        "ruffle": { "label": [{ "lng": "en", "label": "Ruffle" }, { "lng": "ko", "label": "러플" }], "tnClass": "tn-4-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-4-5" }
      },
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Fitted" }, { "lng": "ko", "label": "슬림핏" }], "tnClass": "tn-2-0" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-2-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-2" }
      }
    },
    "overrideBodyPartGroupName": {
      "style": [{ "to": ["style", "length"] }],
      "neckline": [
        {
          "on": [
            ['neckline', ['open']],
            ['sleeves', ['thin_strap', 'wide_strap']],
          ],
          "to": 'no_display_group',
        },
        {
          "on": [
            ["neckline", ["round", "v_neck"]],
            ["sleeves", ["thin_strap"]],
          ],
          "to": ["neckline", "sleeves"],
        }
      ],
      "sleeves": [
        {
          "on": [["neckline", ['open']], ['sleeves', ['thin_strap', 'wide_strap']]],
          "to": ["sleeves", "neckline"],
        }
      ],
    },
  },
  "wouter": {
    "label": "Women Outerwears",
    "category": "wouter",
    "partList": ["necktype", "length", "sleeves", "waist"],
    "partListLabels": ["Necktype", "Length", "Sleeves", "Waist"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Necktype" }, { "lng": "ko", "label": "네크라인" }], "name": "necktype" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
      { "label": [{ "lng": "en", "label": "Sleeves" }, { "lng": "ko", "label": "소매 기장" }], "name": "sleeves" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
    ],
    "defaultVal": { "necktype": "all", "sleeves": "all", "waist": "all", "length": "all" },
    "presetsList": {
      "Blazer": { "necktype": "lapel", "sleeves": "long", "waist": "tight", "length": "half", "material": "wool" },
      "Overcoat": { "necktype": "lapel", "sleeves": "long", "waist": "wide", "length": "long", "material": "wool" },
      "Jackets": { "necktype": "collar", "sleeves": "long", "waist": "tight", "length": "short" },
      "Parka": { "necktype": "hood", "sleeves": "long", "waist": "wide", "length": "long", "material": "down" },
      "Pullover": { "necktype": "high", "sleeves": "long", "waist": "wide", "length": "half", "material": "fleece" },
      "Moto": { "necktype": "lapel", "sleeves": "long", "waist": "tight", "length": "short", "material": "leather" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wouter_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Outerwears-2.0_OptionAll.svg?raw' ),
    "tn": {
      "necktype": {
        "lapel": { "label": [{ "lng": "en", "label": "Lapel" }, { "lng": "ko", "label": "라펠" }], "tnClass": "tn-0-0" },
        "collarless": { "label": [{ "lng": "en", "label": "Collarless" }, { "lng": "ko", "label": "칼라리스" }], "tnClass": "tn-0-1" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-2" },
        "hood": { "label": [{ "lng": "en", "label": "Hood" }, { "lng": "ko", "label": "후드" }], "tnClass": "tn-0-3" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-4" },
        "wide_collar": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "세일러" }], "tnClass": "tn-0-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-6" }
      },
      "length": {
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "크롭/하프" }], "tnClass": "tn-1-0" },
        "regular": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-1-1" },
        "half": { "label": [{ "lng": "en", "label": "Half" }, { "lng": "ko", "label": "미디" }], "tnClass": "tn-1-2" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-1-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-4" },
      },
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Fitted" }, { "lng": "ko", "label": "슬림핏" }], "tnClass": "tn-3-0" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-3-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-2" }
      },
      "sleeves": {
        "sleeveless": { "label": [{ "lng": "en", "label": "Sleeveless" }, { "lng": "ko", "label": "베스트" }], "tnClass": "tn-2-0" },
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "반팔" }], "tnClass": "tn-2-1" },
        "long": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "긴팔" }], "tnClass": "tn-2-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-3" }
      }
    },
    "overrideBodyPartGroupName": {
      "sleeves": [{
        "on": [
          ["sleeves", ['sleeveless']],
        ],
        "to": ["sleeves", "waist"],
      }],
      "length": [{
        "to": ["length", "waist"],
      }],
      "waist": [{
        "to": "no_display_group",
      }],
    },
  },
  "wpaddings": {
    "label": "Women Paddings",
    "category": "wouter",
    "partList": ["necktype", "length"],
    "partListLabels": ["Necktype", "Length"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Necktype" }, { "lng": "ko", "label": "네크라인" }], "name": "necktype" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
    ],
    "defaultVal": { "necktype": "all", "length": "all" },
    "presetsList": {
      "Blazer": { "necktype": "lapel", "length": "half", "material": "wool" },
      "Overcoat": { "necktype": "lapel", "length": "long", "material": "wool" },
      "Jackets": { "necktype": "collar", "length": "short" },
      "Parka": { "necktype": "hood", "length": "long", "material": "down" },
      "Pullover": { "necktype": "high", "length": "half", "material": "fleece" },
      "Moto": { "necktype": "lapel", "length": "short", "material": "leather" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wpaddings_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Paddings.svg?raw' ),
    "tn": {
      "necktype": {
        "lapel": { "label": [{ "lng": "en", "label": "Lapel" }, { "lng": "ko", "label": "라펠" }], "tnClass": "tn-0-0" },
        "collarless": { "label": [{ "lng": "en", "label": "Collarless" }, { "lng": "ko", "label": "Collarless" }], "tnClass": "tn-0-1" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-2" },
        "hood": { "label": [{ "lng": "en", "label": "Hood" }, { "lng": "ko", "label": "후드" }], "tnClass": "tn-0-3" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-4" },
        "wide_collar": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "세일러" }], "tnClass": "tn-0-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-6" }
      },
      "length": {
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "크롭/하프" }], "tnClass": "tn-1-0" },
        "regular": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-1-1" },
        "half": { "label": [{ "lng": "en", "label": "Half" }, { "lng": "ko", "label": "미디" }], "tnClass": "tn-1-2" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-1-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-4" },
      }
    },
    "extraBodyGroups": ["sleeves_all"]
  },
  "wcoats": {
    "label": "Women Coats",
    "category": "wouter",
    "partList": ["necktype", "length", "sleeves", "waist"],
    "partListLabels": ["Necktype", "Length", "Sleeves", "Waist"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Necktype" }, { "lng": "ko", "label": "네크라인" }], "name": "necktype" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
      { "label": [{ "lng": "en", "label": "Sleeves" }, { "lng": "ko", "label": "소매 기장" }], "name": "sleeves" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
    ],
    "defaultVal": { "necktype": "all", "sleeves": "all", "waist": "all", "length": "all" },
    "presetsList": {
      "Blazer": { "necktype": "lapel", "sleeves": "long", "waist": "tight", "length": "half", "material": "wool" },
      "Overcoat": { "necktype": "lapel", "sleeves": "long", "waist": "wide", "length": "long", "material": "wool" },
      "Jackets": { "necktype": "collar", "sleeves": "long", "waist": "tight", "length": "short" },
      "Parka": { "necktype": "hood", "sleeves": "long", "waist": "wide", "length": "long", "material": "down" },
      "Pullover": { "necktype": "high", "sleeves": "long", "waist": "wide", "length": "half", "material": "fleece" },
      "Moto": { "necktype": "lapel", "sleeves": "long", "waist": "tight", "length": "short", "material": "leather" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wcoats_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Coats.svg?raw' ),
    "tn": {
      "necktype": {
        "lapel": { "label": [{ "lng": "en", "label": "Lapel" }, { "lng": "ko", "label": "라펠" }], "tnClass": "tn-0-0" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-1" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-2" },
        "hood": { "label": [{ "lng": "en", "label": "Hood" }, { "lng": "ko", "label": "후드" }], "tnClass": "tn-0-3" },
        "collarless": { "label": [{ "lng": "en", "label": "Plain" }, { "lng": "ko", "label": "라운드넥" }], "tnClass": "tn-0-5" },
        "wide_collar": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "세일러" }], "tnClass": "tn-0-6" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-7" }
      },
      "length": {
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "크롭/하프" }], "tnClass": "tn-1-0" },
        "regular": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-1-1" },
        "half": { "label": [{ "lng": "en", "label": "Half" }, { "lng": "ko", "label": "미디" }], "tnClass": "tn-1-2" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-1-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-4" },
      },
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Tight" }, { "lng": "ko", "label": "타이트" }], "tnClass": "tn-3-0" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-3-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-2" }
      },
      "sleeves": {
        "sleeveless": { "label": [{ "lng": "en", "label": "Sleeveless" }, { "lng": "ko", "label": "베스트" }], "tnClass": "tn-2-0" },
        "long": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "긴팔" }], "tnClass": "tn-2-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-3" }
      }
    },
    "overrideBodyPartGroupName": {
      "sleeves": [{
        "to": ["sleeves", "waist"],
      }],
      "length": [{
        "to": ["length", "waist"],
      }],
      "waist": [{
        "to": "no_display_group",
      }],
    },
  },
  "wpullover": {
    "label": "Women Pullover",
    "category": "wknit",
    "partList": ["neckline", "sleeves", "waist", "length"],
    "partListLabels": ["Neckline", "Sleeves", "Waist", "Length"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Neckline" }, { "lng": "ko", "label": "네크라인" }], "name": "neckline" },
      { "label": [{ "lng": "en", "label": "Sleeves" }, { "lng": "ko", "label": "소매기장" }], "name": "sleeves" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
    ],
    "defaultVal": { "neckline": "all", "sleeves": "all", "waist": "all", "length": "all" },
    "presetsList": {
      "V-neck": { "neckline": "v_neck", "sleeves": "all", "waist": "all", "length": "all", "desing": [] },
      "Round Neck": { "neckline": "round", "sleeves": "all", "waist": "all", "length": "all", "desing": [] },
      "Turtleneck": { "neckline": "high", "sleeves": "all", "waist": "all", "length": "all", "desing": [] },
      "Tank": { "neckline": "all", "sleeves": "all", "waist": "wide", "length": "long", "desing": [] }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wpullover_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Pullover.svg?raw' ),
    "tn": {
      "neckline": {
        "v_neck": { "label": [{ "lng": "en", "label": "V-neck" }, { "lng": "ko", "label": "브이넥" }], "tnClass": "tn-0-0" },
        "round": { "label": [{ "lng": "en", "label": "Round" }, { "lng": "ko", "label": "라운드넥" }], "tnClass": "tn-0-1" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-2" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-3" },
        "boatneck": { "label": [{ "lng": "en", "label": "Boatneck" }, { "lng": "ko", "label": "보트넥" }], "tnClass": "tn-0-4" },
        "wide_collar": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "세일러" }], "tnClass": "tn-0-5" },
        "square": { "label": [{ "lng": "en", "label": "Square" }, { "lng": "ko", "label": "스퀘어" }], "tnClass": "tn-0-6" },
        "hoodie": { "label": [{ "lng": "en", "label": "Hoodie" }, { "lng": "ko", "label": "후드" }], "tnClass": "tn-0-7" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-8" }
      },
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Tight" }, { "lng": "ko", "label": "슬림핏" }], "tnClass": "tn-2-0" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-2-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-2" }
      },
      "length": {
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "크롭/하프" }], "tnClass": "tn-3-0" },
        "regular": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-3-1" },
        "half": { "label": [{ "lng": "en", "label": "Half" }, { "lng": "ko", "label": "미디" }], "tnClass": "tn-3-2" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-3-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-4" }
      },
      "sleeves": {
        "sleeveless": { "label": [{ "lng": "en", "label": "Sleeveless" }, { "lng": "ko", "label": "민소매" }], "tnClass": "tn-1-0" },
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "반팔" }], "tnClass": "tn-1-1" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "긴팔" }], "tnClass": "tn-1-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-3" }
      },
    },
    "overrideBodyPartGroupName": {
      "length": [{
        "to": ["length", "waist"],
      }],
      "waist": [{
        "to": "no_display_group",
      }],
    },
  },
  "wcardigan": {
    "label": "Women Cardigans",
    "category": "wknit",
    "partList": ["neckline", "sleeves", "waist", "length"],
    "partListLabels": ["Neckline", "Sleeves", "Waist", "Length"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Neckline" }, { "lng": "ko", "label": "네크라인" }], "name": "neckline" },
      { "label": [{ "lng": "en", "label": "Sleeves" }, { "lng": "ko", "label": "소매기장" }], "name": "sleeves" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
    ],
    "defaultVal": { "neckline": "all", "sleeves": "all", "waist": "all", "length": "all" },
    "presetsList": {
      "V-neck": { "neckline": "v_neck", "sleeves": "all", "waist": "all", "length": "all", "desing": [] },
      "Round Neck": { "neckline": "round", "sleeves": "all", "waist": "all", "length": "all", "desing": [] },
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wcardigan_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Cardigans.svg?raw' ),
    "tn": {
      "neckline": {
        "open": { "label": [{ "lng": "en", "label": "Open" }, { "lng": "ko", "label": "오프더숄더" }], "tnClass": "tn-0-0" },
        "v_neck": { "label": [{ "lng": "en", "label": "V-neck" }, { "lng": "ko", "label": "브이넥" }], "tnClass": "tn-0-1" },
        "round": { "label": [{ "lng": "en", "label": "Round" }, { "lng": "ko", "label": "라운드넥" }], "tnClass": "tn-0-2" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-3" },
        "wide_collar": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "세일러" }], "tnClass": "tn-0-4" },
        "hoodie": { "label": [{ "lng": "en", "label": "Hood" }, { "lng": "ko", "label": "후드" }], "tnClass": "tn-0-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-6" }
      },
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Tight" }, { "lng": "ko", "label": "타이트" }], "tnClass": "tn-2-0" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-2-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-2" }
      },
      "length": {
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "크롭/하프" }], "tnClass": "tn-3-0" },
        "regular": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-3-1" },
        "half": { "label": [{ "lng": "en", "label": "Half" }, { "lng": "ko", "label": "미디" }], "tnClass": "tn-3-2" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-3-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-4" }
      },
      "sleeves": {
        "sleeveless": { "label": [{ "lng": "en", "label": "Sleeveless" }, { "lng": "ko", "label": "베스트" }], "tnClass": "tn-1-0" },
        "short": { "label": [{ "lng": "en", "label": "Shorts" }, { "lng": "ko", "label": "반팔" }], "tnClass": "tn-1-1" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "긴팔" }], "tnClass": "tn-1-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-3" }
      },
    },
    "overrideBodyPartGroupName": {
      "length": [{
        "to": ["length", "waist"],
      }],
      "waist": [{
        "to": "no_display_group",
      }],
    },
  },
  "wpants": {
    "label": "Women Pants",
    "category": "wpants",
    "partList": ["rise", "length", "fit", "ankle"],
    "partListLabels": ["Rise", "Length", "Fit", "Ankle"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Rise" }, { "lng": "ko", "label": "라이즈" }], "name": "rise" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
      { "label": [{ "lng": "en", "label": "Fit" }, { "lng": "ko", "label": "핏" }], "name": "fit" },
      { "label": [{ "lng": "en", "label": "Ankle" }, { "lng": "ko", "label": "앵클" }], "name": "ankle" },
    ],
    "defaultVal": { "rise": "all", "fit": "all", "length": "all", "ankle": "all" },
    "presetsList": {
      "Jeans": { "rise": "all", "fit": "all", "length": "all", "ankle": "all", "material": "denim" },
      "Work": { "rise": "all", "fit": "all", "length": "all", "ankle": "all", "material": "wool" },
      "Casual": { "rise": "all", "fit": "all", "length": "all", "ankle": "all", "material": "cotton" },
      "Workout": { "rise": "low", "fit": "wide", "length": "long", "ankle": "tight", "material": "cotton" },
      "Leggings": { "rise": "low", "fit": "tight", "length": "cropped", "ankle": "tight" },
      "Shorts": { "rise": "all", "fit": "all", "length": "short", "ankle": "all" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wpants_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Pants-v2.5_TN.svg?raw' ),
    "tn": {
      "rise": {
        "low": { "label": [{ "lng": "en", "label": "Low Rise" }, { "lng": "ko", "label": "로우라이즈" }], "tnClass": "tn-0-0" },
        "medium_high": { "label": [{ "lng": "en", "label": "Mid-High Rise" }, { "lng": "ko", "label": "하이라이즈" }], "tnClass": "tn-0-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-2" }
      },
      "length": {
        "cropped": { "label": [{ "lng": "en", "label": "Cropped" }, { "lng": "ko", "label": "크롭" }], "tnClass": "tn-1-0" },
        "long": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-1-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-2" }
      },
      "fit": {
        "tight": { "label": [{ "lng": "en", "label": "Skinny" }, { "lng": "ko", "label": "타이트핏" }], "tnClass": "tn-2-0" },
        "straight": { "label": [{ "lng": "en", "label": "Slim" }, { "lng": "ko", "label": "스트레이트" }], "tnClass": "tn-2-1" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-2-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-3" }
      },
      "ankle": {
        "tight": { "label": [{ "lng": "en", "label": "Narrow" }, { "lng": "ko", "label": "스키니" }], "tnClass": "tn-3-0" },
        "straight": { "label": [{ "lng": "en", "label": "Straight" }, { "lng": "ko", "label": "스트레이트" }], "tnClass": "tn-3-1" },
        "wide": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "루즈" }], "tnClass": "tn-3-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-3" }
      }
    },
    "overrideBodyPartGroupName": {
      "length": [{ "to": ["length", "fit"] }],
      "fit": [{ "to": "no_display_group" }],
      "ankle": [
        {
          "on": [["length", ["short"]]],
          "to": 'no_display_group',
        },
        {
          "on": [["length", ["short"]]],
          "to": ["ankle", "length", "fit"],
        },
      ],
    },
  },
  "wpants2": {
    "label": "Women Pants 2",
    "category": "wpants",
    "partList": ["rise", "length", "fit"],
    "partListLabels": ["Rise", "Length", "Fit"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Rise" }, { "lng": "ko", "label": "라이즈" }], "name": "rise" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
      { "label": [{ "lng": "en", "label": "Fit" }, { "lng": "ko", "label": "핏 타입" }], "name": "fit" },
    ],
    "defaultVal": { "rise": "all", "fit": "all", "length": "all" },
    "presetsList": {},
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wpants2_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Pants2.svg?raw' ),
    "tn": {
      "rise": {
        "low": { "label": [{ "lng": "en", "label": "Low" }, { "lng": "ko", "label": "로우라이즈" }], "tnClass": "tn-0-0" },
        "medium_high": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "미드라이즈" }], "tnClass": "tn-0-1" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "하이라이즈" }], "tnClass": "tn-0-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-3" }
      },
      "length": {
        "cropped": { "label": [{ "lng": "en", "label": "Cropped" }, { "lng": "ko", "label": "크롭" }], "tnClass": "tn-1-0" },
        "regular": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-1-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-2" }
      },
      "fit": {
        "skinny": { "label": [{ "lng": "en", "label": "Skinny" }, { "lng": "ko", "label": "스키니" }], "tnClass": "tn-2-0" },
        "straight": { "label": [{ "lng": "en", "label": "Straight" }, { "lng": "ko", "label": "스트레이트" }], "tnClass": "tn-2-1" },
        "bootcut": { "label": [{ "lng": "en", "label": "Bootcut" }, { "lng": "ko", "label": "부트컷" }], "tnClass": "tn-2-2" },
        "tapered": { "label": [{ "lng": "en", "label": "Tapered" }, { "lng": "ko", "label": "테이퍼드" }], "tnClass": "tn-2-3" },
        "flare": { "label": [{ "lng": "en", "label": "Flare" }, { "lng": "ko", "label": "플래어" }], "tnClass": "tn-2-4" },
        "wide": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "와이드" }], "tnClass": "tn-2-5" },
        "jogger": { "label": [{ "lng": "en", "label": "Jogger" }, { "lng": "ko", "label": "조거" }], "tnClass": "tn-2-6" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-7" }
      }
    },
    "overrideBodyPartGroupName": {
      "length": [{ "to": ["length", "fit"] }],
      "fit": [{ "to": "no_display_group" }],
    },
  },
  "wshorts": {
    "label": "Women Shorts",
    "category": "wpants",
    "partList": ["rise", "length", "fit"],
    "partListLabels": ["Rise", "Length", "Fit"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Rise" }, { "lng": "ko", "label": "라이즈" }], "name": "rise" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
      { "label": [{ "lng": "en", "label": "Fit" }, { "lng": "ko", "label": "핏" }], "name": "fit" },
    ],
    "defaultVal": { "rise": "all", "fit": "all", "length": "all" },
    "presetsList": {
      "Casual": { "rise": "all", "fit": "all", "length": "all", "material": "cotton" },
      "Workout": { "rise": "low", "fit": "wide", "length": "knee", "occasion": "workout" },
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wshorts_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Shorts-v2.5_OptionAll.svg?raw' ),
    "tn": {
      "rise": {
        "low": { "label": [{ "lng": "en", "label": "Low" }, { "lng": "ko", "label": "로우라이즈" }], "tnClass": "tn-0-0" },
        "medium_high": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-0-1" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "하이라이즈" }], "tnClass": "tn-0-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-3" }
      },
      "length": {
        "mini": { "label": [{ "lng": "en", "label": "Shorts" }, { "lng": "ko", "label": "Shorts" }], "tnClass": "tn-1-0" },
        "short": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-1-1" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "Long" }], "tnClass": "tn-1-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-3" }
      },
      "fit": {
        "skinny": { "label": [{ "lng": "en", "label": "Slim" }, { "lng": "ko", "label": "슬림" }], "tnClass": "tn-2-0" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈" }], "tnClass": "tn-2-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-2" }
      }
    },
    "overrideBodyPartGroupName": {
      "length": [{ "to": ["length", "fit"] }],
      "fit": [{ "to": "no_display_group" }],
    },
  },
  "wskirt": {
    "label": "Women Skirts",
    "category": "wskirt",
    "partList": ["length", "thigh", "style"],
    "partListLabels": ["Length", "Tightness", "Style"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
      { "label": [{ "lng": "en", "label": "Tightness" }, { "lng": "ko", "label": "핏" }], "name": "thigh" },
      { "label": [{ "lng": "en", "label": "Style" }, { "lng": "ko", "label": "스타일" }], "name": "style" },
    ],
    "defaultVal": { "thigh": "all", "length": "all", "style": "all" },
    "presetsList": {
      "Casual": { "thigh": "all", "length": "short", "style": "all" },
      "Work": { "thigh": "all", "length": "knees", "style": "plain" },
      "Evening": { "thigh": "all", "length": "short", "style": "slit" },
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wskirt_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Skirts-2.0_OptionAll.svg?raw' ),
    "tn": {
      "length": {
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "미니" }], "tnClass": "tn-0-0" },
        "knees": { "label": [{ "lng": "en", "label": "Knees" }, { "lng": "ko", "label": "무릎" }], "tnClass": "tn-0-1" },
        "semi_long": { "label": [{ "lng": "en", "label": "Midi" }, { "lng": "ko", "label": "미디" }], "tnClass": "tn-0-2" },
        "very_long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-0-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-4" }
      },
      "thigh": {
        "tight": { "label": [{ "lng": "en", "label": "Fitted" }, { "lng": "ko", "label": "타이트" }], "tnClass": "tn-1-0" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈" }], "tnClass": "tn-1-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-2" }
      },
      "style": {
        "plain": { "label": [{ "lng": "en", "label": "Plain" }, { "lng": "ko", "label": "플레인" }], "tnClass": "tn-2-0" },
        "pleated": { "label": [{ "lng": "en", "label": "Pleated" }, { "lng": "ko", "label": "플리츠" }], "tnClass": "tn-2-1" },
        "slit": { "label": [{ "lng": "en", "label": "Slit" }, { "lng": "ko", "label": "슬릿" }], "tnClass": "tn-2-2" },
        "ruffle": { "label": [{ "lng": "en", "label": "Ruffle" }, { "lng": "ko", "label": "러플" }], "tnClass": "tn-2-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-6" }
      }
    },
    "overrideBodyPartGroupName": {
      "length": [{ "to": ["length", "thigh"] }],
      "style": [
        { "on": [["style", ['all', 'pleated', 'ruffle']]], "to": ["style", "length", "thigh"] },
        { "on": [["style", ['slit']]], "to": ["style", "length"] },
      ],
      "thigh": [{ "to": "no_display_group" }],
    },
  },
  "wdressshoes": {
    "label": "Women Dress Shoes",
    "category": "wdressshoes",
    "partList": ["toes", "shafts", "counters", "bottoms"],
    "partListLabels": ["Toes", "Shafts", "Counters", "Bottoms"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Toes" }, { "lng": "ko", "label": "토" }], "name": "toes" },
      { "label": [{ "lng": "en", "label": "Neck" }, { "lng": "ko", "label": "발목" }], "name": "shafts" },
      { "label": [{ "lng": "en", "label": "Counter" }, { "lng": "ko", "label": "카운터" }], "name": "counters" },
      { "label": [{ "lng": "en", "label": "Bottoms" }, { "lng": "ko", "label": "Bottoms" }], "name": "bottoms" },
    ],
    "defaultVal": { "toes": "all", "shafts": "all", "counters": "all", "bottoms": "all" },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wdressshoes_core.svg' ),
    "tnSprite": "dressshoes",
    "tnSpriteOptions": [
      { "label": "Dress Shoes", "name": "dressshoes", "sprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-DressShoes-2.0_OptionAll.svg?raw' ) },
      { "label": "Boots", "name": "boots", "sprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Women-Boots.svg?raw' ) },
    ],
    "presetsList": {
      "Stiletto": { "toes": "pointy", "shafts": "open", "counters": "full", "bottoms": "stiletto" },
      "Sandal": { "toes": "open", "shafts": "open", "counters": "open", "bottoms": "kitten" },
      "Flat": { "toes": "pointy", "shafts": "open", "counters": "full", "bottoms": "flat" },
      "Bootie": { "toes": "pointy", "shafts": "ankles", "counters": "full", "bottoms": "low_block" },
      "Boot": { "toes": "pointy", "shafts": "knees", "counters": "full", "bottoms": "low_block" },
      "Shootie": { "toes": "open", "shafts": "ankles", "counters": "open", "bottoms": "low_block" }
    },
    "tn": {
      "toes": {
        "open": { "label": [{ "lng": "en", "label": "Open" }, { "lng": "ko", "label": "오픈토" }], "tnClass": "tn-2-0" },
        "pointy": { "label": [{ "lng": "en", "label": "Pointed" }, { "lng": "ko", "label": "포인티토" }], "tnClass": "tn-2-1" },
        "round": { "label": [{ "lng": "en", "label": "Round" }, { "lng": "ko", "label": "라운드토" }], "tnClass": "tn-2-2" },
        "square": { "label": [{ "lng": "en", "label": "Square" }, { "lng": "ko", "label": "스퀘어" }], "tnClass": "tn-2-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-4" }
      },
      "bottoms": {
        "flat": { "label": [{ "lng": "en", "label": "Flat" }, { "lng": "ko", "label": "플랫" }], "tnClass": "tn-0-0" },
        "wedge": { "label": [{ "lng": "en", "label": "Wedge" }, { "lng": "ko", "label": "웨지" }], "tnClass": "tn-0-1" },
        "low_block": { "label": [{ "lng": "en", "label": "Low Block" }, { "lng": "ko", "label": "낮은 통굽" }], "tnClass": "tn-0-2" },
        "high_block": { "label": [{ "lng": "en", "label": "High Block" }, { "lng": "ko", "label": "높은 통굽" }], "tnClass": "tn-0-3" },
        "kitten": { "label": [{ "lng": "en", "label": "Low heel" }, { "lng": "ko", "label": "낮은 힐" }], "tnClass": "tn-0-4" },
        "stiletto": { "label": [{ "lng": "en", "label": "High heel" }, { "lng": "ko", "label": "하이힐" }], "tnClass": "tn-0-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-6" }
      },
      "shafts": {
        "open": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "오픈" }], "tnClass": "tn-3-0" },
        "strap": { "label": [{ "lng": "en", "label": "Strap" }, { "lng": "ko", "label": "스트랩" }], "tnClass": "tn-3-1" },
        "ankles": { "label": [{ "lng": "en", "label": "Ankle" }, { "lng": "ko", "label": "앵클" }], "tnClass": "tn-3-2" },
        "knees": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "니하이" }], "tnClass": "tn-3-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-4" }
      },
      "counters": {
        "open": { "label": [{ "lng": "en", "label": "Open" }, { "lng": "ko", "label": "오픈" }], "tnClass": "tn-1-0" },
        "back": { "label": [{ "lng": "en", "label": "Back" }, { "lng": "ko", "label": "하프커버" }], "tnClass": "tn-1-1" },
        "full": { "label": [{ "lng": "en", "label": "Full" }, { "lng": "ko", "label": "풀커버" }], "tnClass": "tn-1-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-3" }
      }
    },
    "overrideBodyPartGroupName": {
      "shafts": [
        {
          "on": [
            ["shafts", ['knees', 'ankles']],
            ["counters", ['open']],
          ],
          "to": ["shafts", "counters"],
        },
        {
          "on": [
            ["shafts", ['all']],
          ],
          "to": "no_display_group",
        },
      ],
    },
  },
  "wsneakers": {
    "label": "Women Sneakers",
    "category": "wsneakers",
    "partList": ["toes", "neck", "sole", "cover"],
    "partListLabels": ["Toes", "Neck", "Sole", "Cover"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Toes" }, { "lng": "ko", "label": "토" }], "name": "toes" },
      { "label": [{ "lng": "en", "label": "Neck" }, { "lng": "ko", "label": "목길이" }], "name": "neck" },
      { "label": [{ "lng": "en", "label": "Sole" }, { "lng": "ko", "label": "솔" }], "name": "sole" },
      { "label": [{ "lng": "en", "label": "Cover" }, { "lng": "ko", "label": "커버" }], "name": "cover" },
    ],
    "defaultVal": { "toes": "all", "neck": "all", "sole": "all", "cover": "all" },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_wsneakers_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN_Women_Sneaker_2.0_OptionAll.svg?raw' ),
    "presetsList": {
      "Low top": { "toes": "flat", "neck": "low", "sole": "low", "cover": "laceup", "design": "solid" },
      "High top": { "toes": "flat", "neck": "high", "sole": "low", "cover": "laceup" },
      "Slip-on": { "toes": "flat", "neck": "low", "sole": "low", "cover": "plain" },
      "Chunky": { "toes": "pointy", "neck": "full", "sole": "chunky", "cover": "laceup" },
      "Running": { "toes": "pointy", "neck": "low", "sole": "grip", "cover": "laceup" },
      "Ornament": { "toes": "round", "neck": "low", "sole": "chunky", "cover": "ornament" },
    },
    "tn": {
      "toes": {
        "pointy": { "label": [{ "lng": "en", "label": "Pointed" }, { "lng": "ko", "label": "포인티토" }], "tnClass": "tn-0-0" },
        "round": { "label": [{ "lng": "en", "label": "Round" }, { "lng": "ko", "label": "라운드토" }], "tnClass": "tn-0-1" },
        "flat": { "label": [{ "lng": "en", "label": "Flat" }, { "lng": "ko", "label": "플랫" }], "tnClass": "tn-0-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-3" }
      },
      "neck": {
        "low": { "label": [{ "lng": "en", "label": "Low top" }, { "lng": "ko", "label": "로우탑" }], "tnClass": "tn-1-0" },
        "full": { "label": [{ "lng": "en", "label": "Mid top" }, { "lng": "ko", "label": "미드탑" }], "tnClass": "tn-1-1" },
        "high": { "label": [{ "lng": "en", "label": "High top" }, { "lng": "ko", "label": "하이탑" }], "tnClass": "tn-1-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-3" }
      },
      "sole": {
        "low": { "label": [{ "lng": "en", "label": "Flat" }, { "lng": "ko", "label": "플랫" }], "tnClass": "tn-2-0" },
        "grip": { "label": [{ "lng": "en", "label": "Grip" }, { "lng": "ko", "label": "그립" }], "tnClass": "tn-2-1" },
        "chunky": { "label": [{ "lng": "en", "label": "Chunky" }, { "lng": "ko", "label": "청키" }], "tnClass": "tn-2-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-3" }
      },
      "cover": {
        "plain": { "label": [{ "lng": "en", "label": "Plain" }, { "lng": "ko", "label": "플레인" }], "tnClass": "tn-3-0" },
        "laceup": { "label": [{ "lng": "en", "label": "Lace-up" }, { "lng": "ko", "label": "레이스업" }], "tnClass": "tn-3-1" },
        "strap": { "label": [{ "lng": "en", "label": "Strap" }, { "lng": "ko", "label": "스트랩" }], "tnClass": "tn-3-2" },
        "ornament": { "label": [{ "lng": "en", "label": "Ornament" }, { "lng": "ko", "label": "장식" }], "tnClass": "tn-3-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-4" },
      },
    },
    "extraBodyGroups": ["shadow"],
    "overrideBodyPartGroupName": {
      "cover": [{ "on": [["cover", ['all', 'laceup', 'strap']]], "to": ["cover", "neck"] }],
      "sole": [{ "to": ["sole", "toes"] }],
    },
  },
  "wbag": {
    "label": "Women Bags",
    "category": "wbag",
    "partList": ["size_h", "size_v", "handle", "closure"],
    "partListLabels": ["Size", "Size", "Handle", "Closure"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Size" }, { "lng": "ko", "label": "사이즈" }], "name": "size_h" },
      { "label": [{ "lng": "en", "label": "Size" }, { "lng": "ko", "label": "사이즈" }], "name": "size_v" },
      { "label": [{ "lng": "en", "label": "Handle" }, { "lng": "ko", "label": "핸들" }], "name": "handle" },
      { "label": [{ "lng": "en", "label": "Closure" }, { "lng": "ko", "label": "클로져" }], "name": "closure" },
    ],
    "defaultVal": { "direction": "tall", "size_h": "large", "size_v": "large", "size": "large", "handle": "medium", "closure": "flap" },
    "presetsList": {
      "Shoulder": { "direction": "tall", "size_h": "large", "size_v": "large", "size": "large", "handle": "medium", "closure": "flap" },
      "Bucket": { "direction": "tall", "size_h": "medium", "size_v": "medium", "size": "medium", "handle": "long", "closure": "string" },
      "Tote": { "direction": "tall", "size_h": "large", "size_v": "large", "size": "large", "handle": "short", "closure": "none" },
      "Wrist": { "direction": "wide", "size_h": "medium", "size_v": "medium", "size": "medium", "handle": "short", "closure": "plain" },
      "Big Bag": { "direction": "wide", "size_h": "large", "size_v": "large", "size": "large", "handle": "short", "closure": "non" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white/vf_wbag_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/common/TN-Women-Bags.svg?raw' ),
    "tn": {
      "size_h": {
        "small": { "label": [{ "lng": "en", "label": "Small" }, { "lng": "ko", "label": "스몰백" }], "tnClass": "tn-1-0" },
        "medium": { "label": [{ "lng": "en", "label": "Medium" }, { "lng": "ko", "label": "미디엄백" }], "tnClass": "tn-1-1" },
        "large": { "label": [{ "lng": "en", "label": "Large" }, { "lng": "ko", "label": "라지백" }], "tnClass": "tn-1-2" }
      },
      "size_v": {
        "small": { "label": [{ "lng": "en", "label": "Small" }, { "lng": "ko", "label": "스몰백" }], "tnClass": "tn-0-0" },
        "medium": { "label": [{ "lng": "en", "label": "Medium" }, { "lng": "ko", "label": "미디엄백" }], "tnClass": "tn-0-1" },
        "large": { "label": [{ "lng": "en", "label": "Large" }, { "lng": "ko", "label": "라지백" }], "tnClass": "tn-0-2" }
      },
      "handle": {
        "none": { "label": [{ "lng": "en", "label": "None" }, { "lng": "ko", "label": "없음" }], "tnClass": "tn-2-0" },
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "쇼트 핸들" }], "tnClass": "tn-2-1" },
        "medium": { "label": [{ "lng": "en", "label": "Medium" }, { "lng": "ko", "label": "미디엄 핸들" }], "tnClass": "tn-2-2" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱 핸들" }], "tnClass": "tn-2-3" }
      },
      "closure": {
        "none": { "label": [{ "lng": "en", "label": "None" }, { "lng": "ko", "label": "없음" }], "tnClass": "tn-3-0" },
        "flap": { "label": [{ "lng": "en", "label": "Flap" }, { "lng": "ko", "label": "플랩" }], "tnClass": "tn-3-1" },
        "zipup": { "label": [{ "lng": "en", "label": "Zipup" }, { "lng": "ko", "label": "지퍼" }], "tnClass": "tn-3-2" },
        "string": { "label": [{ "lng": "en", "label": "String" }, { "lng": "ko", "label": "스트링" }], "tnClass": "tn-3-3" }
      }
    },
    sanitizePropChange: function ( state, prop, sel ) {
      if ( prop === 'size_h' ) {
        state['direction'] = 'wide'
        state['size'] = sel
        state['size_v'] = sel
      }
      if ( prop === 'size_v' ) {
        state['direction'] = 'tall'
        state['size'] = sel
        state['size_h'] = sel
      }
      state[prop] = sel
    },
    "overrideBodyPartGroupName": {
      "handle": [
        {
          "on": [["handle", ["short", "medium", "long"]]],
          "to": ["handle", "size", "direction"],
        }
      ],
      "closure": [
        {
          "on": [["closure", ["flap", "zipup", "string"]]],
          "to": ["closure", "size", "direction"],
        }
      ],
    },
    overrideBodyPartGroupName_DEPRECATED: function ( state, prop, val ) {
      if ( prop === 'size_h' || prop === 'size_v' ) {
        if ( ( prop === 'size_h' && state['direction'] == 'wide' ) ||
          ( prop === 'size_v' && state['direction'] == 'tall' ) ) {
          return prop + '_' + val
        } else {
          return 'no_display_group'
        }
      }
      return null
    }
  },
  "wwallet": {
    "label": "Women Wallets",
    "category": "wwallet",
    "partList": ["size", "closure"],
    "partListLabels": ["Size", "Closure"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Size" }, { "lng": "ko", "label": "사이즈" }], "name": "size" },
      { "label": [{ "lng": "en", "label": "Closure" }, { "lng": "ko", "label": "클로져" }], "name": "closure" },
    ],
    "defaultVal": { "size": "tall", "closure": "envelope" },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white/vf_wwallet_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white/TN-Women-Wallets-2.0.svg?raw' ),
    "presetsList": {
      "Small": { "size": "small", "closure": "zipper" },
      "Card": { "size": "small", "closure": "pocket" },
      "Long": { "size": "large", "closure": "envelope" },
      "Bi-fold": { "size": "medium", "closure": "zipper" },
    },
    "tn": {
      "size": {
        "small": { "label": [{ "lng": "en", "label": "Small" }, { "lng": "ko", "label": "반지갑" }], "tnClass": "tn-0-0" },
        "medium": { "label": [{ "lng": "en", "label": "Medium" }, { "lng": "ko", "label": "중지갑" }], "tnClass": "tn-0-1" },
        "tall": { "label": [{ "lng": "en", "label": "Tall" }, { "lng": "ko", "label": "세로" }], "tnClass": "tn-0-2" },
        "large": { "label": [{ "lng": "en", "label": "Large" }, { "lng": "ko", "label": "장지갑" }], "tnClass": "tn-0-3" }
      },
      "closure": {
        "envelope": { "label": [{ "lng": "en", "label": "Flap" }, { "lng": "ko", "label": "플랩" }], "tnClass": "tn-1-0" },
        "zipper": { "label": [{ "lng": "en", "label": "Zipper" }, { "lng": "ko", "label": "지퍼" }], "tnClass": "tn-1-1" },
        "pocket": { "label": [{ "lng": "en", "label": "Pocket" }, { "lng": "ko", "label": "포켓" }], "tnClass": "tn-1-2" },
      }
    },
    "overrideBodyPartGroupName": {
      "closure": [
        { "on": [["closure", ["other"]]], "to": ["closure"] },
        { "to": ["closure", "size"] },
      ],
    },
  },
  "wearring": {
    "label": "Women Earrings",
    "category": "wearring",
    "partList": ["shape", "size"],
    "partListLabels": ["Shape", "Size"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Shape" }, { "lng": "ko", "label": "모양" }], "name": "shape" },
      { "label": [{ "lng": "en", "label": "Size" }, { "lng": "ko", "label": "사이즈" }], "name": "size" },
    ],
    "defaultVal": { "shape": "abstract", "size": "small" },
    "presetsList": {
      "Statement": { "shape": "abstract", "size": "large" },
      "Big hoop": { "shape": "ring", "size": "large" },
      "Drop": { "shape": "dropdown", "size": "small" },
      "Linear": { "shape": "dropdown", "size": "large" },
      "Pearl": { "shape": "abstract", "size": "small", "material": "pearl" },
      "Playful": { "shape": "specific", "size": "large" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white/vf_wearring_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/common/TN-Women-Earrings.svg?raw' ),
    "tn": {
      "size": {
        "small": { "label": [{ "lng": "en", "label": "Small" }, { "lng": "ko", "label": "스몰" }], "tnClass": "tn-2-0" },
        "medium": { "label": [{ "lng": "en", "label": "Medium" }, { "lng": "ko", "label": "미디엄" }], "tnClass": "tn-2-1" },
        "large": { "label": [{ "lng": "en", "label": "Large" }, { "lng": "ko", "label": "라지" }], "tnClass": "tn-2-2" }
      },
      "shape": {
        "abstract": { "label": [{ "lng": "en", "label": "Abstract" }, { "lng": "ko", "label": "추상적" }], "tnClass": "tn-0-0" },
        "specific": { "label": [{ "lng": "en", "label": "Specific" }, { "lng": "ko", "label": "Specific" }], "tnClass": "tn-0-1" },
        "ring": { "label": [{ "lng": "en", "label": "Ring" }, { "lng": "ko", "label": "링" }], "tnClass": "tn-0-2" },
        "dropdown": { "label": [{ "lng": "en", "label": "Dropdown" }, { "lng": "ko", "label": "드롭" }], "tnClass": "tn-0-3" }
      }
    },
    "overrideBodyPartGroupName": {
      "shape": [{ "to": ["shape", "size"] }],
      "size": [{ "to": "no_display_group" }],
    },
  },
  "men": {
    "label": "Men Global",
    "category": "men",
    "defaultVal": {},
  },
  "mtshirts": {
    "label": "Men T-Shirts",
    "category": "mtshirts",
    "partList": ["neckline", "sleeves", "waist"],
    "partListLabels": ["Neckline", "Sleeves", "Waist"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Neckline" }, { "lng": "ko", "label": "네크라인" }], "name": "neckline" },
      { "label": [{ "lng": "en", "label": "Sleeves" }, { "lng": "ko", "label": "소매 기장" }], "name": "sleeves" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
    ],
    "defaultVal": { "neckline": "all", "sleeves": "all", "waist": "all" },
    "presetsList": {
      "Crewneck": { "neckline": "flat", "sleeves": "short", "waist": "straight" },
      "Polo": { "neckline": "collar", "sleeves": "short", "waist": "straight" },
      "Hoodie": { "neckline": "hood", "sleeves": "long", "waist": "straight" },
      "Pullover": { "neckline": "high", "sleeves": "long", "waist": "tight" },
      "Tank": { "neckline": "flat", "sleeves": "sleeveless", "waist": "straight" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_mtshirts_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Tshirts-v2.0_OA.svg?raw' ),
    "tn": {
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Fitted" }, { "lng": "ko", "label": "슬림핏" }], "tnClass": "tn-2-0" },
        "straight": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러핏" }], "tnClass": "tn-2-1" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-2-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-3" },
      },
      "sleeves": {
        "sleeveless": { "label": [{ "lng": "en", "label": "Sleeveless" }, { "lng": "ko", "label": "민소매" }], "tnClass": "tn-1-0" },
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "반팔" }], "tnClass": "tn-1-1" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "긴팔" }], "tnClass": "tn-1-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-3" },
      },
      "neckline": {
        "flat": { "label": [{ "lng": "en", "label": "Round" }, { "lng": "ko", "label": "라운드넥" }], "tnClass": "tn-0-0" }, // =flat/round
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-1" },
        "hood": { "label": [{ "lng": "en", "label": "Hoodie" }, { "lng": "ko", "label": "후드" }], "tnClass": "tn-0-2" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-4" },
      }
    }
  },
  "mshirts": {
    "label": "Men Shirts",
    "category": "mshirts",
    "partList": ["neckline", "waist", "sleeves"],
    "partListLabels": ["Neckline", "Waist", "Sleeves"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Neckline" }, { "lng": "ko", "label": "네크라인" }], "name": "neckline" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
      { "label": [{ "lng": "en", "label": "Sleeves" }, { "lng": "ko", "label": "소매기장" }], "name": "sleeves" },
    ],
    "defaultVal": { "neckline": "all", "sleeves": "all", "waist": "all" },
    "presetsList": {
      "Basic": { "neckline": "short", "sleeves": "long", "waist": "tight", "design": "solid" },
      "Casual": { "neckline": "short", "sleeves": "short", "waist": "wide" },
      "Dressy": { "neckline": "large", "sleeves": "long", "waist": "tight" },
      "Henley": { "neckline": "henley", "sleeves": "long", "waist": "tight" },
      "Hawaiian": { "neckline": "camp", "sleeves": "short", "waist": "wide" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_mshirts_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Shirts-v2.0-OA.svg?raw' ),
    "tn": {
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Slim" }, { "lng": "ko", "label": "슬림핏" }], "tnClass": "tn-1-0" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-1-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-2" },
      },
      "sleeves": {
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "반팔" }], "tnClass": "tn-2-0" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "긴팔" }], "tnClass": "tn-2-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-2" },
      },
      "neckline": {
        "short": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "칼라" }], "tnClass": "tn-0-0" },
        "large": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "와이드 칼라" }], "tnClass": "tn-0-1" },
        "henley": { "label": [{ "lng": "en", "label": "Henley" }, { "lng": "ko", "label": "칼라리스" }], "tnClass": "tn-0-2" },
        "camp": { "label": [{ "lng": "en", "label": "Camp Collar" }, { "lng": "ko", "label": "오픈칼라" }], "tnClass": "tn-0-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-4" },
      }
    },
  },
  "mouter": {
    "label": "Men Outerwears",
    "category": "mouter",
    "partList": ["neckline", "waist", "length"],
    "partListLabels": ["Neckline", "Waist", "Length"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Neckline" }, { "lng": "ko", "label": "네크라인" }], "name": "neckline" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "상의기장" }], "name": "length" },
    ],
    "defaultVal": { "neckline": "all", "waist": "all", "length": "all" },
    "presetsList": {
      "Blazer": { "neckline": "lapel", "waist": "tight", "length": "short", "material": "cotton,wool" },
      "Trucker": { "neckline": "collar", "waist": "tight", "length": "short" },
      "Pullover": { "neckline": "high", "waist": "tight", "length": "short", "material": "fleece" },
      "Coat": { "neckline": "collar", "waist": "tight", "length": "long" },
      "Hood": { "neckline": "hood", "waist": "wide", "length": "short", "material": "cotton" },
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_mouter_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Outer-v2.0-OA.svg?raw' ),
    "tn": {
      "length": {
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "크롭/하프" }], "tnClass": "tn-3-0" },
        "regular": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-3-1" },
        "half": { "label": [{ "lng": "en", "label": "Half" }, { "lng": "ko", "label": "미디" }], "tnClass": "tn-3-2" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-3-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-4" },
      },
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Fitted" }, { "lng": "ko", "label": "슬림핏" }], "tnClass": "tn-1-0" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-1-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-2" },
      },
      "neckline": {
        "lapel": { "label": [{ "lng": "en", "label": "Lapel" }, { "lng": "ko", "label": "라펠" }], "tnClass": "tn-0-0" },
        "collarless": { "label": [{ "lng": "en", "label": "Collarless" }, { "lng": "ko", "label": "칼라리스" }], "tnClass": "tn-0-1" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-2" },
        "hood": { "label": [{ "lng": "en", "label": "Hood" }, { "lng": "ko", "label": "후드 " }], "tnClass": "tn-0-3" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-4" },
        "wide_collar": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "세일러" }], "tnClass": "tn-0-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-6" },
      }
    },
    "overrideBodyPartGroupName": {
      "length": [{ "to": ["length", "waist"] }],
      "waist": [{ "to": "no_display_group" }],
    },
  },
  "mpaddings": {
    "label": "Men Paddings",
    "category": "mouter",
    "partList": ["neckline", "length"],
    "partListLabels": ["Neckline", "Length"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Neckline" }, { "lng": "ko", "label": "네크라인" }], "name": "neckline" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "상의기장" }], "name": "length" },
    ],
    "defaultVal": { "neckline": "all", "length": "all" },
    "presetsList": {
      "Blazer": { "neckline": "low", "length": "short", "material": "cotton,wool" },
      "Trucker": { "neckline": "collar", "length": "short" },
      "Pullover": { "neckline": "high", "length": "short", "material": "fleece" },
      "Coat": { "neckline": "collar", "length": "long" },
      "Hood": { "neckline": "hood", "length": "short", "material": "cotton" },
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_mpaddings_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Paddings.svg?raw' ),
    "tn": {
      "length": {
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "크롭/하프" }], "tnClass": "tn-2-0" },
        "regular": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-2-1" },
        "half": { "label": [{ "lng": "en", "label": "Half" }, { "lng": "ko", "label": "미디" }], "tnClass": "tn-2-2" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-2-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-4" },
      },
      "neckline": {
        "lapel": { "label": [{ "lng": "en", "label": "Lapel" }, { "lng": "ko", "label": "라펠" }], "tnClass": "tn-0-0" },
        "collarless": { "label": [{ "lng": "en", "label": "Collarless" }, { "lng": "ko", "label": "칼라리스" }], "tnClass": "tn-0-1" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-2" },
        "hood": { "label": [{ "lng": "en", "label": "Hood" }, { "lng": "ko", "label": "후드 " }], "tnClass": "tn-0-3" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-4" },
        "wide_collar": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "세일러" }], "tnClass": "tn-0-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-6" },
      },
    },
    "extraBodyGroups": ["sleeves"]
  },
  "mcoats": {
    "label": "Men Coats",
    "category": "mouter",
    "partList": ["neckline", "waist", "length"], // "sleeves",
    "partListLabels": ["Neckline", "Waist", "Length"], // "Sleeves",
    "parts": [
      { "label": [{ "lng": "en", "label": "Neckline" }, { "lng": "ko", "label": "네크라인" }], "name": "neckline" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "상의기장" }], "name": "length" },
    ],
    "defaultVal": { "neckline": "all", "waist": "all", "length": "all" }, // "sleeves": "long",
    "presetsList": {
      "Blazer": { "neckline": "low", "waist": "tight", "length": "short", "material": "cotton,wool" },
      "Trucker": { "neckline": "collar", "waist": "tight", "length": "short" },
      "Pullover": { "neckline": "high", "waist": "tight", "length": "short", "material": "fleece" },
      "Coat": { "neckline": "collar", "waist": "tight", "length": "long" },
      "Hood": { "neckline": "hood", "waist": "wide", "length": "short", "material": "cotton" },
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_mcoats_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Coats.svg?raw' ),
    "tn": {
      "length": {
        "short": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "크롭/하프" }], "tnClass": "tn-2-0" },
        "regular": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-2-1" },
        "half": { "label": [{ "lng": "en", "label": "Half" }, { "lng": "ko", "label": "미디" }], "tnClass": "tn-2-2" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-2-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-4" },
      },
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Fitted" }, { "lng": "ko", "label": "슬림핏" }], "tnClass": "tn-1-0" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-1-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-2" },
      },
      "neckline": {
        "lapel": { "label": [{ "lng": "en", "label": "Lapel" }, { "lng": "ko", "label": "라펠" }], "tnClass": "tn-0-0" },
        "collarless": { "label": [{ "lng": "en", "label": "Collarless" }, { "lng": "ko", "label": "칼라리스" }], "tnClass": "tn-0-1" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-2" },
        "hood": { "label": [{ "lng": "en", "label": "Hood" }, { "lng": "ko", "label": "후드" }], "tnClass": "tn-0-3" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-4" },
        "wide_collar": { "label": [{ "lng": "en", "label": "Wide" }, { "lng": "ko", "label": "세일러" }], "tnClass": "tn-0-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-6" },
      },
    },
    "extraBodyGroups": ["sleeves"],
    "overrideBodyPartGroupName": {
      "length": [{ "to": ["length", "waist"] }],
      "waist": [{ "to": "no_display_group" }],
    },
  },
  "mpullover": {
    "label": "Men Pullover",
    "category": "mknit",
    "partList": ["neckline", "sleeves", "waist"],
    "partListLabels": ["Neckline", "Sleeves", "Waist"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Neckline" }, { "lng": "ko", "label": "네크라인" }], "name": "neckline" },
      { "label": [{ "lng": "en", "label": "Sleeves" }, { "lng": "ko", "label": "소매기장" }], "name": "sleeves" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
    ],
    "defaultVal": { "neckline": "all", "sleeves": "all", "waist": "all" },
    "presetsList": {
      "V-neck": { "neckline": "low", "sleeves": "long", "waist": "tight", "design": "solid" },
      "Pullover": { "neckline": "high", "sleeves": "long", "waist": "tight" },
      "Collared": { "neckline": "collar", "sleeves": "long", "waist": "tight", "design": "solid" },
      "High-neck": { "neckline": "high", "sleeves": "long", "waist": "wide" },
      "Vest": { "neckline": "low", "sleeves": "sleeveless", "waist": "tight" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_mpullover_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Sweater-v2.5-OptionAll.svg?raw' ),
    "tn": {
      "neckline": {
        "v_neck": { "label": [{ "lng": "en", "label": "V-neck" }, { "lng": "ko", "label": "브이넥" }], "tnClass": "tn-0-0" },
        "round": { "label": [{ "lng": "en", "label": "Round" }, { "lng": "ko", "label": "라운드넥" }], "tnClass": "tn-0-1" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-2" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-3" },
        "hoodie": { "label": [{ "lng": "en", "label": "Hood" }, { "lng": "ko", "label": "후드 " }], "tnClass": "tn-0-4" },
        "mock": { "label": [{ "lng": "en", "label": "Mock" }, { "lng": "ko", "label": "모크" }], "tnClass": "tn-0-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-6" },
      },
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Fitted" }, { "lng": "ko", "label": "슬림핏" }], "tnClass": "tn-2-0" },
        "straight": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러핏" }], "tnClass": "tn-2-0" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-2-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-2" },
      },
      "sleeves": {
        "sleeveless": { "label": [{ "lng": "en", "label": "Sleeveless" }, { "lng": "ko", "label": "민소매" }], "tnClass": "tn-1-0" },
        "half": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "반팔" }], "tnClass": "tn-1-1" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "긴팔" }], "tnClass": "tn-1-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-3" },
      }
    },
  },
  "mcardigan": {
    "label": "Men Cardigans",
    "category": "mknit",
    "partList": ["neckline", "sleeves", "waist"],
    "partListLabels": ["Neckline", "Sleeves", "Waist"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Neckline" }, { "lng": "ko", "label": "네크라인" }], "name": "neckline" },
      { "label": [{ "lng": "en", "label": "Sleeves" }, { "lng": "ko", "label": "소매기장" }], "name": "sleeves" },
      { "label": [{ "lng": "en", "label": "Waist" }, { "lng": "ko", "label": "허리핏" }], "name": "waist" },
    ],
    "defaultVal": { "neckline": "all", "sleeves": "all", "waist": "all" },
    "presetsList": {
      "V-neck": { "neckline": "low", "sleeves": "long", "waist": "tight", "design": "solid" },
      "Pullover": { "neckline": "high", "sleeves": "long", "waist": "tight" },
      "Collared": { "neckline": "collar", "sleeves": "long", "waist": "tight", "design": "solid" },
      "High-neck": { "neckline": "high", "sleeves": "long", "waist": "wide" },
      "Vest": { "neckline": "low", "sleeves": "sleeveless", "waist": "tight" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_mcardigan_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Cardigan.svg?raw' ),
    "tn": {
      "neckline": {
        "v_neck": { "label": [{ "lng": "en", "label": "V-neck" }, { "lng": "ko", "label": "브이넥" }], "tnClass": "tn-0-0" },
        "round": { "label": [{ "lng": "en", "label": "Round" }, { "lng": "ko", "label": "라운드넥" }], "tnClass": "tn-0-1" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-0-2" },
        "hoodie": { "label": [{ "lng": "en", "label": "Hood" }, { "lng": "ko", "label": "후드" }], "tnClass": "tn-0-3" },
        "collar": { "label": [{ "lng": "en", "label": "Collar" }, { "lng": "ko", "label": "카라" }], "tnClass": "tn-0-4" },
        "open": { "label": [{ "lng": "en", "label": "Open" }, { "lng": "ko", "label": "오픈" }], "tnClass": "tn-0-5" },
        "mock": { "label": [{ "lng": "en", "label": "Mock" }, { "lng": "ko", "label": "모크" }], "tnClass": "tn-0-6" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-7" },
      },
      "waist": {
        "tight": { "label": [{ "lng": "en", "label": "Fitted" }, { "lng": "ko", "label": "슬림핏" }], "tnClass": "tn-2-0" },
        "straight": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러핏" }], "tnClass": "tn-2-1" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-2-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-3" },
      },
      "sleeves": {
        "sleeveless": { "label": [{ "lng": "en", "label": "Sleeveless" }, { "lng": "ko", "label": "민소매" }], "tnClass": "tn-1-0" },
        "half": { "label": [{ "lng": "en", "label": "Short" }, { "lng": "ko", "label": "반팔" }], "tnClass": "tn-1-1" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "긴팔" }], "tnClass": "tn-1-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-3" },
      }
    },
  },
  "mpants": {
    "label": "Men Pants",
    "category": "mpants",
    "partList": ["fit", "length", "ankle"],
    "partListLabels": ["Fit", "Length", "Ankle"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Fit" }, { "lng": "ko", "label": "핏" }], "name": "fit" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
      { "label": [{ "lng": "en", "label": "Ankle" }, { "lng": "ko", "label": "앵클" }], "name": "ankle" },
    ],
    "defaultVal": { "fit": "wide", "length": "cropped", "ankle": "straight" },
    "presetsList": {
      "Wide": { "fit": "wide", "length": "cropped", "ankle": "wide" },
      "Dress": { "fit": "wide", "length": "long", "ankle": "straight", "material": "wool" },
      "Straight": { "fit": "straight", "length": "long", "ankle": "straight", "material": "denim" },
      "Skinny": { "fit": "tight", "length": "long", "ankle": "tight" },
      "Jogger": { "fit": "wide", "length": "long", "ankle": "tight", "material": "cotton" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_mpants_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Pants-v2.0-OA.svg?raw' ),
    "tn": {
      "fit": {
        "tight": { "label": [{ "lng": "en", "label": "Fitted" }, { "lng": "ko", "label": "타이트핏" }], "tnClass": "tn-0-0" },
        "straight": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-0-1" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-0-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-3" },
      },
      "length": {
        "cropped": { "label": [{ "lng": "en", "label": "Cropped" }, { "lng": "ko", "label": "크롭" }], "tnClass": "tn-1-1" },
        "long": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-1-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-3" },
      },
      "ankle": {
        "tight": { "label": [{ "lng": "en", "label": "Tight" }, { "lng": "ko", "label": "스키니" }], "tnClass": "tn-2-0" },
        "straight": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-2-1" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈" }], "tnClass": "tn-2-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-3" },
      }
    },
    "extraBodyGroups": ["zipper"],
    "overrideBodyPartGroupName": {
      "length": [{ "to": ["length", "fit"] }],
      "ankle": [{ "to": ["ankle", "length", "fit"] }],
      "fit": [{ "to": "no_display_group" }],
    },
  },
  "mpants2": {
    "label": "Men Pants 2",
    "category": "mpants",
    "partList": ["fit", "length"],
    "partListLabels": ["Fit", "Length"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Fit" }, { "lng": "ko", "label": "핏" }], "name": "fit" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
    ],
    "defaultVal": { "fit": "all", "length": "all" },
    "presetsList": {
      "Wide": { "fit": "wide", "length": "cropped" },
      "Dress": { "fit": "wide", "length": "long", "material": "wool" },
      "Straight": { "fit": "straight", "length": "long", "material": "denim" },
      "Skinny": { "fit": "tight", "length": "long" },
      "Jogger": { "fit": "wide", "length": "long", "material": "cotton" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_mpants2_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Pants2.svg?raw' ),
    "tn": {
      "fit": {
        "skinny": { "label": [{ "lng": "en", "label": "Skinny" }, { "lng": "ko", "label": "스키니" }], "tnClass": "tn-0-0" },
        "straight": { "label": [{ "lng": "en", "label": "Straight" }, { "lng": "ko", "label": "스트레이트" }], "tnClass": "tn-0-1" },
        "bootcut": { "label": [{ "lng": "en", "label": "Bootcut" }, { "lng": "ko", "label": "부트컷" }], "tnClass": "tn-0-2" },
        "tapered": { "label": [{ "lng": "en", "label": "Tapered" }, { "lng": "ko", "label": "테이퍼드" }], "tnClass": "tn-0-3" },
        "relax": { "label": [{ "lng": "en", "label": "Relax" }, { "lng": "ko", "label": "릴렉스" }], "tnClass": "tn-0-4" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루스" }], "tnClass": "tn-0-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-6" }
      },
      "length": {
        "cropped": { "label": [{ "lng": "en", "label": "Cropped" }, { "lng": "ko", "label": "크롭" }], "tnClass": "tn-1-0" },
        "regular": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-1-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-2" }
      }
    },
    "extraBodyGroups": ["zipper"],
    "overrideBodyPartGroupName": {
      "length": [{ "to": ["length", "fit"] }],
      "fit": [{ "to": "no_display_group" }],
    },
  },
  "mshorts": {
    "label": "Men Shorts",
    "category": "mpants",
    "partList": ["fit", "length"],
    "partListLabels": ["Fit", "Length"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Fit" }, { "lng": "ko", "label": "핏" }], "name": "fit" },
      { "label": [{ "lng": "en", "label": "Length" }, { "lng": "ko", "label": "기장" }], "name": "length" },
    ],
    "defaultVal": { "fit": "all", "length": "all" },
    "presetsList": {
      "Wide": { "fit": "wide", "length": "all" },
      "Straight": { "fit": "straight", "length": "all" },
      "Skinny": { "fit": "skinny", "length": "all" },
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_mshorts_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Shorts.svg?raw' ),
    "tn": {
      "fit": {
        "skinny": { "label": [{ "lng": "en", "label": "Fitted" }, { "lng": "ko", "label": "타이트핏" }], "tnClass": "tn-0-0" },
        "straight": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-0-1" },
        "wide": { "label": [{ "lng": "en", "label": "Loose" }, { "lng": "ko", "label": "루즈핏" }], "tnClass": "tn-0-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-3" },
      },
      "length": {
        "mini": { "label": [{ "lng": "en", "label": "Shorter" }, { "lng": "ko", "label": "크롭" }], "tnClass": "tn-1-0" },
        "short": { "label": [{ "lng": "en", "label": "Standard" }, { "lng": "ko", "label": "레귤러" }], "tnClass": "tn-1-1" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-2" },
      }
    },
    "extraBodyGroups": ["zipper"],
    "overrideBodyPartGroupName": {
      "length": [{ "to": ["length", "fit"] }],
      "fit": [{ "to": "no_display_group" }],
    },
  },
  "mdressshoes": {
    "label": "Men Dress Shoes",
    "category": "mdressshoes",
    "partList": ["toes", "neck", "sole", "cover"],
    "partListLabels": ["Toes", "Neck", "Sole", "Cover"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Toes" }, { "lng": "ko", "label": "신발코" }], "name": "toes" },
      { "label": [{ "lng": "en", "label": "Neck" }, { "lng": "ko", "label": "목길이" }], "name": "neck" },
      { "label": [{ "lng": "en", "label": "Sole" }, { "lng": "ko", "label": "솔" }], "name": "sole" },
      { "label": [{ "lng": "en", "label": "Cover" }, { "lng": "ko", "label": "커버" }], "name": "cover" },
    ],
    "defaultVal": { "toes": "all", "neck": "all", "sole": "all", "cover": "all" },
    "presetsList": {
      "Classic": { "toes": "round", "neck": "open", "sole": "heel", "cover": "oxford", "material": "leather" },
      "Loafer": { "toes": "pointy", "neck": "open", "sole": "heel", "cover": "plain", "material": "leather" },
      "Tassel": { "toes": "pointy", "neck": "open", "sole": "heel", "cover": "tassel", "material": "leather" },
      "Chelsea": { "toes": "pointy", "neck": "ankle", "sole": "heel", "cover": "plain", "material": "leather" },
      "Boots": { "toes": "round", "neck": "high", "sole": "chunky", "cover": "oxford" },
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_mdressshoes_core.svg' ),
    "tnSprite": "dressshoes",
    "tnSpriteOptions": [
      { "label": "Dress Shoes", "name": "dressshoes", "sprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Shoes-v2.0-OA.svg?raw' ) },
      { "label": "Boots", "name": "boots", "sprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Boots.svg?raw' ) },
    ],
    "tn": {
      "toes": {
        "pointy": { "label": [{ "lng": "en", "label": "Pointed" }, { "lng": "ko", "label": "포인티토" }], "tnClass": "tn-0-0" },
        "round": { "label": [{ "lng": "en", "label": "Round" }, { "lng": "ko", "label": "라운드토" }], "tnClass": "tn-0-1" },
        "apron": { "label": [{ "lng": "en", "label": "Apron" }, { "lng": "ko", "label": "에이프런토" }], "tnClass": "tn-0-2" },
        "square": { "label": [{ "lng": "en", "label": "Square" }, { "lng": "ko", "label": "스퀘어" }], "tnClass": "tn-0-3" },
        "open": { "label": [{ "lng": "en", "label": "Open" }, { "lng": "ko", "label": "Open" }], "tnClass": "tn-0-4" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-5" },
      },
      "neck": {
        "low": { "label": [{ "lng": "en", "label": "Regular" }, { "lng": "ko", "label": "로우" }], "tnClass": "tn-1-0" },
        "full": { "label": [{ "lng": "en", "label": "Full" }, { "lng": "ko", "label": "Full" }], "tnClass": "tn-1-1" },
        "ankle": { "label": [{ "lng": "en", "label": "Ankle" }, { "lng": "ko", "label": "미디" }], "tnClass": "tn-1-2" },
        "high": { "label": [{ "lng": "en", "label": "High" }, { "lng": "ko", "label": "터틀넥" }], "tnClass": "tn-1-3" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-4" },
      },
      "sole": {
        "heel": { "label": [{ "lng": "en", "label": "Heel" }, { "lng": "ko", "label": "힐" }], "tnClass": "tn-2-0" },
        "solid": { "label": [{ "lng": "en", "label": "Flat" }, { "lng": "ko", "label": "솔리드" }], "tnClass": "tn-2-1" },
        "chunky": { "label": [{ "lng": "en", "label": "Chunky" }, { "lng": "ko", "label": "청키" }], "tnClass": "tn-2-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-3" },
      },
      "cover": {
        "plain": { "label": [{ "lng": "en", "label": "Plain" }, { "lng": "ko", "label": "플레인" }], "tnClass": "tn-3-0" },
        "ornament": { "label": [{ "lng": "en", "label": "Ornament" }, { "lng": "ko", "label": "장식" }], "tnClass": "tn-3-1" },
        "laceup": { "label": [{ "lng": "en", "label": "Laceup" }, { "lng": "ko", "label": "레이스업" }], "tnClass": "tn-3-2" },
        "penny": { "label": [{ "lng": "en", "label": "Penny" }, { "lng": "ko", "label": "페니" }], "tnClass": "tn-3-3" },
        "tassel": { "label": [{ "lng": "en", "label": "Tassel/Horsebit" }, { "lng": "ko", "label": "Tassel/Horsebit" }], "tnClass": "tn-3-4" },
        "strap": { "label": [{ "lng": "en", "label": "Strap" }, { "lng": "ko", "label": "스트랩" }], "tnClass": "tn-3-5" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-3" },
      }
    },
    "extraBodyGroups": ["shadow"],
    "overrideBodyPartGroupName": {
      "sole": [{ "to": ["sole", "toes"] }],
      "neck": [
        { "on": [["neck", ["all"]], ["cover", ["laceup"]]], "to": "neck_all_laceup" },
        { "on": [["neck", ["low"]], ["cover", ["laceup"]]], "to": "neck_low_laceup" },
      ],
      "cover": [{ "on": [["cover", ["laceup"]]], "to": ["neck", "cover"] }],
      // "cover": [{ "on": [["cover", ["plain"]]], "to": "no_display_group" }],
      // "toes": [{ "to": "no_display_group" }],
      // "cover": [{ "on": [["cover", ["all"]]], "to": "no_display_group" }],
      // "neck": [{ "on": [["neck", ["open", "all"]], ["cover", ["oxford"]]], "to": ["neck", "cover"] }],
    },
    sanitizePropChange( state, prop, sel ) {
      if ( prop === 'neck' && sel !== 'open' ) {
        if ( state['toes'] == "open" ) {
          state['toes'] = "round"
        }
      }
      state[prop] = sel
    },
  },
  "msneakers": {
    "label": "Men Sneakers",
    "category": "msneakers",
    "partList": ["toes", "neck", "sole", "cover"],
    "partListLabels": ["Toe Styles", "Neck Length", "Sole Styles", "Cover Style"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Toes" }, { "lng": "ko", "label": "토" }], "name": "toes" },
      { "label": [{ "lng": "en", "label": "Neck" }, { "lng": "ko", "label": "발목" }], "name": "neck" },
      { "label": [{ "lng": "en", "label": "Sole" }, { "lng": "ko", "label": "솔" }], "name": "sole" },
      { "label": [{ "lng": "en", "label": "Cover" }, { "lng": "ko", "label": "커버" }], "name": "cover" },
    ],
    "defaultVal": { "toes": "all", "neck": "all", "sole": "all", "cover": "all" },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white-all/vf_msneakers_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white-all/TN-Men-Sneakers-2.0-OA.svg?raw' ),
    "presetsList": {
      "Low top": { "toes": "flat", "neck": "low", "sole": "low", "cover": "laceup", "design": "solid" },
      "High top": { "toes": "flat", "neck": "high", "sole": "low", "cover": "laceup" },
      "Slip-on": { "toes": "flat", "neck": "low", "sole": "low", "cover": "plain" },
      "Chunky": { "toes": "pointy", "neck": "full", "sole": "chunky", "cover": "laceup" },
      "Running": { "toes": "round", "neck": "low", "sole": "grip", "cover": "laceup" },
      "Sock": { "toes": "round", "neck": "high", "sole": "low", "cover": "plain" },
    },
    "extraBodyGroups": ["shadow"],
    "tn": {
      "toes": {
        "pointy": { "label": [{ "lng": "en", "label": "Pointed" }, { "lng": "ko", "label": "포인티토" }], "tnClass": "tn-0-0" },
        "round": { "label": [{ "lng": "en", "label": "Round" }, { "lng": "ko", "label": "라운드토" }], "tnClass": "tn-0-1" },
        "flat": { "label": [{ "lng": "en", "label": "Flat" }, { "lng": "ko", "label": "플랫" }], "tnClass": "tn-0-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-0-3" },
      },
      "neck": {
        "low": { "label": [{ "lng": "en", "label": "Low top" }, { "lng": "ko", "label": "로우탑" }], "tnClass": "tn-1-0" },
        "full": { "label": [{ "lng": "en", "label": "Mid top" }, { "lng": "ko", "label": "미드탑" }], "tnClass": "tn-1-1" },
        "high": { "label": [{ "lng": "en", "label": "High top" }, { "lng": "ko", "label": "하이탑" }], "tnClass": "tn-1-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-1-3" },
      },
      "sole": {
        "low": { "label": [{ "lng": "en", "label": "Flat" }, { "lng": "ko", "label": "플랫" }], "tnClass": "tn-2-0" },
        "grip": { "label": [{ "lng": "en", "label": "Grip" }, { "lng": "ko", "label": "그립" }], "tnClass": "tn-2-1" },
        "chunky": { "label": [{ "lng": "en", "label": "Chunky" }, { "lng": "ko", "label": "청키" }], "tnClass": "tn-2-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-2-3" },
      },
      "cover": {
        "plain": { "label": [{ "lng": "en", "label": "Plain" }, { "lng": "ko", "label": "플레인" }], "tnClass": "tn-3-0" },
        "laceup": { "label": [{ "lng": "en", "label": "Laceup" }, { "lng": "ko", "label": "레이스업" }], "tnClass": "tn-3-1" },
        "strap": { "label": [{ "lng": "en", "label": "Strap" }, { "lng": "ko", "label": "스트랩" }], "tnClass": "tn-3-2" },
        "all": { "label": [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "전체" }], "tnClass": "tn-3-3" },
      }
    },
    "overrideBodyPartGroupName": {
      "sole": [{ "to": ["sole", "toes"] }],
      "cover": [{ "on": [["cover", ["strap", "laceup", "all"]]], "to": ["cover", "neck"] }],
    },
  },
  "mbag": {
    "label": "Men Bags",
    "category": "mbag",
    "partList": ["size_h", "size_v", "handle", "style"],
    "partListLabels": ["Size", "Size", "Handle", "Style"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Size" }, { "lng": "ko", "label": "사이즈" }], "name": "size_h" },
      { "label": [{ "lng": "en", "label": "Size" }, { "lng": "ko", "label": "사이즈" }], "name": "size_v" },
      { "label": [{ "lng": "en", "label": "Handle" }, { "lng": "ko", "label": "핸들" }], "name": "handle" },
      { "label": [{ "lng": "en", "label": "Style" }, { "lng": "ko", "label": "클로져" }], "name": "style" },
    ],
    "defaultVal": { "direction": "tall", "size": "large", "size_h": "large", "size_v": "large", "handle": "medium", "style": "flap" },
    "presetsList": {
      "Backpack": { "direction": "tall", "size": "large", "size_h": "large", "size_v": "large", "handle": "backpack", "style": "flap" },
      "Tote": { "direction": "tall", "size": "medium", "size_h": "medium", "size_v": "medium", "handle": "medium", "style": "none" },
      "Hip Pack": { "direction": "wide", "size": "small", "size_h": "small", "size_v": "small", "handle": "none", "style": "zipper" },
      "Cross Body": { "direction": "tall", "size": "small", "size_h": "small", "size_v": "small", "handle": "long", "style": "zipper" },
      "Messenger": { "direction": "wide", "size": "large", "size_h": "large", "size_v": "large", "handle": "long", "style": "flap" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/usdemo/vf_mbag_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/common/TN-Men-Bags.svg?raw' ),
    "tn": {
      "size_h": {
        "small": { "label": [{ "lng": "en", "label": "Small" }, { "lng": "ko", "label": "스몰" }], "tnClass": "tn-1-0" },
        "medium": { "label": [{ "lng": "en", "label": "Medium" }, { "lng": "ko", "label": "미디엄" }], "tnClass": "tn-1-1" },
        "large": { "label": [{ "lng": "en", "label": "Large" }, { "lng": "ko", "label": "라지" }], "tnClass": "tn-1-2" }
      },
      "size_v": {
        "small": { "label": [{ "lng": "en", "label": "Small" }, { "lng": "ko", "label": "스몰" }], "tnClass": "tn-2-0" },
        "medium": { "label": [{ "lng": "en", "label": "Medium" }, { "lng": "ko", "label": "미디엄" }], "tnClass": "tn-2-1" },
        "large": { "label": [{ "lng": "en", "label": "Large" }, { "lng": "ko", "label": "라지" }], "tnClass": "tn-2-2" },
      },
      "handle": {
        "none": { "label": [{ "lng": "en", "label": "None" }, { "lng": "ko", "label": "없음" }], "tnClass": "tn-3-0" },
        "medium": { "label": [{ "lng": "en", "label": "Medium" }, { "lng": "ko", "label": "미디엄" }], "tnClass": "tn-3-2" },
        "long": { "label": [{ "lng": "en", "label": "Strap" }, { "lng": "ko", "label": "롱" }], "tnClass": "tn-3-3" },
        "backpack": { "label": [{ "lng": "en", "label": "Backpack" }, { "lng": "ko", "label": "백팩" }], "tnClass": "tn-3-4" },
      },
      "style": {
        "none": { "label": [{ "lng": "en", "label": "Open" }, { "lng": "ko", "label": "오픈" }], "tnClass": "tn-4-0" },
        "flap": { "label": [{ "lng": "en", "label": "Flap" }, { "lng": "ko", "label": "플랩" }], "tnClass": "tn-4-1" },
        "zipper": { "label": [{ "lng": "en", "label": "Zipper" }, { "lng": "ko", "label": "지퍼" }], "tnClass": "tn-4-2" }
      }
    },
    sanitizePropChange: function ( state, prop, sel ) {
      if ( prop === 'size_h' ) {
        if ( state['direction'] === 'tall' )
          state['direction'] = 'wide'
        state['size'] = sel
        state['size_v'] = sel
      }
      if ( prop === 'size_v' ) {
        state['direction'] = 'tall'
        state['size'] = sel
        state['size_h'] = sel
      }
      // backpack handle + horizontal bag combo is not allowed
      if ( prop === 'handle' && sel === 'backpack' ) {
        state['direction'] = 'tall'
      }
      if ( prop === 'size_h' && state['handle'] === 'backpack' ) {
        state['handle'] = 'medium'
      }
      state[prop] = sel
    },
    overrideBodyPartGroupName: function ( state, prop, val ) {
      if ( prop === 'size_h' || prop === 'size_v' ) {
        if ( ( prop === 'size_h' && state['direction'] == 'wide' ) ||
          ( prop === 'size_v' && state['direction'] == 'tall' ) ) {
          return prop + '_' + val
        } else {
          return 'no_display_group'
        }
      }
      if ( prop === 'handle' && val !== 'none' ) {
        return 'handle_' + val + '_for_' + state['size'] + '_' + state['direction']
      }
      if ( prop === 'style' && val !== 'none' ) {
        return 'style_' + val + '_for_' + state['size'] + '_' + state['direction']
      }
      return null
    }
  },
  "mwallet": {
    "label": "Men Wallets",
    "category": "mwallet",
    "partList": ["size", "style"],
    "partListLabels": ["Size", "Style"],
    "parts": [
      { "label": [{ "lng": "en", "label": "Size" }, { "lng": "ko", "label": "사이즈" }], "name": "size" },
      { "label": [{ "lng": "en", "label": "Style" }, { "lng": "ko", "label": "클로져" }], "name": "style" },
    ],
    "defaultVal": { "size": "tall", "style": "folder" },
    "presetsList": {
      "Fold": { "size": "half", "style": "folder" },
      "Long": { "size": "long", "style": "zipper" },
      "Card": { "size": "half", "style": "pocket" },
      "Fabric": { "size": "half", "style": "folder", "material": "fabric" }
    },
    "vfSvgSource": require( '@yesplz/core-models/assets/svg/white/vf_mwallet_core.svg' ),
    "tnSprite": require( '@yesplz/core-models/assets/svg/white/TN-Men-Wallets-2.0.svg?raw' ),
    "tn": {
      "size": {
        "half": { "label": [{ "lng": "en", "label": "Half" }, { "lng": "ko", "label": "반지갑" }], "tnClass": "tn-0-0" },
        "long": { "label": [{ "lng": "en", "label": "Long" }, { "lng": "ko", "label": "장지갑" }], "tnClass": "tn-0-1" },
        "tall": { "label": [{ "lng": "en", "label": "Tall" }, { "lng": "ko", "label": "세로" }], "tnClass": "tn-0-2" },
      },
      "style": {
        "plain": { "label": [{ "lng": "en", "label": "Plain" }, { "lng": "ko", "label": "플레인" }], "tnClass": "tn-1-0" },
        "zipper": { "label": [{ "lng": "en", "label": "Zipper" }, { "lng": "ko", "label": "지퍼" }], "tnClass": "tn-1-1" },
        "folder": { "label": [{ "lng": "en", "label": "Folder" }, { "lng": "ko", "label": "폴더" }], "tnClass": "tn-1-2" },
        "pocket": { "label": [{ "lng": "en", "label": "Pocket" }, { "lng": "ko", "label": "포켓" }], "tnClass": "tn-1-3" }
      }
    },
    "overrideBodyPartGroupName": {
      "style": [
        {
          "on": [["style", ["zipper", "folder", "pocket"]]],
          "to": ["style", "size"]
        },
      ],
    },
  },
};

const extendedCategories = cloneDeep( baseCategories );
const categories = baseCategories;

Object.keys( extendedCategories ).forEach( categoryId => {
  if (extendedCategories[categoryId].parts && extendedCategories[categoryId].parts.length)
    extendedCategories[categoryId].parts = extendedCategories[categoryId].parts
      ? extendedCategories[categoryId].parts
      : extendedCategories[categoryId]
        .partList.map( ( name, i ) => {
          const partListLabels = extendedCategories[categoryId].partListLabels;
          return { label: partListLabels[i], name };
        } );

  if ( isPlainObject( extendedCategories[categoryId].presetsList ) ) {
    extendedCategories[categoryId].presetsList = Object.entries( extendedCategories[categoryId].presetsList )
      .map( ( [label, preset] ) => ( { label, value: label, preset } ) );
  }

  extendedCategories[categoryId].occasionsList = [
    { "label": "Casual", "value": "casual" },
    { "label": "Comfy", "value": "comfy" },
    { "label": "Night Out", "value": "nightout" },
    { "label": "Work", "value": "work" },
    { "label": "Formal", "value": "formal" },
    { "label": "Semi Formal", "value": "semiformal" },
    { "label": "Boho", "value": "boho" },
    { "label": "Vintage Inspired", "value": "retro" },
    { "label": "Vacation", "value": "beach" },
    { "label": "Workout", "value": "workout" },
    { "label": "Utility", "value": "utility" },
  ];

  extendedCategories[categoryId].vibesList = [
    { "label": "Casual", "value": "casual" },
    { "label": "Work", "value": "work" },
    { "label": "Nightout", "value": "nightout" },
    { "label": "Comfy", "value": "comfy" },
    { "label": "Romantic", "value": "romantic" },
    { "label": "Business Casual", "value": "businesscasual" },
    { "label": "Beach", "value": "beach" },
    { "label": "Workout", "value": "workout" },
    { "label": "Minimal", "value": "minimal" },
    { "label": "Statement", "value": "statement" },
    { "label": "Cocktail", "value": "cocktail" }, // dress only
    { "label": "Formal", "value": "formal" },
    { "label": "Utility", "value": "utility" }, // men top/pants only
    { "label": "Extra-warm", "value": "extrawarm" }, // outer only
  ];

  extendedCategories[categoryId].moodsList = [
    { "label": "Chic", "value": "chic" },
    { "label": "Classic", "value": "classic" },
    { "label": "Basic", "value": "basic" },
    { "label": "Modern", "value": "modern" },
    { "label": "Ethnic", "value": "ethnic" },
    { "label": "Romantic", "value": "romantic" },
    { "label": "Elegance", "value": "elegance" },
    { "label": "Young Trendy", "value": "youngtrendy" },
    { "label": "Sporty", "value": "sporty" },
    { "label": "Street", "value": "street" },
    { "label": "Vintage", "value": "vintage" },
    { "label": "Glam", "value": "glam" },
  ];

  if (['wpants', 'wpants2', 'mpants', 'mpants2'].includes(categoryId)) {
    extendedCategories[categoryId].wash = [
      { label: [{ "lng": "en", "label": "Dark" }, { "lng": "ko", "label": "Dark" }], value: 'dark', className: 'wide-image', image: patternWashedDarkSvg },
      { label: [{ "lng": "en", "label": "Medium" }, { "lng": "ko", "label": "Medium" }], value: 'medium', className: 'wide-image', image: patternWashedMediumSvg },
      { label: [{ "lng": "en", "label": "Light" }, { "lng": "ko", "label": "Light" }], value: 'light', className: 'wide-image', image: patternWashedLightSvg },
    ];
  }

  extendedCategories[categoryId].patterns = patternTypes.map( ( { type, label } ) => ( {
    value: type, type, label,
  } ) );

  extendedCategories[categoryId].materials = materialTypes.map( ( { type, label } ) => ( {
    value: type, type, label,
  } ) );

  extendedCategories[categoryId].categoryId = categoryId;
} );

async function fetchConfiguration() {
  const { data: configuration } = await axios.get( `https://admin-proxy.dev.yesplz.ai/retailers/${process.env.RETAILER}/configuration` );

  const categories = Object.entries(sortCategories(fillCategoriesByParent(configuration))).reduce( ( categories, [categoryId, category] ) => {
    if ( category.isPublished ) {
      if ( !category.vibesList ) category.vibesList = [];
      categories[categoryId] = {
        ...extendedCategories[category.baseCategory || categoryId],
        ...category,
        presetsList: category.presetsList || [],
        occasionsList: category.occasionsList || [],
        vibesList: category.vibesList || [],
        moodsList: category.moodsList || [],
        patterns: category.patterns || [],
        materials: category.materials || [],
      };
    }
    return categories;
  }, {} );

  return {
    categories,
    patternsFilterParams,
    materialsFilterParams,
  };
}

export { extendedCategories, categories, fetchConfiguration };
