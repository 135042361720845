import MaterialCottonSvg from '../../assets/svg/material-cotton.svg'
import MaterialSilkSvg from '../../assets/svg/material-silk.svg'
import MaterialWrinkleFreeSvg from '../../assets/svg/material-wrinkle-free.svg'
import MaterialDenimSvg from '../../assets/svg/material-denim.svg'
import MaterialSpandexSvg from '../../assets/svg/material-spandex.svg'
import MaterialWoolSvg from '../../assets/svg/material-wool.svg'
import MaterialSuedeSvg from '../../assets/svg/material-suede.svg'
import MaterialLeatherSvg from '../../assets/svg/material-leather.svg'
import MaterialGlossySvg from '../../assets/svg/material-glossy.svg'
import MaterialFabricSvg from '../../assets/svg/material-fabric.svg'

// materials
export const MaterialSVG = {
  'cotton': MaterialCottonSvg,
  'silk': MaterialSilkSvg,
  'wrinkle-free': MaterialWrinkleFreeSvg,
  'denim': MaterialDenimSvg,
  'spandex': MaterialSpandexSvg,
  'wool': MaterialWoolSvg,
  'suede': MaterialSuedeSvg,
  'leather': MaterialLeatherSvg,
  'glossy': MaterialGlossySvg,
  'fabric': MaterialFabricSvg
}
