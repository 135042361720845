import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TopIcon from '@yesplz/core-web/assets/svg/vf-button-top.svg'
import shoesIcon from '@yesplz/core-web/assets/svg/vf-button-shoes.svg'
import pantsIcon from '@yesplz/core-web/assets/svg/vf-button-pants.svg'
import skirtIcon from '@yesplz/core-web/assets/svg/vf-button-skirt.svg'
import outerwearIcon from '@yesplz/core-web/assets/svg/vf-button-outerwear.svg'
import wdressIcon from '@yesplz/core-web/assets/svg/vf-button-dress.svg'
import wbagIcon from '@yesplz/core-web/assets/svg/vf-button-women-bag.svg'
import earringIcon from '@yesplz/core-web/assets/svg/vf-button-earring.svg'
import mshoesIcon from '@yesplz/core-web/assets/svg/vf-button-men-shoes.svg'
import mbagsIcon from '@yesplz/core-web/assets/svg/vf-button-men-bag.svg'
import walletIcon from '@yesplz/core-web/assets/svg/vf-button-wallet.svg'
import sneakersIcon from '@yesplz/core-web/assets/svg/vf-button-sneakers.svg'
import mdressshoesIcon from '@yesplz/core-web/assets/svg/vf-button-men-dress-shoes.svg'
import wdressshoesIcon from '@yesplz/core-web/assets/svg/vf-button-women-dress-shoes.svg'

import TopOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-top.svg';
import shoesOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-shoes.svg';
import pantsOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-pants.svg';
import skirtOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-skirt.svg';
import outerwearOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-outerwear.svg';
import wdressOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-dress.svg';
import wbagOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-women-bag.svg';
import earringOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-earring.svg';
import mshoesOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-men-shoes.svg';
import mbagsOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-men-bag.svg';
import walletOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-wallet.svg';
import sneakersOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-sneakers.svg';
import mdressshoesOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-men-dress-shoes.svg';
import wdressshoesOutlinedIcon from '@yesplz/core-web/assets/svg/vs-button-outlined/vf-button-women-dress-shoes.svg';

import './FlatFloatButton.scss'

export default class FlatFloatButton extends PureComponent {
  static propTypes = {
    category: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    onVisible: PropTypes.func,
    isVisible: PropTypes.bool,
    scrollMode: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    color: PropTypes.oneOf(['black']),
    icon: PropTypes.string,
    top: PropTypes.bool,
    outlinedIcon: PropTypes.bool,
  }

  static defaultProps = {
    size: 'large',
    scrollMode: true,
    isVisible: false,
    top: false,
    color: null,
    outlinedIcon: false,
  };

  constructor (props) {
    super(props);

    this.state = {
      show: this.props.isVisible,
      animateOut: false,
    };

    if (this.props.scrollMode) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount () {
    if (this.props.scrollMode) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.scrollMode) {
      if (!prevState.show && this.state.show && this.props.onVisible) {
        this.props.onVisible();
      }
      else if (prevState.show && !this.state.show && this.props.onHide) {
        this.props.onHide();
      }
    }
    else {
      if (prevProps.isVisible !== this.props.isVisible) {
        if (this.props.isVisible) {
          this.setState({ show: this.props.isVisible });
        }
        else {
          this.setState({ animateOut: true });
        }
      }
    }
  }

  handleScroll = () => {
    const { show } = this.state
    if (show && window.scrollY < 15) {
      this.setState({ show: false })
    } else if (!show && window.scrollY >= 15) {
      this.setState({ show: true })
    }
  }

  handleAnimationEnd = () => {
    if (!this.props.scrollMode && this.state.animateOut) {
      this.setState({
        animateOut: false,
        show: false,
      });
    }
  }

  getIcon () {
    const { category } = this.props
    switch (category) {
      case 'wshoes':
        return shoesIcon
      case 'wpants':
        return pantsIcon
      case 'mpants':
        return pantsIcon
      case 'wskirt':
        return skirtIcon
      case 'wouter':
        return outerwearIcon
      case 'mouter':
        return outerwearIcon
      case 'wdress':
        return wdressIcon
      case 'wearring':
        return earringIcon
      case 'wbag':
        return wbagIcon
      case 'mshirts':
        return TopIcon
      case 'mshoes':
        return mshoesIcon
      case 'msneakers':
        return sneakersIcon
      case 'wsneakers':
        return sneakersIcon
      case 'mdressshoes':
        return mdressshoesIcon
      case 'wdressshoes':
        return wdressshoesIcon
      case 'mbag':
        return mbagsIcon
      case 'mwallet':
        return walletIcon
      case 'wwallet':
        return walletIcon
      default:
        return TopIcon
    }
  }

  getIconOutlined() {
    const { category } = this.props
    switch (category) {
      case 'wshoes':
        return shoesOutlinedIcon
      case 'wpants':
        return pantsOutlinedIcon
      case 'mpants':
        return pantsOutlinedIcon
      case 'wskirt':
        return skirtOutlinedIcon
      case 'wouter':
        return outerwearOutlinedIcon
      case 'mouter':
        return outerwearOutlinedIcon
      case 'wdress':
        return wdressOutlinedIcon
      case 'wearring':
        return earringOutlinedIcon
      case 'wbag':
        return wbagOutlinedIcon
      case 'mshirts':
        return TopOutlinedIcon
      case 'mshoes':
        return mshoesOutlinedIcon
      case 'msneakers':
        return sneakersOutlinedIcon
      case 'wsneakers':
        return sneakersOutlinedIcon
      case 'mdressshoes':
        return mdressshoesOutlinedIcon
      case 'wdressshoes':
        return wdressshoesOutlinedIcon
      case 'mbag':
        return mbagsOutlinedIcon
      case 'mwallet':
        return walletOutlinedIcon
      case 'wwallet':
        return walletOutlinedIcon
      default:
        return TopOutlinedIcon
    }
  }

  render () {
    const { onClick, className, color, style, size, icon, top, outlinedIcon } = this.props
    const { show, animateOut } = this.state

    return (
      <div className={classNames("FlatFloatButton-container", {
        'hidden': !show,
      })}>
        <button
          className={classNames('FlatFloatButton', {
            [className]: className,
            'hidden': !show,
            'animateOut': animateOut,
            'small': size === 'small',
            'medium': size === 'medium',
            'position-top': top,
            [color]: color,
          })}
          style={style}
          onClick={onClick}
          onAnimationEnd={this.handleAnimationEnd}>
          <img
            src={
              icon
                ? icon
                : outlinedIcon
                  ? this.getIconOutlined()
                  : this.getIcon()
            }
            alt='VisualFilterButton'
          />
          Search with the Style Filter
        </button>
      </div>
    )
  }
}
