import React from 'react'
import { Route, Switch, Redirect } from 'react-router'

// pages
import { Base, NotFound } from 'modules/base'
import { Categories } from 'modules/categories'
import { Favorites } from 'modules/favorites'
import { ProductsPage, ProductsCorpPage } from 'modules/products'

// presentationals
import { SingleProductRoute } from 'modules/products/singleProductRoutes'

const createRoutes = () => (
  <Switch>
    <Route
      path='/'
      component={BasePlatform} />
  </Switch>
)

// nested routes components
const BasePlatform = (props) => (
  <Base {...props}>
    <Switch>
      <Route exact path='/' render={() => <Redirect to="/women" />} />
      {/* <Route exact path='/' render={() => <div />} /> */}
      <Route exact path='/:gender(men|women)' component={Categories} />
      <Route exact path='/visualfilter/:category' component={ProductsPage} />
      <Route exact path='/product/:categoryId/:productId' render={SingleProductRoute} />
      <Route exact path='/favorites/:favoriteType' component={Favorites} />
      {/* <Route exact path='/new' component={Home} /> */}
      {/* <Route exact path='/products' component={Tops} /> */}
      {/* <Route exact path='/products/:category/all' component={AllProductsPage} /> */}
      {/* <Route exact path='/products/:category/list' component={ProductsPage} /> */}
      {/* <Route exact path='/new/:type' component={NewProductsPage} /> */}
      {/* <Route exact path='/products/:category/:productId' render={SinglePresetProductRoute} /> */}
      {/* <Route exact path='/products/:productId' component={SingleProductRoute} /> */}
      {/* <Route exact path='/preset-products/:category/:presetName' render={PresetProductsRoute} /> */}
      {/* <Route exact path='/preset-products/:presetName/:productId' render={SinglePresetProductRoute} /> */}
      {/* <Route exact path='/comingsoon' component={ComingSoon} /> */}

      <Route exact path='/demo/visual-filter/products/:categoryId/:device' component={ProductsCorpPage} />

      <Route component={NotFound} />
    </Switch>
  </Base>
)

export default createRoutes()
