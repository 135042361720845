import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';
import { categories } from 'config/categories';
import FilterGroup from '@yesplz/core-web/modules/filters/FilterGroup'
import FilterLabel from '@yesplz/core-web/modules/filters/FilterLabel'
import { findLabelByLng } from '@yesplz/core';

const FavoriteProductsFilter = ({ type, favorites, values, onSubmit }) => {
  const allOptions = Object.keys(favorites[type]).sort();
  const filterCategories = allOptions
    .filter(categoryId => categories[categoryId])
    .map(categoryId => ({
      name: categoryId,
      label: findLabelByLng(categories[categoryId].label),
    }));

  const handleChange = (_, values) => {
    onSubmit(without(values.sort(), 'all'));
  }

  useEffect(() => {
    const newValues = values.filter(v => allOptions.includes(v));
    if (!isEqual(values, newValues)) {
      onSubmit(newValues);
    }
  });

  return (
    <div>
      <FilterLabel label='Types'>
        <FilterGroup
          name='types'
          options={[
            {
              name: 'all',
              label: 'All Types'
            },
            ...filterCategories
          ]}
          values={
            isEqual(values, allOptions)
              ? ['all', ...values]
              : values
          }
          type='radio'
          onChange={handleChange}
        />
      </FilterLabel>
    </div>
  )
}

FavoriteProductsFilter.propTypes = {
  values: PropTypes.array,
  onSubmit: PropTypes.func,
}

FavoriteProductsFilter.defaultProps = {
  onSubmit: (productListConfig) => { console.debug('Unhandled `onSubmit` prop in `ProductsFilter`', productListConfig) },
}

const mapStateToProps = ({ favoritesStorage }) => ({
  favorites: favoritesStorage,
})

export default connect(mapStateToProps)(FavoriteProductsFilter)
