import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import isNil from 'lodash/isNil'
import { connect } from 'react-redux'
import numeral from 'numeral';
import { toggleLikeProduct } from '@yesplz/core-redux/ducks/favoritesStorage'
import { BASE_IMG_PATH } from '@yesplz/core-web/config/constants'
import { LikeButton } from '@yesplz/core-web/ui-kits/buttons'
import './product-grid.css'

class ProductGrid extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    // price: PropTypes.number.isRequired,
    // originalPrice: PropTypes.number,
    showOriginalPrice: PropTypes.bool,
    imgSrc: PropTypes.string.isRequired,
    currency: PropTypes.string,
    categoryId: PropTypes.string.isRequired,
    className: PropTypes.string,
    extraInfo: PropTypes.string,
    favorite: PropTypes.bool,
    productBasePath: PropTypes.string,
    rawData: PropTypes.object,
    disableLike: PropTypes.bool,
    style: PropTypes.object
  }

  static defaultProps = {
    currency: '$',
    active: false,
    favorite: false,
    showOriginalPrice: true,
    disableLike: false,
    className: '',
    category: '',
    productBasePath: '/products',
  }

  constructor (props) {
    super(props)
    this.state = {
      liked: false
    }
  }

  get toggleLike () {
    const { id, categoryId, toggleLikeProduct } = this.props
    return (e) => {
      e.preventDefault()
      toggleLikeProduct(categoryId, id)
    }
  }

  render () {
    const {
      id, name, brand, imgSrc, price, originalPrice, currency, className, category, categoryId,
      favorite, showOriginalPrice, disableLike, style, extraInfo } = this.props
    // sale is available if original price is different with price
    const isSale = !isNil(originalPrice) && originalPrice !== price
    const isOutOfStock = +price === 0
    const categoryClassName = category ? `is-${category}` : ''

    return (
      <Link
        to={`/product/${encodeURIComponent(categoryId)}/${id}`}
        className={`ProductGrid ${className}`}
        style={style}
        title={`${name} - ${brand}${extraInfo}`}>
        {!disableLike && <LikeButton active={favorite} onClick={this.toggleLike} />}
        <div className={`ProductGrid-thumbnail ${categoryClassName}`}>
          {
            !imgSrc && (
              <div className='ProductGrid-noImage' />
            )
          }
          {
            imgSrc && (
              <img src={imgSrc.startsWith('http') ? imgSrc : `${BASE_IMG_PATH}/${imgSrc}`} />
            )
          }
        </div>
        <div className='ProductGrid-detail'>
          <h5 dangerouslySetInnerHTML={{ __html: brand }} />
          {/* {isSale && showOriginalPrice && <div className='ProductGrid-originalPrice'>{currency}{numeral(originalPrice).format('0,0.00')}</div>}
          <div className={classNames('ProductGrid-price', { sale: isSale })}>
            {
              !isOutOfStock ? `${currency}${numeral(price).format('0,0.00')}` : 'Out of Stock'
            }
          </div> */}
        </div>
      </Link>
    )
  }
}

export default connect(
  ({ favoritesStorage }, { categoryId, id }) => ({
    favorite: favoritesStorage.products[categoryId] && favoritesStorage.products[categoryId].includes(id),
  }),
  { toggleLikeProduct },
)(ProductGrid);
