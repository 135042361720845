import React from 'react'
import { useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import history from '@yesplz/core-web/config/history'
import routes from './config/routes'
import ScrollToTop from './ScrollToTop'

// import global styles
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '@yesplz/core-web/assets/css/animations.css'
import '@yesplz/core-web/assets/css/reset.css'
import 'assets/css/overrides.css'
import 'app.css'

const App = () => {
  const isRehydrated = useSelector(({ _persist }) => (_persist.rehydrated));

  if (!isRehydrated) return null;

  return (
    <ConnectedRouter history={history}>
      <ScrollToTop>{routes}</ScrollToTop>
    </ConnectedRouter>
  )
}

export default App
