import React, { Component } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import isEqual from 'lodash/isEqual'
import classnames from 'classnames'
import { categories } from 'config/categories';

// core-web
import withTrackingProvider from '@yesplz/core-web/hoc/withTrackingProvider'
import Transition from '@yesplz/core-web/ui-kits/transitions/Transition'
import { withTrackingConsumer } from '@yesplz/core-web/hoc'
import { fetchFavorites } from '@yesplz/core-redux/ducks/favorites';
import ProductGrid from '@yesplz/core-web/modules/products/ProductGrid';
import { DotLoader } from '@yesplz/core-web/ui-kits/loaders'

// desktop
// import Presets from 'modules/presets/Presets'
import MenuNavigation from 'modules/menus/MenuNavigation'
import FavoriteProductsFilter from 'modules/favorites/FavoriteProductsFilter'
import EmptyContent from 'ui-kits/empties'
import './favorites.scss'
import CategorySection from 'modules/categories/CategorySection';

class Favorites extends Component {
  static propTypes = {
    favoriteType: PropTypes.string,
    products: PropTypes.array,
    presets: PropTypes.array,
    match: PropTypes.object,
    history: PropTypes.object,
    // setFilter: PropTypes.func.isRequired,
    // likePreset: PropTypes.func.isRequired,
    // enableInitialFetch: PropTypes.func.isRequired,
    // unlikePreset: PropTypes.func.isRequired,
    tracker: PropTypes.object,
    setting: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      defaultColType: true,
      filters: null,
      filterStyles: [],
      stylesFetching: false,
    }

    this.stylesFetchingTimeout = null;
  }

  componentDidMount() {
    if (this.props.shouldBeRefetched) {
      this.fetchFavorites();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.shouldBeRefetched
      &&
      this.props.shouldBeRefetched)
      ||
      prevProps.favoriteType !== this.props.favoriteType
    ) {
      this.fetchFavorites();
    }

    if (
      !isEqual(
        prevProps.favoritesStorage.styles,
        this.props.favoritesStorage.styles)
      ||
      !isEqual(
        prevState.filterStyles,
        this.state.filterStyles)

    ) {
      this.setState({
        stylesFetching: true,
      });
      setTimeout(() => {
        this.stylesFetchingTimeout = this.setState({
          stylesFetching: false,
        });
      }, 400);
    }
  }

  componentWillUnmount() {
    if (this.stylesFetchingTimeout) {
      clearTimeout(this.stylesFetchingTimeout);
    }
  }

  fetchFavorites() {
    this.setState({
      filters: [
        ...Object.keys(this.props.favoritesStorage.products).sort(),
      ],
      filterStyles: [
        ...Object.keys(this.props.favoritesStorage.styles).sort(),
      ],
    });
    this.props.fetchFavorites();
  }

  get menuNavigationOptions () {
    return [
      {
        title: 'STYLES',
        isActived: false,
        key: 'styles'
      },
      {
        title: 'ITEMS',
        isActived: true,
        key: 'items'
      },
    ].map(item => ({
      ...item,
      isActived: this.props.favoriteType === item.key
    }))
  }

  handleClickMenuNavigation = item => {
    const { key } = item
    this.props.history.push(`/favorites/${key}`)
  }

  handleSubmitFilter = (filters) => {
    this.setState({ filters })
  }

  handleSubmitFilterPreset = (filterStyles) => {
    this.setState({ filterStyles })
  }

  get stylesFiltered() {
    const { favoritesStorage } = this.props;
    const { filterStyles } = this.state;
    return concat(
      ...concat(
        ...Object.values(favoritesStorage.styles))
          .map(catStyles => concat(
            ...Object.values(catStyles)
          )
      )
    ).filter(filter => includes(filterStyles, filter.categoryId));
  }

  get productsFiltered () {
    const { products } = this.props;
    const { filters } = this.state;

    return products
      .filter(product => includes(filters, product.categoryId))
  }

  get togglePresetLike () {
    const { likePreset, unlikePreset, tracker } = this.props
    return (preset, favorite) => {
      if (favorite) {
        likePreset(preset, tracker)
      } else {
        unlikePreset(preset, tracker)
      }
    }
  }

  get handlePresetClick () {
    const { setFilter, enableInitialFetch, tracker } = this.props
    return (filters, presetName, category) => {
      setFilter(category, filters)
      // make products fetched from beginning
      enableInitialFetch()
      // redirect to preset's products page
      // history.push(`/preset-products/${category}/${formatPresetName(presetName)}`)
      this.props.history.push(`/products/${category}/list?favorite=true`)
      // track preset click
      tracker.track('Preset Choose', { name: presetName })
    }
  }

  getPresetFilter = preset => {
    const customFilter = { ...preset }
    // ToDo: customFilter['preset'] = formatPresetName(preset.name)
    delete customFilter.category
    delete customFilter.name

    return customFilter
  }

  onClickGroupTitle = (preset) => () => {
    const { setFilter } = this.props
    setFilter(preset.category, this.getPresetFilter(preset))
    // `/products/${preset.category}/list?listingView=single&page=editorspick&preset=${formatPresetName(preset.name)}`
    this.props.history.push(
      `/products/${preset.category}/list?listingView=single&page=all`
    )
  }

  render () {
    const { favoriteType } = this.props;
    const { filters, filterStyles, stylesFetching } = this.state;
    const showStyles = favoriteType === 'styles';
    console.log(this.productsFiltered);
    return (
      <div className='FavoriteContent'>
        <div className='left-menu'>
          <MenuNavigation
            menu={this.menuNavigationOptions}
            onClickMenuItem={this.handleClickMenuNavigation}
          />
          <div className='content-menu'>
            {
              favoriteType === 'items' && <FavoriteProductsFilter type="products" values={filters || []} onSubmit={this.handleSubmitFilter} />
            }
            {
              favoriteType === 'styles' && <FavoriteProductsFilter type="styles" values={filterStyles || []} onSubmit={this.handleSubmitFilterPreset} />
            }
          </div>
        </div>
        <div className='Favorites'>
          <div className='title'>
            <h3>FAVORITES</h3>
          </div>
          {
            showStyles ? (
              !stylesFetching && this.stylesFiltered.length > 0 ? (
                <Transition show transition='fadeInUp'>
                  {this.stylesFiltered.map((filter, index) => {
                    return (
                      <CategorySection
                        key={index}
                        currentFilter={filter}
                        config={categories[filter.categoryId]}
                        categoryId={filter.categoryId}
                        title={'the style'}
                        link={null}
                        showButton={true}
                      />
                    )
                  })}
                </Transition>
              ) : (
                stylesFetching
                  ? <DotLoader visible />
                  : <EmptyContent page='favorite-styles' />
              )
            ) : (
              this.productsFiltered.length > 0 ? (
                <div className={classnames('ProductsPage-ProductList ColTypeDouble')}>
                  <div className="ProductsPage-products">
                    <div className="container">
                      <div className="ProductList-wrapper">
                        {this.productsFiltered.map(product => (
                          <ProductGrid
                            key={product.productId}
                            {...{
                              categoryId: product.categoryId,
                              id: product.productId,
                              brand: product.brandName,
                              name: product.productName,
                              imgSrc: (
                                product.frontImg
                                  ? product.frontImg
                                  : product.variants.length && product.variants[0].images.length
                                    ? product.variants[0].images[0].srcUri
                                    : null
                              ),
                              price: product.salePrice,
                              originalPrice: product.originalPrice,
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <EmptyContent page='favorite-items' />
              )
            )
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  shouldBeRefetched: (state._persist.rehydrated && state.favorites.shouldBeRefetched),
  favoritesStorage: state.favoritesStorage,
  favoriteType: props.match.params.favoriteType,
  products: state.favorites.products,
})

export default compose(
  connect(
    mapStateToProps,
    {
      fetchFavorites,
    }
  ),
  withTrackingProvider('Favorites'),
  withTrackingConsumer()
)(Favorites)
