import VisualFilter from './VisualFilter';
import EditorsPicks from './EditorsPicks';
import StyleFilter from './StyleFilter';
import DesignFilter from './DesignFilter';
import ColorFilter from './ColorFilter';
import MaterialFilter from './MaterialFilter';
import BrandFilter from './BrandFilter';
import PriceFilter from './PriceFilter';
import PriceRangeFilter from './PriceRangeFilter';
import ProductsList from './ProductsList';
import TotalFound from './TotalFound';
import Pagination from './Pagination';
import ProductsPerPage from './ProductsPerPage';
import FullVisualFilter from './FullVisualFilter';
import SelectMenu from './SelectMenu';
import TreeViewMenu from './TreeViewMenu';
import CategoryTitle from './CategoryTitle';
import SortBy from './SortBy';
import ParamLabels from './ParamLabels';
import CategorySelect from './CategorySelect';
import PresetSelect from './PresetSelect';

import SortBySelect from './SortBySelect';
import PageSizeSelect from './PageSizeSelect';
import PageSize from './PageSize';

import CollapsibleFilters from './CollapsibleFilters';
import PriceFromToFilter from './PriceFromToFilter';
import SimpleCategoriesList from './SimpleCategoriesList';
import SimplePresetsList from './SimplePresetsList';
import SimpleOccasionsList from './SimpleOccasionsList';
import SimplePresetsOccasionsList from './SimplePresetsOccasionsList';
import SimpleMoodsList from './SimpleMoodsList';
import SizeFilter from './SizeFilter';
import DiscountsFilter from './DiscountsFilter';
import ShippingOptionsFilter from './ShippingOptionsFilter';
import Chips from './Chips';
import SimpleVibesList from './SimpleVibesList';
import SimpleSubtypesList from './SimpleSubtypesList';
import WashFilter from './WashFilter';
import SubcategoriesList from './SubcategoriesList';
import MaterialTextList from './MaterialTextList';

const widgets = {
  VisualFilter,
  EditorsPicks,
  StyleFilter,
  DesignFilter,
  ColorFilter,
  MaterialFilter,
  BrandFilter,
  PriceFilter,
  PriceRangeFilter,
  ProductsList,
  TotalFound,
  Pagination,
  ProductsPerPage,
  FullVisualFilter,
  SelectMenu,
  TreeViewMenu,
  CategoryTitle,
  SortBy,
  ParamLabels,
  CategorySelect,
  PresetSelect,

  SortBySelect,
  CollapsibleFilters,
  PriceFromToFilter,
  SimpleCategoriesList,
  SimplePresetsList,
  SimpleOccasionsList,
  SimplePresetsOccasionsList,
  SimpleMoodsList,
  SizeFilter,
  DiscountsFilter,
  ShippingOptionsFilter,
  Chips,
  SimpleVibesList,
  SimpleSubtypesList,
  WashFilter,
  SubcategoriesList,
  MaterialTextList,

  PageSizeSelect,
  PageSize,
}

export default widgets;
