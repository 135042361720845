import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import pick from 'lodash/pick';

import { categories, designFilterParams, materialFilterParams } from 'config/categories';
import { connect } from 'react-redux';
import { setCategoryLatestStyle } from '@yesplz/core-redux/ducks/latestStyles';
import { toggleLikeStyle } from '@yesplz/core-redux/ducks/favoritesStorage';
import objectHash from '@yesplz/core-web/utils/objectHash';

import visualfilter from '@yesplz/visualfilter';
import '@yesplz/visualfilter/src/styles/styles.scss';

import resetIconSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';

import './SidebarProductsCollapsibleFilter.scss';

class SidebarProductsCollapsibleFilter extends React.Component {
  constructor (props) {
    super(props)

    this.visualFilter = null;
    this.favoriteButton = null;

    this.state = {
      currentFilter: null,
    };
  }

  get categoryId() {
    return this.props.category;
  }

  get gender() {
    const { category } = this.props;
    return category[0] === 'w' ? 'Women' : 'Men';
  }

  get genderCode() {
    const { category } = this.props;
    return category[0];
  }

  componentDidMount() {
    this.renderVisualFilter();

    if (
      this.props.location.state
      &&
      this.props.location.state.filter
    ) {
      this.props.history.replace(this.props.location.pathname);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.visualFilter) return;

    if (prevProps.category !== this.props.category) {
      this.visualFilter.setCategory(this.props.category);
    }

    if (this.props.isOnboarding && this.props.onboardingStep === 2) {
      this.visualFilter.toggleTooltips();
    }
  }

  componentWillUnmount() {
    this.visualFilter.off('searchStarted', this.handleSearchStart);
    this.visualFilter.off('searchFinished', this.handleSearchFinished);
  }

  handleSearchStart = (filter) => {
    if (this.props.category !== filter.categoryId) {
      this.props.history.push(`/visualfilter/${encodeURIComponent(filter.categoryId)}`);
    }

    if (this.props.onSearchStarted) {
      this.props.onSearchStarted(filter);
    }
    this.setState({
      currentFilter: filter,
    });
    this.props.setCategoryLatestStyle(this.props.category, filter);
  }

  handleSearchFinished = (results) => {
    if (this.props.onSearchFinished)
      this.props.onSearchFinished(results)
  }

  setOffset(offset) {
    this.visualFilter.setOffset(offset);
  }

  handlePresetChange = ({ value: presetName }) => {
    this.visualFilter.setFilter({
      presetIndex: presetName,
      params: {
        ...pick(this.state.currentFilter.params, ['brands', 'price']),
        ...categories[this.props.category].presetList[presetName],
      },
    });
  }

  toggleTooltips = () => {
    this.visualFilter.toggleTooltips();
  }

  get cleanedFilter() {
    const cleanedFilter = { ...(this.state.currentFilter || {}) };
    delete cleanedFilter.offset;
    delete cleanedFilter.limit;
    if (cleanedFilter.params)
      delete cleanedFilter.params.editorspick;

    return cleanedFilter;
  }

  get filterHash() {
    return objectHash(this.cleanedFilter);
  }

  checkFavoriteButton = () => {
    const svgGroup = window.document.getElementById('favorite-svg-group');

    const isFavorite = (
      this.props.favoritesStorage.styles[this.props.category]
      &&
      this.props.favoritesStorage.styles[this.props.category][this.filterHash]
    );
    svgGroup.setAttribute('fill', isFavorite ? '#2F30EB' : 'none');
  }

  handleFavoriteClick = () => {
    this.props.toggleLikeStyle(this.props.category, this.filterHash, this.cleanedFilter);
    this.checkFavoriteButton();
  }

  renderVisualFilter() {
    const vs = visualfilter({
      categories,
      searchFinishedReturnFields: null,
      usePersistentFilter: true,
      initialFilter: {
        categoryId: this.props.category,
        ...(this.props.initialFilter || {}),
        limit: 72,
      },
    });

    vs.addWidget(
      vs.widgets.CollapsibleFilters({
        container: "#collapsible-filter",
        filters: ['categories', 'presets', 'vibes', /* 'brand', */ 'style', 'wash', 'patterns', 'colors', 'materials', /* 'price' */],
        visualFilterParams: {
          svgViewBox: [0, 0, 362, 254],
          svgViewBoxMobile: [0, 0, 362, 254],
          presetNavigation: false,
          showTooltipsToggler: false,
          showParamTags: true,
          tagHighlightColor: '#2F30EB',
          onSVGLoaded: () => {},
          onTouchClick: () => {},
        },
        patternsFilterParams: {
          ...designFilterParams,
          showReset: true,
        },
        materialsFilterParams: {
          showReset: true,
          ...materialFilterParams,
        },
        colorsFilterParams: {
          resetImage: resetIconSvg,
        },
        presetsParams: {
          showResetButton: true,
        },
        filtersOpenByDefault: ['style'],
        usePresetFilter: false,
        useFooter: false,
        isFoldable: false,
        useOnboarding: true,
        onApplyClick: () => {
          this.props.onApplyClick();
        },
      })
    );

    vs.addWidget(
      vs.widgets.Chips({
        container: '#filter-chips',
      })
    );

    vs.on('searchStarted', this.handleSearchStart);

    vs.on('searchFinished', this.handleSearchFinished);

    this.visualFilter = vs;

    setTimeout(() => {
      const header = window.document.querySelector('.CollapsibleFilters .CollapsibleFilters-header');

      if (!header) return;

      header.insertAdjacentHTML('afterend', `
          <div class="CollapsibleFilters-favorite-button">
            <button id="collapsible-like-button" class="FavoriteFullButton">
              <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="favorite-svg-group">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.84 3.61012C19.8085 2.57811 18.4091 1.99829 16.95 1.99829C15.4909 1.99829 14.0915 2.57811 13.06 3.61012L12 4.67012L10.94 3.61012C8.79161 1.46173 5.30838 1.46173 3.16 3.61012C1.01161 5.7585 1.01161 9.24173 3.16 11.3901L4.22 12.4501L12 20.2301L19.78 12.4501L20.84 11.3901C21.872 10.3586 22.4518 8.95925 22.4518 7.50012C22.4518 6.04098 21.872 4.64164 20.84 3.61012Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>
              <span>Save filtering</span>
            </button>
          </div>
        `);

      this.favoriteButton = window.document.getElementById('collapsible-like-button')
      this.favoriteButton.addEventListener('click', this.handleFavoriteClick);

      this.checkFavoriteButton();
    }, 200);
  }

  render () {
    const { onScrollY } = this.props;

    return (
      <div
        className='SidebarProductsCollapsibleFilter'
        style={{
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
        onScroll={onScrollY}
      >
        <div id="collapsible-filter" />
      </div>
    )
  }
}

SidebarProductsCollapsibleFilter.propTypes = {
  category: PropTypes.string.isRequired,
  lastBodyPart: PropTypes.string,
  filters: PropTypes.object,
  onFilterChange: PropTypes.func
}

export default connect(
  ({ favoritesStorage }) => ({ favoritesStorage }),
  {
    setCategoryLatestStyle,
    toggleLikeStyle,
  }
)(withRouter(SidebarProductsCollapsibleFilter));
