import Handlebars from 'handlebars';
import Widget from '../modules/Widget';

const { document } = window;

const TEMPLATE = `
  <select id="visual-filter-select-menu-parent">
    {{#each parentCategories}}
      <option value="{{this.parentId}}">{{this.label}}</option>
    {{/each}}
  </select>
  <select id="visual-filter-select-menu-category">
    {{#each categories}}
      <option value="{{this.categoryId}}">{{this.label}}</option>
    {{/each}}
  </select>
`

class SelectMenu extends Widget {
  defaultParams = {
    template: TEMPLATE,
    isParentActiveCategory: false,
  };

  constructor(params) {
    super(params);

    this.template = Handlebars.compile(this.params.template);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'select-menu-container';
    this.mainElement = element;

    this.categories = params.categories;

    this.lastCategoryId = null;

    this.parentSelectId = 'visual-filter-select-menu-parent';
    this.categorySelectId = 'visual-filter-select-menu-category';
  }

  findParentCategory() {
    const { categoryId } = this.state.filter;
    let foundParentCategory = null;

    for (const parentCategory of this.categories) {
      if (parentCategory.parentId === categoryId) {
        foundParentCategory = parentCategory;
      }
      for (const category of parentCategory.children) {
        if (category.categoryId === categoryId) {
          foundParentCategory = parentCategory;
          break;
        }
      }
      if (foundParentCategory) break;
    }

    return foundParentCategory;
  }

  changeParent = (parentId) => {
    for (const parentCategory of this.categories) {
      if (parentCategory.parentId === parentId) {
        this.changeCategory(
          this.params.isParentActiveCategory
            ? parentCategory.parentId
            : parentCategory.children[0].categoryId
        );
        break;
      }
    }
  }

  changeCategory = (categoryId) => {
    this.main.setCategory(categoryId);
  }

  renderCategories() {
    if (this.lastCategoryId === this.state.filter.categoryId) return;

    const parentCategory = this.findParentCategory();

    this.mainElement.innerHTML = this.template({
      parentCategories: this.categories,
      categories: parentCategory.children,
    });

    const parentSelect = this.mainElement
      .querySelector(`#${this.parentSelectId}`);
    const parentSelectedOption = parentSelect
      .querySelector(`option[value="${parentCategory.parentId}"]`);
    if (parentSelectedOption) {
      parentSelectedOption.selected = true;
    }
    parentSelect.addEventListener('change', (e) => this.changeParent(e.target.value));

    const categorySelect = this.mainElement
      .querySelector(`#${this.categorySelectId}`);
    const categorySelectedOption = categorySelect
      .querySelector(`option[value="${this.state.filter.categoryId}"]`);
    if (categorySelectedOption) {
      categorySelectedOption.selected = true;
    }
    categorySelect.addEventListener('change', (e) => this.changeCategory(e.target.value));

    this.lastCategoryId = this.state.filter.categoryId;
  }

  update() {
    this.renderCategories();
  }

  render() {
    return this.mainElement;
  }
}

export default (params) => {
  return new SelectMenu(params);
};
