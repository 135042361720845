import Handlebars from 'handlebars';
import pick from 'lodash/pick';
import Widget from '../modules/Widget';

const { document } = window;

const TEMPLATE = `
  <option value="">Style...</option>
  {{#each presets}}
    <option value="{{this.id}}">{{this.label}}</option>
  {{/each}}
`;

const persistentParams = ['brands', 'price'];

class PresetSelect extends Widget {
  defaultParams = {};

  constructor(params) {
    super(params);

    this.template = Handlebars.compile(TEMPLATE);

    const element = document.createElement('select');
    element.className = this.params.containerClassName || 'select-category';
    this.mainElement = element;

    this.presets = {};

    this.lastCategoryId = null;
    this.lastPreset = null;

    this.mainElement.addEventListener('change', (e) => console.log(e.target) || this.changePreset(e.target.value));
  }

  get categoryId() {
    return this.state.filter.categoryId;
  }

  changePreset = (presetName) => {
    this.setFilter({
      presetIndex: presetName,
      params: {
        ...pick(this.state.filter.params, persistentParams),
        ...this.presets[presetName],
      },
    });
  }

  didMount() {
    this.updateSelected();
  }

  didUpdate() {
    this.updateSelected();
  }

  updateSelected() {
    if (this.lastPreset === this.state.filter.presetIndex) return;

    const selectedOption = this.mainElement
      .querySelector(`option[value="${this.state.filter.presetIndex || ''}"]`);
    if (selectedOption) {
      selectedOption.selected = true;
    }

    this.lastPreset = this.state.filter.presetIndex;
  }

  renderPresets() {
    if (this.lastCategoryId === this.state.filter.categoryId) return;

    const presets = this.main.categories[this.categoryId].presetsList;
    this.presets = presets;
    this.mainElement.innerHTML = this.template({
      presets: Object.keys(presets).map((presetId) => ({
        id: presetId,
        label: presetId,
      })),
    });

    this.lastCategoryId = this.state.filter.categoryId;
  }

  update() {
    this.renderPresets();
  }

  render() {
    this.renderPresets();
    return this.mainElement;
  }
}

export default (params) => {
  return new PresetSelect(params);
};
