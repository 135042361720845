import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom';
import logoImg from 'assets/images/yesplz-logo.svg';
import classnames from 'classnames';
import { remoteConfigStatus } from 'config/categories';

// core-web
import FavoritesSvg from '@yesplz/core-web/assets/svg/favorites.svg'
import FavoritesActiveSvg from '@yesplz/core-web/assets/svg/favorite_purple.svg'

// desktop
import { GlobalMenu } from 'modules/globalmenu'
import './base.scss'
import MainMenu from './MainMenu';

class Base extends Component {
  static propTypes = {
    children: PropTypes.element,
    location: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      gender: 'women',
      isOpenMenu: false,
      isConfigLoaded: false,
    }
  }

  get isProductDetailPage () {
    const { location } = this.props
    return /^\/products\//.test(location.pathname)
  }

  get isFavoritesPage () {
    const { location } = this.props
    return /^\/favorites\//.test(location.pathname)
  }

  get handleHomeLinkActive () {
    return match => match // || this.isProductDetailPage
  }

  get handleFavoritesLinkActive () {
    return () => {
      const { location } = this.props
      return /^\/favorites\/(fits|clothing)$/.test(location.pathname)
    }
  }

  get handleLinkClick () {
    return () => {
      const scrollWrapper = document.getElementById('MainScroll')
      if (scrollWrapper) {
        scrollWrapper.scrollTop = 0
      }
    }
  }

  get isDemo() {
    const { location } = this.props
    return location.pathname.includes('/demo');
  }

  updateGender() {
    const { location } = this.props;
    const { gender } = this.state;

    if (location.pathname.includes('/demo')) return;

    if (location.pathname.includes('/m') && gender !== 'men') {
      this.setState({ gender: 'men' });
    }
    else if (location.pathname.includes('/w') && gender !== 'women') {
      this.setState({ gender: 'women' });
    }
  }

  onToggleGlobalMenu = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu })
  }

  closeGlobalMenu = () => {
      this.setState({ isOpenMenu: false })
  }

  isHomePage = () => {
    const { location } = this.props
    return location.pathname === '/'
  }
  
  waitForConfigAndRender() {
    this.waitTimeout = null;
    const checkIfConfigLoaded = () => {
      if (remoteConfigStatus === 'loaded' || remoteConfigStatus === 'error') {
        clearTimeout(this.waitTimeout);
        this.setState({ isConfigLoaded: true });
      }
      else
        setTimeout(checkIfConfigLoaded, 20);
    }
    checkIfConfigLoaded();
  }

  componentDidMount() {
    this.waitForConfigAndRender();
    this.updateGender();
  }

  componentDidUpdate() {
    this.updateGender();
  }

  render () {
    const { children } = this.props
    const { isOpenMenu } = this.state

    if (!this.state.isConfigLoaded) return null;

    if (this.isDemo) {
      return children;
    }

    return (
      <>
        <GlobalMenu
          isOpenMenu={isOpenMenu}
          isHomePage={this.isHomePage()}
          closeGlobalMenu={this.closeGlobalMenu}
          onToggleGlobalMenu={this.onToggleGlobalMenu}
        />
        <div id='Base-desktop' className='Base'>
          <div className='Base-header'>
            <div className='Base-headerContainer'>
              <div className='Base-leftNav'>
                <MainMenu onLinkClick={this.handleLinkClick} />
              </div>
              <NavLink
                exact
                to={`/${this.state.gender}`}
                onClick={this.handleLinkClick}
                isActive={this.handleHomeLinkActive}
                className='logo'>
                <img src={logoImg} alt='YESPLZ' />
              </NavLink>
              <div className='Base-rightNav'>
                <NavLink
                  to={this.isFavoritesPage ? '#' : '/favorites/styles'}
                  onClick={this.handleLinkClick}
                  isActive={this.handleFavoritesLinkActive}
                  className={classnames('menu-icon', {
                    isActive: this.isFavoritesPage,
                  })}>
                  <img
                    src={this.isFavoritesPage ? FavoritesActiveSvg : FavoritesSvg}
                    alt='Your Collection' /> Your Collection
                </NavLink>
              </div>
            </div>
          </div>
          <div className={`base-content ${isOpenMenu ? 'show' : ''} ${this.isHomePage() ? 'home' : 'not-home'}`}>{children}</div>
        </div>
      </>
    )
  }
}

export default Base
