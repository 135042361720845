import { applyMiddleware, createStore, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router'
// import MixpanelMiddleware from 'redux-mixpanel-middleware'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import history from '@yesplz/core-web/config/history'
import * as reducers from '@yesplz/core-redux/ducks'
import persistMigrations from '@yesplz/core-redux/persistMigrations'
import { makeRecommendationMiddleware } from '@yesplz/core-redux/middlewares/recommendation'

// const mixpanelMiddleware = new MixpanelMiddleware(window.mixpanel)

const rootReducer = combineReducers({
  router: connectRouter(history),
  ...reducers
})

const persistConfig = {
  key: 'shop.yesplz',
  version: 1,
  storage,
  whitelist: ['favoritesStorage', 'latestStyles'],
  migrate: createMigrate(persistMigrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      thunk, // add dispatch to action creators
      makeRecommendationMiddleware(90),
      // mixpanelMiddleware
    )
  )
)

export const persistor = persistStore(store)

export default store
