import categoriesConfig from './defaultCategoryConfigs';

function getCategoryDefaultBodyPart(category) {
  return categoriesConfig[PRODUCT_DEFAULT_CATEGORY]['partList'][0];
}

export {
  getCategoryDefaultBodyPart,
  categoriesConfig,
};

export const PRODUCT_DEFAULT_CATEGORY = Object.keys(categoriesConfig)[0];
export const PRODUCT_DEFAULT_BODYPART = categoriesConfig[PRODUCT_DEFAULT_CATEGORY]['partList'][0];
