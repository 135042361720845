import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classnames from 'classnames';
import { DotLoader } from '@yesplz/core-web/ui-kits/loaders'
import { PRODUCTS_MAX_PRODUCTS } from 'config/consts';
import withTrackingProvider from '@yesplz/core-web/hoc/withTrackingProvider';
import { Button } from '@yesplz/core-web/ui-kits/buttons'
import useOnboarding from '@yesplz/core-web/hooks/useOnboarding'
// import SidebarProductsFilter from './SidebarProductsFilter';
import SidebarProductsCollapsibleFilter from './SidebarProductsCollapsibleFilter';
import List from './List';
// import Onboarding from './Onboarding';
import './ProductsPage.scss';

let changeTimeout = null;

function ProductsPage({ match, location }) {
  const [isFetching, setIsFetching] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [changeCount, setChangeCount] = useState(0);
  const [isAppOnboarding] = useOnboarding();
  // isOnboarding here to manage local onboarding process.
  // for label to wait for one more change before removal.
  const [isOnboarding, setIsOnboarding] = useState(isAppOnboarding);
  const scrollBoxRef = useRef(null);
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (isOnboarding) {
      changeTimeout = setTimeout(() => {
        setChangeCount(4);
      }, 20000);
    }

    return () => {
      if (changeTimeout)
        clearTimeout(changeTimeout);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (changeCount >= 4) {
      setIsOnboarding(false);
    }
  }, [changeCount, setIsOnboarding]);

  const handleSearchFinished = (newSearchResult) => {
    if (newSearchResult.offset > 0) {
      setSearchResult({
        ...newSearchResult,
        results: [
          ...((searchResult && searchResult.results) || []),
          ...newSearchResult.results,
        ],
      });
    }
    else {
      setSearchResult(newSearchResult);
      if (scrollBoxRef.current) {
        scrollBoxRef.current.scrollTo(0, 0);
      }
    }
    setIsFetching(false);
  }

  const categoryId = decodeURIComponent(match.params.category);

  return (
    <div id='MainScroll' className={classnames('ProductsPage', {
      'ProductsPage-onboarding-active': changeCount > 1 && changeCount < 4,
    })}>
      <div className='ProductFilters'>
        {/* <SidebarProductsFilter
          category={categoryId}
          initialFilter={location.state && location.state.filter}
          onSearchStarted={() => {
            if (changeTimeout) {
              clearTimeout(changeTimeout);
            }
            setChangeCount(changeCount + 1);
            setIsFetching(true);
          }}
          onSearchFinished={handleSearchFinished}
          onScrollY={() => {
            if (isOnboarding) {
              setIsOnboarding(false);
            }
          }}
          isOnboarding={isAppOnboarding}
          onboardingStep={changeCount}
          wrappedComponentRef={sidebarRef}
        /> */}

        <SidebarProductsCollapsibleFilter
          category={categoryId}
          initialFilter={location.state && location.state.filter}
          onSearchStarted={() => {
            if (changeTimeout) {
              clearTimeout(changeTimeout);
            }
            setChangeCount(changeCount + 1);
            setIsFetching(true);
          }}
          onSearchFinished={handleSearchFinished}
          isOnboarding={false}
          onboardingStep={changeCount}
          wrappedComponentRef={sidebarRef}
        />
      </div>
      <div className='Desktop-section' ref={scrollBoxRef} onScroll={(e) => {
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
          const maxCount = searchResult.counts.total < PRODUCTS_MAX_PRODUCTS
            ? searchResult.counts.total
            : PRODUCTS_MAX_PRODUCTS;
          if (!isFetching && searchResult && searchResult.results.length < maxCount) {
            sidebarRef.current.setOffset(searchResult.offset + searchResult.limit);
          }
        }
      }}>
        <div id="filter-chips"></div>
        <List
          categoryId={categoryId}
          products={searchResult && searchResult.results}
          counts={searchResult && searchResult.counts}
        />
        {isFetching && <DotLoader visible />}

        {searchResult && <Button kind='secondary' className="Button-backToTop" onClick={() => {
          scrollBoxRef.current.scrollTo(0, 0);
        }}>
          Back to top
        </Button>}
      </div>
      {/* {
        isOnboarding && changeCount < 4
        &&
        <Onboarding categoryId={categoryId} setChangeCount={setChangeCount} changeCount={changeCount} />
      } */}
    </div>
  )
}

ProductsPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
}

export default compose(
  withTrackingProvider('ProductsPage')
)(ProductsPage)
