import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import client from '@yesplz/client';
import widgets from './widgets';
import EventEmitter from './modules/EventEmitter';
import Hooks from './modules/Hooks';
import { categoriesConfig } from '@yesplz/core-models/src/CategoryConfigs';
import isPlainObject from 'lodash/isPlainObject';
import intersection from 'lodash/intersection';
import { parseUrlState, startHistoryListener, removeEmptyValuesFromObject, setPrevFilter } from './modules/history';
import { store, configureStore } from './store';
import { setCategories } from './store/categories';
import { setFilterAndSearch, hydrateFromUrl } from './store/actions/filterActions';
import { runSearch } from './store/actions/searchActions';
import { setConfigValue, setSearchFinishedReturnFields, setUseUrlHistory, toggleTooltips } from './store/actions/configActions';

/**
 * Mediator and Core Module for VisualFilter
 */
export default class VisualFilter {
  $hooks = Hooks;
  widgetsMountQueue = [];
  widgetsContainer = [];
  initialized = false;

  constructor(params) {
    const {
      lng = 'en',
      categories = categoriesConfig,
      noFilterCategories,
      clientBaseURL,
      clientBaseURLToken,
      clientSearchPath,
      clientAliases,
      usePersistentFilter = true,
      useUrlHistory = false,
      useSearch = true,
      requestConfig,
      searchAdditionalParams,
      searchFinishedReturnFields,
      initialFilter,
      isAdmin,
    } = params;

    this.initialFilter = initialFilter ?? null;

    if (isAdmin) {
      client.setIsAdmin(isAdmin);
    }

    if (clientBaseURL) {
      client.setBaseURL(clientBaseURL);
    }
    if (clientBaseURLToken) {
      client.setToken(clientBaseURLToken);
    }
    if (clientSearchPath) {
      client.setSearchPath(clientSearchPath);
    }
    if (clientAliases) {
      client.setAliases(clientAliases);
    }

    configureStore(usePersistentFilter);
    this.usePersistentFilter = usePersistentFilter;

    if (searchFinishedReturnFields !== undefined) {
      store.dispatch(
        setSearchFinishedReturnFields(searchFinishedReturnFields)
      );
    }

    if (useUrlHistory) {
      startHistoryListener(this.handleHistoryChanges);
      store.dispatch(setUseUrlHistory(true));
    }

    store.dispatch(setConfigValue({
      key: 'useSearch',
      value: useSearch,
    }));

    this.setLng(lng);

    if (searchAdditionalParams && typeof searchAdditionalParams === 'object') {
      store.dispatch(setConfigValue({
        key: 'searchAdditionalParams',
        value: searchAdditionalParams,
      }));
    }

    if (requestConfig && typeof requestConfig === 'object') {
      store.dispatch(setConfigValue({
        key: 'requestConfig',
        value: requestConfig,
      }));
    }

    this.noFilterCategories = noFilterCategories || [];
    this.categories = Object.entries(categories).reduce((categories, [categoryId, category]) => {
      categories[categoryId] = {
        ...category,
        parts: (
          category.parts
            ? category.parts
            : category.partList
              ? category
                .partList.map((name, i) => {
                  const partListLabels = category.partListLabels || category.partListLabels;
                  return { label: partListLabels[i], name };
                })
              : []
        ),
        presetsList: (
          isPlainObject(category.presetsList)
            ? Object.entries(category.presetsList).map(([label, preset]) => ({ label, value: label, preset }))
            : category.presetsList
        ),
        tn: category.tn ? Object.entries(category.tn).reduce((tn, [part, partValues]) => {
          if (!partValues.all) {
            tn[part].all = { "label": "Any" };
          }
          return tn;
        }, category.tn) : category.tn,
      };
      return categories;
    }, {});
    // Setting categories to be accessible for the store components.
    // TODO: On improving categories config management, remove.
    setCategories(this.categories);

    this.widgets = widgets;

    window.requestAnimationFrame(() => {
      this.state = store.getState();

      if (
        usePersistentFilter
        &&
        !(this.state._persist && this.state._persist.rehydrated)
        &&
        !(this.state.filter._persist && this.state.filter._persist.rehydrated)
      ) {
        const unsubscribe = store.subscribe(() => {
          const state = store.getState();
          if (
            state._persist.rehydrated
            &&
            state.filter._persist.rehydrated
          ) {
            unsubscribe();
            this.handleAfterStoreRehydrated(state);
          }
        });
      }
      else {
        this.validateStateAndInitialize();
      }
    });

    this.isLoadingMore = false;
  }

  handleAfterStoreRehydrated(newState) {
    this.state = newState;
    this.validateStateAndInitialize();
  }

  validateStateAndInitialize() {
    store.subscribe(() => this.state = store.getState());

    if (
      !this.state.filter.categoryId
      ||
      !this.isFilterValid(this.state.filter)
      ||
      this.initialFilter
    ) {
      const categoryId = this.initialFilter && this.initialFilter.categoryId
        ? this.initialFilter.categoryId
        : this.state.filter.categoryId
          &&
          (this.categories[this.state.filter.categoryId] || this.noFilterCategories.includes(this.state.filter.categoryId))
          ? this.state.filter.categoryId
          : Object.keys(this.categories)[0];

      const savedFilter = this.usePersistentFilter ? this.state.savedFilters[categoryId] : null;
      let newFilter = {
        ...(this.state.filter.sort ? { sort: this.state.filter.sort } : {}),
        ...(
          savedFilter && this.isFilterValid(savedFilter)
            ? savedFilter
            : this.getCategoryDefaults(categoryId)
        ),
      };
      if (this.initialFilter) {
        newFilter = {
          ...newFilter,
          ...this.initialFilter,
          params: {
            ...(
              this.initialFilter.params
                ? {
                  ...newFilter.params,
                  ...this.initialFilter.params,
                }
                : newFilter.params
            )
          },
        };
      }
      if (this.state.config.useUrlHistory) {
        const urlFilter = parseUrlState(this.categories);
        newFilter = {
          ...newFilter,
          ...urlFilter,
          params: {
            ...(
              urlFilter.params
                ? {
                  ...newFilter.params,
                  ...urlFilter.params,
                }
                : newFilter.params
            )
          },
        };
      }
      newFilter = Hooks.call('init.filterInitialization', newFilter);

      if (this.state.config.useUrlHistory) {
        setPrevFilter(removeEmptyValuesFromObject(newFilter));
      }

      this.setFilterAndSearch(newFilter, true);
    }
    else {
      store.dispatch(runSearch(this.categories));
    }

    this.mountWidgets();

    this.initialized = true;
  }

  isFilterValid(filter) {
    if (!filter || (filter && !filter.categoryId)) return false;

    const category = this.categories[filter.categoryId];

    if (!category) return false;

    if (filter.category !== category.category) return false;

    const availableParts = category.parts.map(p => p.name);
    const pickedParts = pick(filter.params, availableParts);
    if (availableParts && availableParts.length !== Object.keys(pickedParts).length) return false;

    const occasionValues = (category.occasionsList || []).map(({ value }) => value);
    if (filter.occasion && intersection(occasionValues, filter.occasion).length !== filter.occasion.length) return false;

    const presetValues = (category.presetsList || []).map(({ value }) => value);
    if (filter.presetIndex && !presetValues.includes(filter.presetIndex)) return false;

    let isPartValuesValid = Object.entries(pickedParts).reduce((isValid, [partName, partValue]) => {
      if (isValid && category.tn[partName] &&  !Object.keys(category.tn[partName]).includes(partValue)) {
        return false;
      }
      return isValid;
    }, true);

    if (isPartValuesValid) {
      isPartValuesValid = category.parts.reduce((isValid, { name, isPublished, disabledValue = 'all' }) => {
        if (!isPublished && filter.params[name] !== disabledValue) return false;

        return isValid
      }, true);
    }

    return isPartValuesValid;
  }

  handleHistoryChanges = () => {
    window.requestAnimationFrame(() => {
      store.dispatch(hydrateFromUrl(true, this.categories));
    });
  }

  setLng = (lng) => {
    store.dispatch(setConfigValue({
      key: 'lng',
      value: lng,
    }));
  }

  setSort = (sort) => {
    this.setFilterAndSearch({ sort });
  }

  setLimit = (limit) => {
    this.setFilterAndSearch({ limit });
  }

  setOffset = (offset) => {
    this.setFilterAndSearch({ offset });
  }

  setFilter = (filter) => {
    this.setFilterAndSearch(filter);
  }

  setFilterAndSearch = (filter, forceSearch) => {
    return store.dispatch(setFilterAndSearch(filter, this.categories, forceSearch));
  }

  getFilter = () => {
    const { filter } = store.getState();
    return cloneDeep(filter);
  }

  setUseSearch = (useSearch) => {
    store.dispatch(setConfigValue({
      key: 'useSearch',
      value: useSearch,
    }));
  }

  toggleTooltips = () => {
    store.dispatch(toggleTooltips());
  }

  getCategoryDefaults(categoryId) {
    if (this.noFilterCategories.includes(categoryId)) return {};

    const categories = this.categories;

    if (!categories[categoryId]) {
      console.log("Unsupported CategoryId", categoryId, "Supported Categories are", Object.keys(categories));
      return {};
    }

    const bodyPart = (
      categories[categoryId].partList && categories[categoryId].partList.length
        ? categories[categoryId].partList[0]
        : null
    );
    const params = {
      ...(
        categories[categoryId].defaultVal
          ? categories[categoryId].defaultVal
          : {}
      ),
      color: [],
    };

    // Evaluate Disabled parts
    categories[categoryId].parts.forEach(({ name, isPublished, disabledValue }) => {
      if (isPublished) return;

      params[name] = disabledValue;
    });

    return {
      categoryId,
      category: categories[categoryId].category,
      bodyPart,
      params,
      presetIndex: null,
      occasion: null,
    };
  }

  setCategory = (categoryId) => {
    const savedFilter = this.usePersistentFilter ? this.state.savedFilters[categoryId] : null;
    const filter = savedFilter && this.isFilterValid(savedFilter)
      ? this.state.savedFilters[categoryId]
      : this.getCategoryDefaults(categoryId);

    EventEmitter.emit('categoryChanged', categoryId);
    return this.setFilterAndSearch({
      ...filter,
      offset: 0,
    });
  }

  currentCategoryHasPresets() {
    return (
      this.categories[this.state.filter.categoryId].presetsList
      &&
      this.categories[this.state.filter.categoryId].presetsList.length
    );
  }

  setPrevPreset = () => {
    this.changePreset(false);
  }

  setNextPreset = () => {
    this.changePreset();
  }

  changePreset(isNext = true) {
    const categoryConfig = this.categories[this.state.filter.categoryId];

    if (isEmpty(categoryConfig.presetsList)) {
      return null;
    }

    const presetsKeys = Object.keys(categoryConfig.presetsList);

    let presetKeyIndex = 0;
    if (this.state.filter.presetIndex !== null) {
      const currentPresetKeyIndex = presetsKeys.indexOf(
        this.state.filter.presetIndex
      );
      if (isNext) {
        presetKeyIndex = currentPresetKeyIndex === presetsKeys.length - 1
          ? 0
          : currentPresetKeyIndex + 1;
      }
      else {
        presetKeyIndex = currentPresetKeyIndex === 0
          ? presetsKeys.length - 1
          : currentPresetKeyIndex - 1;
      }
    }
    this.setFilterAndSearch({
      params: { ...categoryConfig.presetsList[presetsKeys[presetKeyIndex]] },
      presetIndex: presetsKeys[presetKeyIndex],
    });

    EventEmitter.emit('presetChange', presetsKeys[presetKeyIndex]);
  }

  updateCategories(categories) {
    this.categories = categories;
    // Setting categories to be accessible for the store components.
    // TODO: On improving categories config management, remove.
    setCategories(categories);
  }

  addWidget(widget) {
    if (!this.initialized) {
      this.widgetsMountQueue.push(widget);
    }
    else {
      this.mountWidget(widget);
    }
  }

  mountWidgets() {
    this.widgetsMountQueue.map(this.mountWidget);
  }

  mountWidget = widget => {
    this.widgetsContainer.push(widget);
    widget.main = this;
    widget.mount();
  }

  loadMore = () => {
    store.dispatch(setConfigValue({
      key: 'infiniteScroll',
      value: true,
    }));
    const { filter: { limit, offset } } = store.getState();
    this.setOffset(offset + limit);
  }

  on(event, subscriber) {
    EventEmitter.on(event, subscriber);
  }

  off(event, subscriber) {
    EventEmitter.off(event, subscriber);
  }
}
