import React from 'react'
import PropTypes from 'prop-types'
// tab content
export const Tab = ({ tabKey, children, style }) => (
  <div key={tabKey} className='Tab' style={style}>
    {children}
  </div>
)

Tab.propTypes = {
  tabKey: PropTypes.string.isRequired,
  children: PropTypes.any,
  style: PropTypes.object
}

export default Tab
