import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

const designFilterKeys = ['solid', 'pattern', 'details', 'lace-up', 'ripped-off']
const nonVisualFilterKeys = [...designFilterKeys, 'color', 'material']

const AdvancedFilterHandler = ({ filters, onChange, render }) => {
  // split filters based on its type
  const style = omit(filters, nonVisualFilterKeys)
  const design = pick(filters, designFilterKeys)
  const color = (filters.color || '').split(',')
  const material = (filters.material || '').split(',')

  const handleFilterChange = (name, value) => {
    let updatedFilters = {
      ...filters
    }

    switch (name) {
      case 'material':
      case 'color':
        const valueJoin = value.join(',')
        updatedFilters = {
          ...updatedFilters,
          [name]: valueJoin.slice(0, 1) === ',' ? valueJoin.slice(1) : valueJoin
        }
        break
      case 'favorite':
        updatedFilters = value
        break
      default:
        updatedFilters = {
          ...updatedFilters,
          ...value
        }
    }

    onChange(updatedFilters)
  }

  return render({ style, design, color, material }, handleFilterChange)
}

AdvancedFilterHandler.propTypes = {
  filters: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export default AdvancedFilterHandler
