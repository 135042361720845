import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import omit from 'lodash/omit'

import VisualFilterBagde from '../../ui-kits/visual-filters/VisualFilterBagde'
import { ThumbnailPicker, ThumbnailPickerOption } from '../../ui-kits/selects'

const omittedKeys = ['category', 'id', 'name']

const SavedStylesSelect = ({ name, value, category, presets, onChange }) => {
  // chosen filter key
  const [ filterKey ] = useState()

  useEffect(() => {
    // define initial state when initial render, when it exist
  }, [JSON.stringify(value)])

  const handleChange = (_, itemValue) => {
    // update filterKey
  }

  return (
    <ThumbnailPicker name={name} value={filterKey} onChange={handleChange}>
      {
        presets.map((preset, index) => (
          <ThumbnailPickerOption
            key={preset.id}
            label={preset.name}
            value={preset.id}>
            <VisualFilterBagde
              id={`vf-${preset.id}`}
              category={category}
              filter={omit(preset, omittedKeys)}
            />
          </ThumbnailPickerOption>
        ))
      }
    </ThumbnailPicker>
  )
}

SavedStylesSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  category: PropTypes.string.isRequired,
  presets: PropTypes.array.isRequired,
  onChange: PropTypes.func
}

SavedStylesSelect.defaultProps = {
  onChange: (name, value) => {}
}

export default SavedStylesSelect
