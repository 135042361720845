import Handlebars from 'handlebars';
import Widget from '../modules/Widget';
// import { findLabelByLng } from '@yesplz/core';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import { formatCurrency } from '@yesplz/core';

const { document } = window;

const TEMPLATE = `
<div>
  <ul>
    {{#each items}}
      <li data-type="{{this.type}}" data-value="{{this.value}}" class="is-active">
        {{this.label}}
      </li>
    {{/each}}
  </ul>
</div>
`

const shippingOptions = [
  { label: 'Overseas - Standard', value: 'OVERSEAS_STANDARD' },
  { label: 'Priority', value: 'PRIORITY' },
  { label: 'Overseas - Pre-order', value: 'OVERSEAS_PRE_ORDER' },
  { label: 'Overseas - Made to order', value: 'OVERSEAS_MADETOORDER' },
];

const discounts = [
  { label: 'up to 20% off', value: '-20' },
  { label: '20%-30% off', value: '20-30' },
  { label: '30%-50% off', value: '30-50' },
  { label: '50%-75%+ off', value: '50-' },
];

class Chips extends Widget {
  defaultParams = {
    containerClassName: 'simple-presets-occasions-tags',
  };

  constructor(params) {
    super(params);

    this.template = Handlebars.compile(TEMPLATE);

    const element = document.createElement('div');
    element.className = this.params.containerClassName;
    this.mainElement = element;

    this.items = [];
  }

  assignEventHandlers() {
    const childElements = this.mainElement.querySelectorAll('li');
    childElements.forEach(childElement => {
      childElement.addEventListener('click', () => {
        const type = childElement.getAttribute('data-type');
        const value = childElement.getAttribute(`data-value`);

        this.handleValueRemoval(type, value);
      });
    });
  }

  didMount() {
    this.renderItems()
  }

  didUpdate() {
    this.renderItems();
  }

  handleValueRemoval(type, value) {
    if (Array.isArray(this.state.filter[type])) {
      const values = this.state.filter[type];
      this.main.updateSearchQuery({ [type]: values.filter(v => v !== value) });
    }
    else {
      this.main.updateSearchQuery({ [type]: null });
    }
  }

  buildItems() {
    const params = this.state.filter;
    this.items = [];

    const push = (type, value, label) => {
      this.items.push({ type, value, label });
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'gender' && value) {
        if (value) push(key, value, capitalize(value));
      }
      if (key === 'categories' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, capitalize(value));
        });
      }
      if (key === 'sale' && value) {
        if (value) push(key, value, 'Sale');
      }
      if (key === 'new' && value) {
        if (value) push(key, value, 'New');
      }
      if (key === 'bestsellers' && value) {
        if (value) push(key, value, 'Top Sellers');
      }
      if (key === 'colors' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, capitalize(value));
        });
      }
      if (key === 'materials' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, capitalize(value));
        });
      }
      if (key === 'sizes' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, `Size ${value}`);
        });
      }
      if (key === 'shipping' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const option = find(shippingOptions, { value });
          push(key, value, option.label);
        });
      }
      if (key === 'discount' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          const discount = find(discounts, { value });
          push(key, value, discount.label);
        });
      }
      if (key === 'price' && value && value !== '0-') {
        let prices = value.split('-');
        prices = prices.filter(p => p).map(p => formatCurrency(p, {}));
        let label = prices.join(" — ");
        if (prices.length === 1) {
          label = label + ' or more';
        }
        push(key, value, label);
      }
      if (key === 'brands' && value) {
        [...(Array.isArray(value) ? value : [value])].forEach(value => {
          push(key, value, value);
        });
      }
    });
  }

  renderItems() {
    this.buildItems();

    this.mainElement.innerHTML = this.template({
      items: this.items,
    });

    if (this.items.length)
      this.mainElement.classList.remove('empty');
    else
      this.mainElement.classList.add('empty');

    this.assignEventHandlers();

    if (typeof this.params.onRedered === 'function') {
      this.params.onRedered(this.items);
    }
  }

  render() {
    this.renderItems();
    return this.mainElement;
  }
}

export default (params) => {
  return new Chips(params);
};
