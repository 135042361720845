// import { extendedCategories, patternTypes } from '@yesplz/visualfilter/src/extended-configuration';
import { extendedCategories, patternTypes } from './extended-configuration.js';
import flatten from 'lodash/flatten';
import find from 'lodash/find';

const categoriesOrder = Object.keys(extendedCategories);

function sortArrayBy(base, preSorted, key) {
  const index = base.reduce((index, item) => {
    index[item[key]] = item;
    return index;
  }, {});
  const sorted = [];
  preSorted.forEach(({ value }) => {
    if (index[value]) {
      sorted.push(index[value]);
    }
  });

  return sorted;
}

function sortObj(base, preSorted, onEach) {
  const orderedKeys = Object.keys(base);
  const sorted = {};
  orderedKeys.forEach(key => {
    if (preSorted[key]) {
      if (onEach) {
        preSorted[key] = onEach(preSorted[key], base[key]);
      }
      sorted[key] = preSorted[key];
    }
  });

  return sorted;
}

export function sortTns(baseTns, tns) {
  return sortObj(baseTns, tns, (part, basePart) => {
    return sortObj(basePart, part);
  });
}

export default function sortCategories(baseCategories) {
  const categoriesList = Object.values(baseCategories);
  const orderedCategories = Array.from(new Array(categoriesOrder.length)).map(() => []);
  categoriesList.forEach(category => {
    const orderIndex = categoriesOrder.indexOf(category.baseCategory || category.categoryId);
    if (orderIndex > -1) {
      orderedCategories[orderIndex].push(category);
    }
  });

  return flatten(orderedCategories).reduce((categories, category) => {
    if (!category.baseCategory) category.baseCategory = category.categoryId;

    if (category.wash) {
      category.wash = category.wash.map(wash => {
        const baseWash = find(extendedCategories[category.baseCategory].wash, { value: wash.value });
        return {
          ...baseWash,
          ...wash,
        };
      });
    }

    if (category.patterns && category.patterns.length) {
      category.patterns = sortArrayBy(category.patterns, patternTypes, 'value');
    }

    if (category.tn && extendedCategories[category.baseCategory].tn) {
      category.tn = sortTns(extendedCategories[category.baseCategory].tn, category.tn);
      Object.keys(category.tn).forEach(part => {
        Object.keys(category.tn[part]).forEach(value => {
          category.tn[part][value] = {
            ...extendedCategories[category.baseCategory].tn[part][value],
            ...category.tn[part][value],
          };
        });
      });
    }

    categories[category.categoryId] = category;
    return categories;
  }, {});
}
