import React from 'react'
import PropTypes from 'prop-types'
import ProductGridCorp from './ProductGridCorp'
import './ProductListCorp.scss'

const ProductListCorp = ({ products }) => {
  const items = products.map((product) => {
    const {
      frontImg: imgSrc,
      brandName: brand,
      price,
      productId: id
    } = product.product

    return (
      <div
        className='product-list-corp__item-container'
        key={id}
      >
        <ProductGridCorp
          imgSrc={imgSrc}
          brand={brand}
          price={price}
        />
      </div>
    )
  })

  return (
    <div className='product-list-corp'>
      {items}
    </div>
  )
}

ProductListCorp.propTypes = {
  products: PropTypes.array
}

export default ProductListCorp
