import { getCatData } from '@yesplz/core-models/src/VFCatViewData';
import { findLabelByLng } from '@yesplz/core';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import isParamsMatchPreset from '../helpers/isParamsMatchPreset';
import uniq from 'lodash/uniq';

class StyleFilter extends Widget {
  defaultParams = {
    title : 'Styles',
    titlePrefix: [
      { lng: 'en', label: 'Choose ' },
      { lng: 'ko', label: '' },
    ],
    isMultiple: false,
    allOptions: {
      label: [{ "lng": "en", "label": "Any" }, { "lng": "ko", "label": "초기화" }],
      svg: `
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2499 21.7167C5.47449 21.7167 0.783321 17.0766 0.783321 11.3663C0.783321 5.65601 5.47447 1.01597 11.2499 1.01597C12.9488 1.01597 14.6568 1.50411 16.2396 2.42622L16.6637 2.68561L17.191 3.04006L16.9134 1.66675C16.8505 1.35286 16.9995 1.03702 17.2775 0.880617L17.3998 0.824764L17.5186 0.79406C17.8657 0.726591 18.2249 0.926745 18.3522 1.26357L18.3892 1.39498L19.0972 4.89933C19.1374 5.10606 19.0853 5.32141 18.9537 5.48911C18.8488 5.62277 18.7005 5.71692 18.5353 5.75567L18.3929 5.77572L14.9031 6.0026C14.5988 6.05376 14.3124 5.93799 14.1451 5.70758C13.9783 5.478 13.959 5.1748 14.0952 4.92648C14.2095 4.71816 14.4218 4.57191 14.6358 4.54442L14.7522 4.54078L16.5627 4.42683L16.0208 4.04084C14.7845 3.21641 13.4445 2.7005 12.1108 2.54351L11.6659 2.50459L11.2556 2.49256C6.29109 2.49248 2.2805 6.45938 2.2805 11.3663C2.2805 16.2733 6.29111 20.2402 11.2499 20.2402C16.2076 20.2402 20.2193 16.2743 20.2193 11.373C20.2136 10.9725 20.5639 10.6207 20.9679 10.6207C21.3709 10.6207 21.7221 10.9717 21.7167 11.3635C21.7167 17.0766 17.0253 21.7167 11.2499 21.7167Z" fill="#131314"/>
        </svg>
      `,
    },
  };

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.element.className = 'ThumbnailPicker';
    this.thumbnailElements = {};
    this._catdata = {};
  }

  get catdata() {
    const { categoryId } = this.state.filter;
    if (!this._catdata[categoryId]) {
      this._catdata[categoryId] = getCatData(this.main.categories[categoryId]);
    }

    return this._catdata[categoryId];
  }

  didUpdate(prevState) {
    if (
      prevState.filter.categoryId !== this.state.filter.categoryId
      ||
      prevState.filter.bodyPart !== this.state.filter.bodyPart
    ) {
      this.renderStyles();
    }
    else if (
      !isEqual(prevState.filter.params, this.state.filter.params)
    ) {
      const { bodyPart, params } = this.state.filter;
      let prevValues = prevState.filter.params[bodyPart];
      let values     = params[bodyPart];
      if (!Array.isArray(prevValues)) prevValues = [prevValues];
      if (!Array.isArray(values))     values = [values];

      uniq([...prevValues, ...values]).forEach(value => {
        const element = this.thumbnailElements[value];
        if (!element) return;

        if (values.includes(value)) {
          element.classList.add('is-active');
        }
        else {
          element.classList.remove('is-active');
        }
      })
    }
  }

  handleLabelClick = (value) => {
    const { categoryId, bodyPart, params } = this.state.filter;
    const { isMultiple } = this.params;

    EventEmitter.emit('bodyPartThumbnailClick', {
      categoryId,
      bodyPart,
      style: value,
    });

    let bodyPartValue = params[bodyPart];
    if (isMultiple) {
      bodyPartValue = [
        ...(
          typeof params[bodyPart] === 'string'
            ? params[bodyPart] === 'all' ? [] : [params[bodyPart]]
            : params[bodyPart]
        )
      ];
  
      if (bodyPartValue.includes(value)) {
        bodyPartValue = bodyPartValue.filter(v => v !== value);
      }
      else {
        bodyPartValue.push(value);
      }
  
      if (bodyPartValue.includes('all')) {
        bodyPartValue = bodyPartValue.filter(v => v !== 'all');
      }
    }
    else {
      bodyPartValue = bodyPartValue === value ? 'all' : value;
    }

    const sanitizedParams = cloneDeep(params);
    // TODO: Move this workaround to propper place.
    // It's here now to run sanitize function on styles
    // changes.
    this.catdata.sanitizePropChange(
      sanitizedParams,
      bodyPart,
      (
        (Array.isArray(bodyPartValue) && bodyPartValue.length)
        ||
        (typeof bodyPartValue === 'string')
          ? bodyPartValue
          : 'all'
      )
    );

    const newParams = {
      ...params,
      ...sanitizedParams,
    };

    const currentPresetIndex = this.state.filter.presetIndex;
    let presetIndex = null;
    if (currentPresetIndex) {
      const preset = this.main.categories[this.state.filter.categoryId].presetsList[currentPresetIndex];
      if (preset && isParamsMatchPreset(newParams, preset)) {
        presetIndex = currentPresetIndex;
      }
    }

    this.setFilter({
      presetIndex,
      params: newParams,
    });
  }

  renderStyles() {
    const { lng } = this.state.config;
    const { categoryId, bodyPart, params } = this.state.filter;
    const selectedIndex = params[bodyPart];
    const category = this.main.categories[categoryId];
    this.element.innerHTML = '';
    this.thumbnailElements = {};

    if (!category || !category.tn || !category.tn[bodyPart]) {
      this.container.classList.add('empty');
      return null;
    }

    this.container.classList.remove('empty');

    const part = find(category.parts, { name: bodyPart });

    this.titleNode.innerHTML = category && part
      ? (findLabelByLng(this.params.titlePrefix, lng) || '') + findLabelByLng(part.label, lng).toLowerCase()
      : this.params.title;

    const bodyParts = Object.keys(category.tn[bodyPart]);
    bodyParts.forEach((index) => {
      const partTypeLabel = findLabelByLng(category.tn[bodyPart][index].label, lng);
      let partTypeIcon = category.tnSprite;
      if (category.tn[bodyPart][index].icon) {
        category.tn[bodyPart][index].icon
      }
      else if (category.tnSpriteOptions) {
        const tnSprite = find(category.tnSpriteOptions, { name: category.tnSprite });
        if (tnSprite)
          partTypeIcon = tnSprite.sprite;
      }

      const tnClass = this.catdata.catcfg.getTnClass(bodyPart, index);

      if (index === 'all') return;

      this.renderStyle({
        partTypeIcon: index === 'all' ? this.params.allOptions.svg : partTypeIcon,
        isPartTypeIconSvg: index === 'all',
        partTypeLabel,
        tnClass: index === 'all' ? 'all' : tnClass,
        index,
        isSelected: selectedIndex === index || (Array.isArray(selectedIndex) && selectedIndex.includes(index)),
      });
    });

    this.renderStyle({
      partTypeIcon: this.params.allOptions.svg,
      isPartTypeIconSvg: true,
      partTypeLabel: findLabelByLng(this.params.allOptions.label, lng),
      tnClass: 'all',
      index: 'all',
      isSelected: selectedIndex === 'all' || (Array.isArray(selectedIndex) && selectedIndex.includes('all')),
    });
  }

  renderStyle({ partTypeIcon,  isPartTypeIconSvg, partTypeLabel, tnClass, index, isSelected }) {
    const labelElement = document.createElement('div');
    const icon = (partTypeIcon && partTypeIcon.default) || partTypeIcon;

    labelElement.innerHTML = `
      <div class="ThumbnailPickerOption-thumbnail ThumbnailPickerOption-thumbnail--borderless">
        <div class="ThumbnailPickerOption-imageWrapper">
          <div class="thumbnail-item">
            <div
              class="silhouetteTN ${tnClass}"
            >
              ${isPartTypeIconSvg ? partTypeIcon : icon}
            </div>
          </div>
        </div>
      </div>
      <h5>${partTypeLabel}</h5>
    `;
    labelElement.className = 'ThumbnailPickerOption' + (
      isSelected ? ' is-active' : ''
    );
    this.thumbnailElements[index] = labelElement;
    this.element.appendChild(labelElement);

    labelElement.addEventListener('click', () => {
      this.handleLabelClick(index);
    });
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.titleNode = title;
    this.container.classList.add('yesplz-body-parts');
    this.container.appendChild(title);
    this.renderStyles();
    return this.element;
  }
}

export default (params) => {
  return new StyleFilter(params);
};
