import Handlebars from 'handlebars';
import take from 'lodash/take';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import { formatCurrency } from '@yesplz/core';
import capitalize from 'lodash/capitalize';
import unescape from 'lodash/unescape';

const LAYOUT_TEMPLATE = `
<div class="yesplz-text-search-backdrop"></div>
<div class="yesplz-text-search-main">
  <div class="yesplz-text-search-container">
    <button class="yesplz-text-search-close-button"></button>
    <div class="yesplz-text-search-header">
      <div class="yesplz-text-search-bar-container">
        <form class="yesplz-text-search-form">
          <input type="text" placeholder="Search" value="" />
          <button class="submit" type="submit">Search</button>
          <button id="yesplz-text-search-form-clear" class="clear" type="button">clear</button>
        </form>
      </div>
      <div class="yesplz-text-search-menu-container">
        <div class="yesplz-menu sale-container">
          <ul>
            <li class="sale" data-menu-value="sale">Sale Only</li>
          </ul>
        </div>
        <div class="yesplz-suggestions">
          <div class="yesplz-menu">
            <ul>
              <li data-menu-value="all" class="is-active">All</li>
              <li data-menu-value="women">Women</li>
              <li data-menu-value="men">Men</li>
            </ul>
          </div>
          <h5>Top Suggestions</h5>
          <ul id="yesplz-suggested-queries"></ul>
        </div>
      </div>
    </div>
    <div class="yesplz-text-search-popular-queries">
      <h4>Popular Searches</h4>
      <ul id="yesplz-popular-queries"></ul>
    </div>
    <div class="yesplz-text-search-products products wrapper grid products-grid">
      <ol id="yesplz-text-search-products" class="products list items product-items">
      </ol>
    </div>
    <div class="yesplz-text-search-footer">
      <button id="see-results">See All Results</button>
    </div>
  </div>
</div>
`;

const PRODUCT_TEMPLATE = `
<li class="product-item item product jq-grid-item">
    <div class="product-item-info">
        <div class="product-image-block">
            <a class="product-item-photo" href="{{srcUrl}}" target="{{linkTarget}}">
                <img class="photo image lazyloaded" alt="" src="{{frontImg}}">
            </a>
        </div>
        <div class="product-item-details">
            <div class="brand">
                <a href="{{srcUrl}}">{{brand}}</a>
            </div>
            <strong class="product-item-name">
                <a title="" class="product-item-link" href="{{srcUrl}}">{{name}}</a>
            </strong>
            <div class="price-box price-final_price">
              {{#if isSale}}
              <span class="old-price">
                <span class="price-container price-final_price tax weee">
                  <span data-price-type="oldPrice" class="price-wrapper ">
                    <span class="price">{{originalPrice}}</span>
                  </span>
                </span>
              </span>
              {{/if}}
              <span class="normal-price{{#if isSale}} special-price{{/if}}">
                <span class="price-container price-final_price tax weee">
                  <span data-price-type="finalPrice" class="price-wrapper ">
                    <span class="price">{{price}}</span>
                  </span>
                </span>
              </span>
            </div>
        </div>
    </div>
</li>
`;

const { document } = window;

class SearchBar extends Widget {
  defaultParams = {
    templates: {
      layout: LAYOUT_TEMPLATE,
      product: PRODUCT_TEMPLATE,
    },
    currency: {},
    productUrl: null,
    searchPageUrl: process.env.PUBLIC_URL + '/search.html',
    onOpened: null,
    onClosed: null,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = (this.params.containerClassName || 'yesplz-text-search') + ' no-results';
    this.mainElement = element;

    this.mainInput = null;
    this.suggestedQueriesContainer = null;

    this.layoutTemplate = Handlebars.compile(this.params.templates.layout || LAYOUT_TEMPLATE);
    this.productTemplate = Handlebars.compile(this.params.templates.product || PRODUCT_TEMPLATE);

    this.gender = 'all';
    this.sale = false;

    this.menuElements = {};

    this.throttlingTimeout = null;
    this.suggestionPromise = null;
    this.searchPromise = null;
  }

  didMount() {
    this.renderLayout();
    this.suggestedQueriesContainer = this.mainElement.querySelector('#yesplz-suggested-queries');
    this.popularQueriesContainer = this.mainElement.querySelector('#yesplz-popular-queries');
    this.productsContainer = this.mainElement.querySelector('#yesplz-text-search-products');
    this.loadPopularQueries();
    this.addEventListeners();
    // this.open();
  }

  addEventListeners() {
    const startInput = document.querySelector(this.params.startInput);
    if (startInput) {
      startInput.addEventListener('focus', this.open);
    }

    const closeButton = this.mainElement.querySelector('.yesplz-text-search-close-button');
    if (closeButton) {
      closeButton.addEventListener('click', this.close);
    }

    const backdrop = this.mainElement.querySelector('.yesplz-text-search-backdrop');
    if (backdrop) {
      backdrop.addEventListener('click', this.close);
    }

    const mainInput = this.mainElement.querySelector('form input');
    if (mainInput) {
      mainInput.addEventListener('keyup', this.handleKeyUp);
    }
    this.mainInput = mainInput;
    
    const menuItems = this.mainElement.querySelectorAll('li[data-menu-value]');
    if (menuItems) {
      menuItems.forEach(item => {
        const name = item.getAttribute('data-menu-value');
        this.menuElements[name] = item;
        item.addEventListener('click', this.handleMenuClick);
      });
    }

    const form = this.mainElement.querySelector('form.yesplz-text-search-form');
    if (form) {
      form.addEventListener('submit', (e) => {
        e.preventDefault();
        this.goToResultsPage();
      });
    }

    const clearButton = form.querySelector('#yesplz-text-search-form-clear');
    clearButton.addEventListener('click', () => {
      this.reset();
    });

    const seeResults = this.mainElement.querySelector('#see-results');
    if (seeResults) {
      seeResults.addEventListener('click', (e) => {
        e.preventDefault();
        EventEmitter.emit('txtSearchBarSeeAllClick');
        this.goToResultsPage();
      });
    }
  }

  formatCurrency(number) {
    return formatCurrency(number,  this.params.currency);
  }

  goToResultsPage() {
    window.location.href= `${this.params.searchPageUrl}?query=${encodeURIComponent(this.mainInput.value)}&categories=${['men', 'women'].includes(this.gender) ? capitalize(this.gender) : ''}&sale=${this.sale || ''}`
  }

  open = () => {
    this.mainElement.classList.add('is-active');
    this.mainInput.focus();

    if (typeof this.params.onOpened === 'function') {
      this.params.onOpened();
    }

    EventEmitter.emit('txtSearchBarOpened');
  }

  close = () => {
    this.mainElement.classList.remove('is-active');
    this.reset();

    if (typeof this.params.onClosed === 'function') {
      this.params.onClosed();
    }

    EventEmitter.emit('txtSearchBarClosed');
  }

  reset = () => {
    this.mainInput.value = '';
    this.productsContainer.innerHTML = '';
    this.mainElement.classList.add('no-results');
  }

  handleMenuClick = (e) => {
    const name = e.target.getAttribute('data-menu-value');
    if (['women', 'men', 'all'].includes(name)) {
      if (this.gender === name) {
        this.gender = null;
        this.menuElements[name].classList.remove('is-active');
      }
      else {
        this.gender = name;
        ['women', 'men', 'all'].forEach(menuName => {
          if (name !== menuName) {
            this.menuElements[menuName].classList.remove('is-active');
          }
        });
        this.menuElements[name].classList.add('is-active');
      }
      EventEmitter.emit('txtSearchBarGenderChanged', this.gender);
    }
    else if (name === 'sale') {
      if (this.sale) {
        this.menuElements['sale'].classList.remove('is-active');
        this.sale = false;
        EventEmitter.emit('txtSearchBarSaleOnlyOn');
      }
      else {
        this.menuElements['sale'].classList.add('is-active');
        this.sale = true;
        EventEmitter.emit('txtSearchBarSaleOnlyOff');
      }
    }

    if (this.mainInput.value)
      this.loadResults();
  }

  handleKeyUp = () => {
    if (this.throttlingTimeout) {
      clearTimeout(this.throttlingTimeout);
    }
    this.throttlingTimeout = setTimeout(() => {
      this.loadResults();
    }, 200);
  }

  loadResults() {
    const query = this.mainInput.value;
    if (query.length > 0) {
      this.mainElement.classList.remove('no-results');
      const suggestionPromise = Promise.all([
        this.main.fetchBrandsSuggestions(query),
        this.main.fetchTextSuggestions(query, this.gender, this.sale),
        this.main.fetchTextAutocomplete(query, this.gender, this.sale),
      ]);
      this.suggestionPromise = suggestionPromise;
      suggestionPromise
        .then(([brands, suggested, autocomplete]) => {
          return [
            ...brands.map(brand => ({ suggestion: brand, type: 'brand' })),
            ...(brands.length > 0 ? [{ suggestion: ' ', type: 'separator' }] : []),
            ...take([
              ...autocomplete,
              ...suggested,
            ], 5)
          ];
        })
        .then(suggested => {
          if (this.suggestionPromise === suggestionPromise) {
            this.renderSuggestedQueries(suggested, query);
          }          
        })
        .catch(error => {
          console.error('text suggestion error', error);
        });

      const searchPromise = this.main.fetchTextSearch({
        query,
        gender: this.gender,
        sale: this.sale,
        limit: 4,
      })
      this.searchPromise = searchPromise;
      searchPromise
        .then(data => {
          if (this.searchPromise === searchPromise) {
            this.productsContainer.innerHTML = '';
            data.results.forEach(({ product }) => {
              this.renderProduct(product);
            });

            window.requestAnimationFrame(() => {
              if (typeof this.params.onProductsRendered === 'function') {
                this.params.onProductsRendered(data.results);
              }
            });  
          }
        })
        .catch(error => {
          console.error('text search error', error);
        });
    }
    else {
      this.reset();
    }
  }

  addEventListenersToList(container) {
    container.querySelectorAll('li').forEach(li => {
      li.addEventListener('click', () => {
        const type = li.getAttribute('data-type');
        const suggested = li.innerHTML.replaceAll('<strong>', '').replaceAll('</strong>', '');
        this.mainInput.value = unescape(suggested);
        EventEmitter.emit(
          type === 'popular'
            ? 'txtSearchBarPopularSearchesClick'
            : type === 'brand'
              ? 'txtSearchBarBrandClick'
              : 'txtSearchBarSuggestionClick',
          suggested
        );
        this.handleKeyUp();
      });
    });
  }

  async loadExampleProducts() {
    const data = await this.main.fetchProducts(this.gender, this.sale);
    this.productsContainer.innerHTML = '';
    data.results.forEach(({ product }) => {
      this.renderProduct(product);
    });
  }

  async loadPopularQueries() {
    const popularQueries = await this.main.fetchPopularQueries(this.gender, this.sale);
    this.renderPopularQueries(popularQueries);
  }

  getProductUrl(product) {
    if (typeof this.params.productUrl === 'function') {
      return this.params.productUrl(product);
    }
    return product.productUrl;
  }

  renderPopularQueries(populars = []) {
    const container = this.popularQueriesContainer;
    container.innerHTML = populars.map(popular => {
      return `<li data-type="popular">${popular.suggestion}</li>`;
    }).join('');

    this.addEventListenersToList(container);
  }

  renderSuggestedQueries(suggested = [], query = null) {
    const container = this.suggestedQueriesContainer;
    container.innerHTML = suggested.map(({ suggestion, type }) => {
      const suggestionWords = suggestion.split(' ');
      if (query) {
        const queryWords = query.split(' ');
        suggestionWords.forEach((suggestionWord, index) => {
          if (queryWords.includes(suggestionWord)) {
            suggestionWords[index] = `<strong>${suggestionWord}</strong>`;
          }
        });
      }
      return `<li${type ? ` class="suggestion-type-${type}" data-type="${type}"` : ''}>${suggestionWords.join(' ')}</li>`;
    }).join('');

    this.addEventListenersToList(container);
  }

  renderProduct(product) {
    this.productsContainer.insertAdjacentHTML(
      "beforeend",
      this.productTemplate({
        ...product,
        srcUrl: this.getProductUrl(product),
        frontImg: product.frontImgSrc,
        name: product.productName,
        brand: product.brand || product.brandName,
        isSale: !!(product.originalPrice && product.originalPrice !== product.salePrice),
        originalPrice: this.formatCurrency(product.originalPrice),
        price: this.formatCurrency(product.salePrice),
      })
    );
    
    this.productsContainer.lastElementChild.querySelectorAll('a')?.forEach(a => {
      a.addEventListener('click', () => {
        EventEmitter.emit('txtSearchBarProductClick', product.productId);
      });
    });
  }

  renderLayout() {
    this.mainElement.innerHTML = this.layoutTemplate({});
    if (typeof this.params.onRendered === 'function') {
      window.requestAnimationFrame(() => {
        this.params.onRendered();
      });
    }
  }

  render() {
    return this.mainElement;
  }
}

export default (params) => {
  return new SearchBar(params);
};
