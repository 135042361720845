import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

const SidebarItem = ({ title, classes, children, isHidden }) => {
  const [ expanded, setExpanded ] = useState(true)
  return (
    <Accordion
      expanded={expanded}
      className={classnames('SidebarItem', classes.root, {
        [classes.hidden]: isHidden,
      })}
      onChange={(_, isActive) => { setExpanded(isActive) }}
    >
      <AccordionSummary
        expandIcon={expanded ? '-' : '+'}
        classes={{
          root: classes.panelHeader,
          expandIcon: classes.expandIcon
        }}
      >
        <Typography className={classes.heading}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.panelRoot }}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

SidebarItem.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired
}

const styles = {
  root: {
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    }
  },
  hidden: {
    display: 'none',
  },
  heading: {
    fontSize: 14,
    letterSpacing: 1,
    textTransform: 'uppercase',
    color: '#1b1b1d'
  },
  expandIcon: {
    fontSize: 20,
    color: '#1b1b1d',
    right: 27
  },
  panelHeader: {
    padding: '0px 26px'
  },
  panelRoot: {
    padding: '0'
  }
}

export default withStyles(styles)(SidebarItem)
