import "core-js/stable";
import "regenerator-runtime/runtime";
import { fetchConfiguration } from '@yesplz/visualfilter/src/extended-configuration';
import { VisualFilter } from '@yesplz/visualfilter';
import { Product } from '@yesplz/product';
import { TextSearch } from '@yesplz/textsearch';
import findLabelByLng from './findLabelByLng';
import formatCurrency from './formatCurrency';
import sortCategories from './sortCategories';
import fillPublishedListItem from './fillPublishedListItem';
import { fillCategoriesByParent, cleanChildrenByParents } from './fillCategoriesByParent';

const yesplz = {
  VisualFilter,
  Product,
  TextSearch,
  fetchConfiguration,
};

const StyleFilter = VisualFilter;

export default yesplz;

export {
  StyleFilter,
  VisualFilter,
  Product,
  TextSearch,
  fetchConfiguration,
  findLabelByLng,
  formatCurrency,
  sortCategories,
  fillPublishedListItem,
  fillCategoriesByParent,
  cleanChildrenByParents,
};
