import { createSelectorCreator, defaultMemoize } from 'reselect'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
)

const getMatchingProducts = products => (
  filter(products, product => product.match)
)

export const matchingProductsSelector = createDeepEqualSelector(
  products => products,
  getMatchingProducts
)

const getCloseMatchingProducts = (products) => (
  filter(products, product => !product.match)
)

export const closeMatchingProductsSelector = createDeepEqualSelector(
  products => products,
  getCloseMatchingProducts
)
