import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import './TutorialNewArrival.scss'

import { setDesktopOnboarding } from '@yesplz/core-redux/ducks/filters'

import arrow3 from '@yesplz/core-web/assets/svg/desktop-onboarding-3.svg'
import arrow1 from '@yesplz/core-web/assets/svg/desktop-onboarding-1.svg'
import arrow2 from '@yesplz/core-web/assets/svg/desktop-onboarding-2.svg'

const { localStorage } = window

const TutorialNewArrival = (props) => {
  const [view, setView] = useState(false)
  const [nextStep, setNextStep] = useState(0)
  const { setDesktopOnboarding } = props

  useEffect(() => {
    if (nextStep > 3) {
      setDesktopOnboarding(false)
      localStorage.setItem('desktop_onboarded', 1)
    }
  })

  const skipTutorial = () => {
    setView(true)

    setDesktopOnboarding(false)
    localStorage.setItem('desktop_onboarded', 1)
  }

  const nextTutorial = () => {
    setNextStep(nextStep + 1)

    props.handleViewConfig()
  }

  return !view && (
    <div className='TutorialNewArrival'>
      <div className='TutorialNewArrival__black' />
      <div className='TutorialNewArrival__skip' style={nextStep === 0 ? { width: '100%' } : { width: 325 }} onClick={skipTutorial} />
      {nextStep === 0 && (
        <div className='TutorialNewArrival__content tutorial-step-0'>
          <button onClick={nextTutorial} />
          <img src={arrow3} alt='' />
          <div className='TutorialNewArrival__text'>
            <p>Hi!</p>
            <p>Click this button to learn <br /> how our visual filter works!</p>
          </div>
        </div>
      )}
      {nextStep === 1 && (
        <div className='TutorialNewArrival__content tutorial-step-1'>
          <button onClick={nextTutorial} />
          <img src={arrow1} alt='' />
          <div className='TutorialNewArrival__text'>
            <p>Click the diamond button <br />to change the design.</p>
          </div>
        </div>
      )}
      {nextStep === 2 && (
        <div className='TutorialNewArrival__content tutorial-step-2'>
          <button onClick={nextTutorial} />
          <img src={arrow2} alt='' />
          <div className='TutorialNewArrival__text'>
            <p>Try the thumbanils as a <br />shortcut.</p>
          </div>
        </div>
      )}
      {nextStep === 3 && (
        <div className='TutorialNewArrival__content tutorial-step-3'>
          <button onClick={nextTutorial} />
          <img src={arrow3} alt='' />
          <div className='TutorialNewArrival__text'>
            <p>All Done!  <br /><br />Click here to save the style.</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default connect(
  () => ({}),
  {
    setDesktopOnboarding
  }
)(TutorialNewArrival)
