import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty, take } from 'lodash'

// core-redux
import { fetchPresets, getProducts, setActiveCategory } from '@yesplz/core-redux/ducks/products'
import { setFilter } from '@yesplz/core-redux/ducks/filters'

// core-web
import history from '@yesplz/core-web/config/history'
import { Button } from '@yesplz/core-web/ui-kits/buttons'
import withTrackingProvider from '@yesplz/core-web/hoc/withTrackingProvider'
import { ProductList } from '@yesplz/core-web/modules/products'
import { SectionTitle } from '@yesplz/core-web/ui-kits/misc'
import { formatPresetName } from '@yesplz/core-web/utils'

import {
    CATEGORIES_LABELS,
    CATEGORIES_EDITOR_PICKS
} from '@yesplz/core-web/config/constants'

import './AllProductsPage.scss'
import {AdvancedPresetList} from "@yesplz/core-web/modules/presets";

class AllProductsPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        presets: PropTypes.array
    }

    state = {
        pageTitle: '',
        isFetched: [],
        fetchedProducts: []
    }

    handleClickEditorPick = preset => () => {
        const { setFilter } = this.props
        setFilter(preset.category, this.getPresetFilter(preset))
        // `/products/${preset.category}/list?listingView=single&page=editorspick&preset=${formatPresetName(preset.name)}`
        history.push(
            `/products/${preset.category}/list?listingView=single&page=all`
        )
    }

    handleClickExplore = preset => () => {
        const { setFilter } = this.props
        setFilter(preset.category, this.getPresetFilter(preset))
        history.push(
            `/products/${preset.category}/list?listingView=single&page=all`
        )
    }

    getPresetFilter = (preset, isPreset = false) => {
        const customFilter = { ...preset }
        // ToDo: customFilter['preset'] = formatPresetName(preset.name)
        if (isPreset) {
            customFilter['preset'] = formatPresetName(preset.name)
        }
        delete customFilter.category
        delete customFilter.name

        return customFilter
    }

    capitalizePresetTitle = presetName => {
        const partsOfName = presetName.split(' ')
        partsOfName.forEach((part, idx) => {
            partsOfName[idx] = part.charAt(0) + part.slice(1).toLowerCase()
        })
        return partsOfName.join(' ')
    }

    getRecommendedProducts = (preset, limit, index) => {
        const { isFetched, fetchedProducts } = this.state
        const { category } = preset
        const filters = this.getPresetFilter(preset)
        isFetched[index] = false
        fetchedProducts[index] = []
        this.setState({
            isFetched: isFetched,
            fetchedProducts: fetchedProducts
        })
        getProducts(category, filters, limit)
            .then(res => {
                isFetched[index] = true
                fetchedProducts[index] = take(res.products, 3)
                this.setState({
                    isFetched: isFetched,
                    fetchedProducts: fetchedProducts
                })
            })
            .catch(() => {
                return
            })
    }

    componentDidMount () {
        const { match, fetchPresets, presets, setActiveCategory } = this.props
        if (match.params.category) {
            this.setState({ pageTitle: `All ${CATEGORIES_LABELS[match.params.category]}` })
            setActiveCategory(match.params.category)
            fetchPresets(match.params.category)
        }

        if (presets) {
            presets.forEach((preset, idx) => {
                this.getRecommendedProducts(preset, 10, idx)
            })
        }
    }

    componentDidUpdate (prevProps, prevState) {
        const { presets, match, fetchPresets, presetsFilters, setActiveCategory } = this.props
        if (isEmpty(prevProps.presets) && presets.length > 0) {
            presets.forEach((preset, idx) => {
                this.getRecommendedProducts(preset, 10, idx)
            })
        }

        if (prevProps.match.params.category !== match.params.category) {
            this.setState({ pageTitle: `All ${CATEGORIES_LABELS[match.params.category]}` })
            setActiveCategory(match.params.category)
            fetchPresets(match.params.category)
        }

        if (prevProps.presetsFilters.length !== presetsFilters.length) {
            this.setState({presetsEditor: true})
        }
    }

    render () {
        const { presets, match } = this.props
        const { pageTitle, isFetched, fetchedProducts } = this.state

        return (
            <div id='MainScroll' className='All-Products' style={{ paddingBottom: 100 }}>
                {/* section */}
                <div className='Home-section'>
                    <SectionTitle
                        title={pageTitle}
                    />
                    {
                        presets.map((preset, idx) => (
                            <React.Fragment key={preset.name}>
                                <SectionTitle
                                    title={this.capitalizePresetTitle(preset.name)}
                                    small
                                    onClick={this.handleClickExplore(preset)}
                                />
                                <ProductList
                                    category={preset.category}
                                    className='Recommended-products'
                                    customFilters={this.getPresetFilter(preset)}
                                    limitPerPage={3}
                                    recommendedProducts={fetchedProducts[idx]}
                                    recommended
                                    showOriginalPrice
                                    show={isFetched[idx]}
                                    style={{ overflow: 'hidden' }}
                                />
                                <Button kind='secondary' onClick={this.handleClickExplore(preset)} >
                                    Explore Smart Filter
                                </Button>
                                <br />
                            </React.Fragment>
                        ))
                    }
                </div>

                {/* editor's pick section */}
                {CATEGORIES_EDITOR_PICKS[match.params.category] &&
                <div className='Home-section'>
                      <SectionTitle
                        title='EDITOR PICKS'
                      />
                      <div className='container-wide'>
                          <AdvancedPresetList
                            activeCategory={match.params.category}
                            rowCount={2}
                            presetMatchesCount={4}
                            useMinimalPreset
                            titleBellowPreset
                            defaultViewBoxSvg={[0, 0, 304, 214]}
                            onClickGroupTitle={this.handleClickEditorPick}
                          />
                      </div>
                  </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        presets: state.products[props.match.params.category].presets,
        presetsFilters: state.filters.presets
    }
}

export default compose(
    connect(
        mapStateToProps,
        {
            fetchPresets,
            setActiveCategory,
            setFilter
        }
    ),
    withTrackingProvider('AllProducts')
)(AllProductsPage)
