import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { categories } from 'config/categories';
import { findLabelByLng } from '@yesplz/core';
import getSectionedMenu from '../../config/menuItems';
import './MainMenu.scss';
import classNames from 'classnames';

export default class MainMenu extends Component {
  state = {
    hoveredMenu: null,
  };

  handleLinkClick = () => {
    this.props.onLinkClick();
    this.setState({ hoveredMenu: null });
  }

  renderMenuTree(children) {
    if (!children || children.length === 0) return null;

    return (
      <ul>
        {children.map((item, index) => {
          if (item.categoryId && !categories[item.categoryId]) return null;

          const categoryId = item.categoryId;
          const label = categoryId ? findLabelByLng(categories[categoryId].label).replace('Women', '').replace('Men', '') : findLabelByLng(item.label);
          const to = categoryId ? `/visualfilter/${encodeURIComponent(categoryId)}` : item.to;

          return (
            <li key={`${to}${index}`} className={classNames({ 'is-empty': item.children && !item.children.length })}>
              {to && <NavLink
                exact
                to={to}
                onClick={this.handleLinkClick}>
                {label}
              </NavLink>}
              {!to && <span dangerouslySetInnerHTML={{ __html: label }} />}
              {this.renderMenuTree(item.children)}
            </li>
          );
        })}
      </ul>
    );
  }

  render () {
    const { hoveredMenu } = this.state;
    const menuItemsSectioned = getSectionedMenu();

    return (
      <ul className="Main-Menu">
        {menuItemsSectioned.map((item, index) => (
          <li
            key={`${item.to}${index}`}
            className={classnames({
              hovered: hoveredMenu === index,
            })}
            onMouseOver={() => this.setState({ hoveredMenu: index })}
            onMouseOut={() => this.setState({ hoveredMenu: null })}
          >
            <NavLink
              exact
              to={item.to}
              onClick={this.handleLinkClick}>
              {item.label}
            </NavLink>
            {item.children && this.renderMenuTree(item.children)}
          </li>
        ))}
      </ul>
    );
  }
}
