import React, { useEffect } from 'react'
import Picker from 'react-mobile-picker'
import PropTypes from 'prop-types'
import { Transition as RTransition } from 'react-transition-group'
import preventDefault from '@yesplz/core-web/utils/preventDefault'
import AngleDown from '@yesplz/core-web/assets/svg/angle-down-blue.svg'
import './MobilePicker.scss'

const MobilePicker = ({
  name,
  options,
  value,
  isVisible,
  hideBackdrop,
  onChange,
  onPick,
  onClose
}) => {
  const warrapedElement = document.getElementById('Base-mobile')
  if (isVisible && warrapedElement) {
    warrapedElement.classList.add('Base-fixed-height')
  } else if (!isVisible && warrapedElement) {
    warrapedElement.classList.remove('Base-fixed-height')
  } else {
  }

  const onNavClick = (e) => {
    const buttonName = e.currentTarget.name

    const direction = (buttonName === 'next') ? 1 : -1
    let index = options.findIndex(item => item === value)
    index += direction

    if (index < 0 || index > options.length - 1) {
      return
    }

    const newValue = options[index]
    onChange(name, newValue)
  }

  return (
    <RTransition timeout={500} in={isVisible}>
      {
        state => {
          if (state === 'exited') {
            return null
          }

          return (
            <div id='MobilePicker' className={`MobilePicker ${state}`}>
              {!hideBackdrop && <div className='MobilePicker-backDrop' onClick={onClose} />}
              <div className='MobilePicker-content'>
                <div className='MobilePicker-contentHeader'>
                  <div>
                    <button
                      className='MobilePicker__button-prev'
                      type='button'
                      onClick={onNavClick}
                      name='prev'
                    >
                      <img src={AngleDown} alt='nav-arrow' />
                    </button>
                    <button
                      className='MobilePicker__button-next'
                      type='button'
                      onClick={onNavClick}
                      name='next'
                    >
                      <img src={AngleDown} alt='nav-arrow' />
                    </button>
                  </div>
                  <button className='MobilePicker-button' onClick={onPick}>Done</button>
                </div>
                <Picker
                  optionGroups={{[name]:options}}
                  valueGroups={{[name]:value}}
                  onChange={onChange}
                />
              </div>
            </div>
          )
        }
      }
    </RTransition>
  )
}

MobilePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  isVisible: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  onChange: PropTypes.func,
  onPick: PropTypes.func,
  onClose: PropTypes.func
}

MobilePicker.defaultProps = {
  isVisible: false,
  hideBackdrop: false
}

export default MobilePicker
