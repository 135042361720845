import React, { Component } from 'react';
import client from '@yesplz/client';
import visualfilter from '@yesplz/visualfilter';
import ProductGrid from '@yesplz/core-web/modules/products/ProductGrid';
import { Button } from '@yesplz/core-web/ui-kits/buttons'
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import FilterButtonsContainer from '@yesplz/core-web/modules/products/FilterButtonsContainer'
import StyleLikeButton from '@yesplz/core-web/modules/products/StyleLikeButton';
import './CategorySection.scss';

class CategorySection extends Component {
  static defaultProps = {
    showButton: true,
  }

  constructor (props) {
    super(props)

    this.state = {
      products: [],
    };

    this.visualFilter = null;

    const postfix = Math.random().toString(36).substring(7);
    this.containerId = `visual-filter-${postfix}`;
    this.svgContainerId = `visual-filter-svg-${postfix}`;
  }

  componentDidMount() {
    this.renderVisualFilter();
    this.searchWithDefaultParams();
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.products.length !== this.state.products.length
      &&
      this.props.onProductsRendered
    ) {
      this.props.onProductsRendered();
    }
  }

  componentWillUnmount() {
    if (!this.visualFilter) return;
    this.visualFilter.off('searchFinished', this.onSearchFinished);
  }

  onSearchFinished = (data) => {
    this.setState({
      products: data.results.slice(0, 4),
    });
  }

  async searchWithDefaultParams() {
    const { categoryId, config, currentFilter } = this.props;
    const data = await client.search(
      currentFilter && currentFilter.category
        ? currentFilter.category
        : config.category,
      categoryId,
      currentFilter && currentFilter.params
        ? currentFilter.params
        : config.defaultVal,
      null,
      0,
      4,
    );
    this.setState({
      products: data.results,
    });
  }

  handleFilterClick = () => {
    const { categoryId, config, currentFilter } = this.props;
    const filter = currentFilter ? currentFilter : config.defaultVal;
    this.props.history.push(`/visualfilter/${encodeURIComponent(categoryId)}`, { filter });
  }

  renderVisualFilter() {
    const { categoryId, config, currentFilter } = this.props;
    const vs = visualfilter({
      clientBaseURL: process.env.REACT_APP_BASE_API_PATH,
      categories: {
        [categoryId]: config,
      },
      searchFinishedReturnFields: null,
      usePersistentFilter: false,
      useSearch: false,
      initialFilter: currentFilter ? currentFilter : null,
    });

    vs.addWidget(
      vs.widgets.VisualFilter({
        container: `#${this.containerId}`,
        svgContainerId: this.svgContainerId,
        svgViewBox: (
          categoryId === 'mwallet' || categoryId === 'wwallet'
            ? [60, -50, 235, 300]
            : categoryId === 'mbag' || categoryId === 'wbag' || categoryId === 'wearring'
              ? [50, -50, 235, 300]
              : [60, -15, 235, 300]
        ),
        svgViewBoxMobile: [60, -15, 235, 300],
        svgScale: 1,
        svgHideTouchPoints: true,
        presetNavigation: false,
        showTooltipsToggler: false,
      })
    );

    vs.on('searchFinished', this.onSearchFinished);

    this.visualFilter = vs;
  }

  render() {
    const { categoryId, config, title, link, buttonRef, isOnboarding, currentFilter, showButton }  =  this.props;
    const { products } = this.state;
    return (
      <div className="CategorySection">
        <div
          className="CategorySection-VF"
          onClick={this.handleFilterClick}
        >
          {/* <h3>{title}</h3> */}
          <div className="CategorySection-VF-image">
            <FilterButtonsContainer align="top" buttonSize="big" noMargins>
              <StyleLikeButton categoryId={categoryId} filter={currentFilter || {
                categoryId,
                cateogry: config.category,
                params: config.defaultVal,
              }} />
            </FilterButtonsContainer>
            <div className="VF-container" id={this.containerId}></div>
          </div>
          {showButton && <Button
            kind='secondary'
            to={link}
            className={classnames({
              'Button-onboarding': isOnboarding,
            })}
            buttonRef={buttonRef}
          >
            Shop {title}
          </Button>}
        </div>
        <div className="CategorySection-Products">
          {
            products.length > 0
            &&
            <div className="ProductList-wrapper">
              {products.map(product => (
                <ProductGrid
                  key={product.product.productId}
                  {...{
                    id: product.product.productId,
                    categoryId: categoryId,
                    brand: product.product.brandName,
                    name: product.product.productName,
                    imgSrc: product.product.frontImgSrc,
                    price: product.product.salePrice,
                    originalPrice: product.product.originalPrice,
                  }}
                />
              ))}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(CategorySection);
