import difference from 'lodash/difference';
import { findLabelByLng } from '@yesplz/core';
import Widget from '@yesplz/visualfilter/src/modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const CONTAINER_CLASS_NAME = `yesplz-brand-filter`;

class OccasionsFilter extends Widget {
  defaultParams = {
    fitText: false,
    fitTextMaxSize: 15,
    showResetButton: false,
  }

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.occasions = {};
    this.labelElements = {};
  }

  handleLabelClick = (e) => {
    const button = e.target;
    const newOccasion = button.getAttribute('data-occasion');
    const { categoryId, occasion: currentOccasion } = this.state.filter;

    EventEmitter.emit('occasionClick', {
      categoryId,
      occasion: newOccasion,
    });

    let occasion = [...(currentOccasion || [])];

    if (occasion.includes(newOccasion)) {
      occasion = occasion.filter(o => o !== newOccasion);

      EventEmitter.emit('occasionRemoved', {
        categoryId,
        occasion: newOccasion,
      });
    }
    else {
      occasion.push(newOccasion);

      EventEmitter.emit('occasionApplied', {
        categoryId,
        occasion: newOccasion,
      });
    }

    this.setFilter({
      ...this.state.filter,
      occasion,
    });
  }

  didUpdate(prevState) {
    if (this.state.filter.categoryId !== prevState.filter.categoryId) {
      this.renderItems();
    }
    else if (this.state.filter.occasion !== prevState.filter.occasion) {
      const occasion = this.state.filter.occasion;
      const prevOccasion = prevState.filter.occasion;

      const selected = difference(occasion, prevOccasion);
      const deselected = difference(prevOccasion, occasion);

      selected.forEach(occasion => {
        const button = this.element.querySelector(`[data-occasion="${occasion}"]`);
        if (button) button.classList.add('is-selected');
      });
      deselected.forEach(occasion => {
        const button = this.element.querySelector(`[data-occasion="${occasion}"]`)
        if (button) button.classList.remove('is-selected');
      });
    }
  }

  renderItems() {
    const { lng } = this.state.config;
    const { categoryId, occasion } = this.state.filter;
    const categorySettings = this.main.categories[categoryId];

    if (!categorySettings) return;

    this.element.className = CONTAINER_CLASS_NAME;
    this.element.innerHTML = '';
    this.occasions = categorySettings.occasionsList || [];

    const presetsHtml = this.occasions.map(({ label, value }) => {
      const className = (occasion || []).includes(value) ? ` class="is-selected"` : '';
      return `
        <li data-occasion="${value}"${className}>
          ${findLabelByLng(label, lng)}
        </li>
      `;
    });

    const html = `
      <ul>
        ${presetsHtml.join('')}
      </ul>
    `;

    this.element.insertAdjacentHTML('beforeend', html);

    this.element.querySelectorAll('[data-occasion]').forEach(button => {
      button.addEventListener('click', this.handleLabelClick);
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(this.occasions);
    }
  }

  render() {
    this.renderItems();
    return this.element;
  }
}

export default (params) => {
  return new OccasionsFilter(params);
};
