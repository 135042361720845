import { createReducer } from '@reduxjs/toolkit';
import {
  searchResultsPending,
  searchResultsSuccess,
  searchResultsError,
} from '../actions/searchActions';

const initialFilter = {
  isLoading: false,
  error: null,
  count: null,
  counts: null,
};

const search = createReducer(initialFilter, {
  [searchResultsPending.type]: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  [searchResultsSuccess.type]: (state, action) => {
    state.isLoading = false;
    state.results = action.payload.config && action.payload.config.infiniteScroll
      ? [
        ...(action.payload.prevResults || []),
        ...action.payload.results,
      ]
      : action.payload.results;
    state.counts = action.payload.counts;
    state.count = action.payload.count || (action.payload.counts && action.payload.counts.total);

    state.filters = action.payload.filter;
  },
  [searchResultsError.type]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload.error;
  },
});

export default search;
