import EventEmitter from './EventEmitter';
import { handleEvent } from '@yesplz/visualfilter/src/modules/analytics';

export const ANALYTICS_EVENTS_TO_SUBSCRIBE = [
  'txtSearchBarOpened',
  'txtSearchBarClosed',
  'txtSearchBarGenderChanged',
  'txtSearchBarPopularSearchesClick',
  'txtSearchBarSuggestionClick',
  'txtSearchBarBrandClick',
  'txtSearchBarSaleOnlyOn',
  'txtSearchBarSaleOnlyOff',
  'txtSearchBarSeeAllClick',
  'txtSearchBarProductClick',
  'txtSidebarCategoryChanged',
  'txtSidebarSaleApplied',
  'txtSidebarSaleRemoved',
  'txtSidebarClearAllClick',
  'txtSidebarBrandApplied',
  'txtSidebarBrandRemoved',
  'txtSidebarColorApplied',
  'txtSidebarColorRemoved',
  'txtSidebarMaterialsApplied',
  'txtSidebarMaterialsRemoved',
  'txtSidebarSizesApplied',
  'txtSidebarSizesRemoved',
  'txtSidebarDiscountApplied',
  'txtSidebarDiscountRemoved',
  'txtSidebarShippingApplied',
  'txtSidebarShippingRemoved',
  'txtProductListItemClicked',
  'txtPaginationPageChanged',
  'txtSortChanged',
  'txtPageSizeChanged',
];

ANALYTICS_EVENTS_TO_SUBSCRIBE.map(action => {
  EventEmitter.on(
    (
      typeof action === 'string'
        ? action
        : action.event
    ),
    (label) => handleEvent(action, label)
  );
});
