import { findLabelByLng } from '@yesplz/core';
import Widget from '@yesplz/visualfilter/src/modules/Widget';

const CONTAINER_CLASS_NAME = `simple-presets-list`;

class SimpleMoodsList extends Widget {
  defaultParams = {
    fitText: false,
    fitTextMaxSize: 15,
    showResetButton: false,
  }

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.moods = {};
    this.labelElements = {};
  }

  handleLabelClick = (e) => {
    const button = e.target;
    const newMood = button.getAttribute('data-mood');
    const { mood, categoryId, occasion, presetIndex } = this.state.filter;
    const { moodsList, defaultVal } = this.main.categories[categoryId];

    const newMoodItem = moodsList.reduce((found, item) => {
      if (!found && item.value === newMood) {
        return item;
      }
      return found;
    }, null);

    this.setFilter({
      mood: newMood !== mood ? newMood : null,
      occasion: newMood !== mood ? null : occasion,
      presetIndex: newMood !== mood ? null : presetIndex,
      params: {
        ...(
          newMoodItem.preset
            ? newMoodItem.preset
            : defaultVal || {}
        ),
      },
    });
  }

  didUpdate(prevState) {
    if (this.state.filter.categoryId !== prevState.filter.categoryId) {
      this.renderItems();
    }
    else if (this.state.filter.mood !== prevState.filter.mood) {
      const mood = this.state.filter.mood;
      const prevMood = prevState.filter.mood;

      if (mood) {
        const button = this.element.querySelector(`[data-mood="${mood}"]`);
        if (button) button.classList.add('is-selected');
      }
      if (prevMood) {
        const button = this.element.querySelector(`[data-mood="${prevMood}"]`)
        if (button) button.classList.remove('is-selected');
      }
    }
  }

  renderItems() {
    const { lng } = this.state.config;
    const { categoryId, mood } = this.state.filter;
    const categorySettings = this.main.categories[categoryId];

    if (!categorySettings) return;

    this.element.className = CONTAINER_CLASS_NAME;
    this.element.innerHTML = '';
    this.moods = categorySettings.moodsList || [];

    const presetsHtml = this.moods.map(({ label, value }) => {
      return `
        <li data-mood="${value}" ${mood === value ? ' class="is-selected"' : ''}>
          ${findLabelByLng(label, lng)}
        </li>
      `;
    });

    const html = `
      <ul>
        ${presetsHtml.join('')}
      </ul>
    `;

    this.element.insertAdjacentHTML('beforeend', html);

    this.element.querySelectorAll('[data-mood]').forEach(button => {
      button.addEventListener('click', this.handleLabelClick);
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(this.moods);
    }
  }

  render() {
    this.renderItems();
    return this.element;
  }
}

export default (params) => {
  return new SimpleMoodsList(params);
};
