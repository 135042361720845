import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLikeStyle } from '@yesplz/core-redux/ducks/favoritesStorage';
import { LikeButton } from '@yesplz/core-web/ui-kits/buttons';
import objectHash from '@yesplz/core-web/utils/objectHash';

export default ({ categoryId, filter }) => {
  const cleanedFilter = { ...(filter || {}) };
  delete cleanedFilter.offset;
  delete cleanedFilter.limit;
  if (cleanedFilter.params)
    delete cleanedFilter.params.editorspick;

  const filterHash = objectHash(cleanedFilter);

  const dispatch = useDispatch();
  const isFavorite = useSelector(({ favoritesStorage }) => !!(
    favoritesStorage.styles[categoryId]
    &&
    favoritesStorage.styles[categoryId][filterHash]
  ));

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(toggleLikeStyle(categoryId, filterHash, cleanedFilter));
  }

  return (
    <LikeButton active={isFavorite} onClick={handleClick} />
  );
}
