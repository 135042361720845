import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import { findLabelByLng } from '@yesplz/core';
import Widget from '@yesplz/visualfilter/src/modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const CONTAINER_CLASS_NAME = `yesplz-brand-filter`;

class SimpleVibesList extends Widget {
  defaultParams = {
  }

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.labelElements = {};
  }

  handleLabelClick = (e) => {
    const button = e.target;
    const newVibe = button.getAttribute('data-vibe');
    const { categoryId, params } = this.state.filter;

    EventEmitter.emit('vibeClick', {
      categoryId,
      vibe: newVibe,
    });

    let vibe = [...(params.vibe || [])];

    if (vibe.includes(newVibe)) {
      vibe = vibe.filter(v => v !== newVibe);

      EventEmitter.emit('vibeRemoved', {
        categoryId,
        vibe: newVibe,
      });
    }
    else {
      vibe.push(newVibe);

      EventEmitter.emit('vibeApplied', {
        categoryId,
        vibe: newVibe,
      });
    }

    this.setFilter({
      params: {
        ...params,
        vibe,
      },
    });
  }

  didUpdate(prevState) {
    if (
      this.state.filter.categoryId !== prevState.filter.categoryId
      ||
      !isEqual(
        prevState.search?.filters?.vibes,
        this.state.search?.filters?.vibes
      )
    ) {
      this.renderItems();
    }
    else if (this.state.filter.params.vibe !== prevState.filter.params.vibe) {
      const vibe = this.state.filter.params.vibe;
      const prevVibe = prevState.filter.params.vibe;

      const selected = difference(vibe, prevVibe);
      const deselected = difference(prevVibe, vibe);

      selected.forEach(vibe => {
        const button = this.element.querySelector(`[data-vibe="${vibe}"]`);
        if (button) button.classList.add('is-selected');
      });
      deselected.forEach(vibe => {
        const button = this.element.querySelector(`[data-vibe="${vibe}"]`)
        if (button) button.classList.remove('is-selected');
      });
    }
  }

  get vibes() {
    if (this.state.search?.filters?.vibes) {
      return Object.values(this.state.search.filters.vibes).map(vibe => ({
        label: vibe.value,
        value: vibe.value,
      }));
    }

    const { categoryId } = this.state.filter;
    const categorySettings = this.main.categories[categoryId];

    return categorySettings.vibesList || [];
  }

  renderItems() {
    const { lng } = this.state.config;
    const currentVibe = this.state.filter.params?.vibe || [];

    this.element.className = CONTAINER_CLASS_NAME;
    this.element.innerHTML = '';

    const presetsHtml = this.vibes.map(({ label, value }) => {
      return `
        <li data-vibe="${value}"${currentVibe.includes(value) ? ' class="is-selected"' : ''}>
          ${findLabelByLng(label, lng)}
        </li>
      `;
    });

    const html = `
      <ul>
        ${presetsHtml.join('')}
      </ul>
    `;

    this.element.insertAdjacentHTML('beforeend', html);

    this.element.querySelectorAll('[data-vibe]').forEach(button => {
      button.addEventListener('click', this.handleLabelClick);
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(this.vibes);
    }
  }

  render() {
    this.renderItems();
    return this.element;
  }
}

export default (params) => {
  return new SimpleVibesList(params);
};
