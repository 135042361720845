import React from 'react'
import PropTypes from 'prop-types'
import AdvancedFilterTabs, { TabItem } from './AdvancedFilterTabs'
import ColorPallete from '../filters/ColorPallete'
import { CATEGORIES_LABELS } from '@yesplz/core-web/config/constants'
import StylesSelect from './StylesSelect'
import DesignSelect from './DesignSelect'
import MaterialSelect from './MaterialSelect'
import FavoritesSelect from './FavoritesSelect'
import AdvancedFilterHandler from './AdvancedFilterHandler'

const AdvancedFilter = ({ category, lastBodyPart, config, filters, style, onChange, onBodyPartChange }) => {
  const tabs = [
    { label: 'Styles', key: 'styles' },
    { label: 'Design', key: 'design' },
    { label: 'Colors', key: 'colors' },
    { label: 'Materials', key: 'materials' },
    { label: 'My ' + CATEGORIES_LABELS[category], key: 'favorite' }
  ]
  return (
    <AdvancedFilterHandler
      filters={filters}
      onChange={onChange}
      render={(categorizedFilters, handleFilterChange) => (
        <AdvancedFilterTabs tabs={tabs} style={style} categorizedFilters={categorizedFilters}>
          <TabItem tabKey='styles' style={{ padding: 0 }}>
            <StylesSelect
              name='style'
              value={categorizedFilters.style}
              category={category}
              lastBodyPart={lastBodyPart}
              config={config}
              onChange={handleFilterChange}
              onBodyPartChange={onBodyPartChange}
            />
          </TabItem>

          <TabItem tabKey='design'>
            <DesignSelect name='design' category={category} value={categorizedFilters.design} onChange={handleFilterChange} />
          </TabItem>

          <TabItem tabKey='colors'>
            <ColorPallete values={categorizedFilters.color} onColorClick={(values) => handleFilterChange('color', values)} />
          </TabItem>

          <TabItem tabKey='materials'>
            <MaterialSelect name='material' category={category} values={categorizedFilters.material} onChange={handleFilterChange} />
          </TabItem>

          <TabItem tabKey='favorite'>
            <FavoritesSelect name='favorite' category={category} value={filters} onChange={handleFilterChange} />
          </TabItem>
        </AdvancedFilterTabs>
      )}
    />
  )
}

AdvancedFilter.propTypes = {
  category: PropTypes.string.isRequired,
  lastBodyPart: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  filters: PropTypes.object,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onBodyPartChange: PropTypes.func.isRequired
}

AdvancedFilter.defaultProps = {
  config: {},
  filters: {},
  onChange: (filters) => {}
}

export default AdvancedFilter
