import React from 'react';
import useOnboarding from '../hooks/useOnboarding';

export default (WrappedComponent) => {
  return (props) => {
    const [isOnboarding, finishOnboarding] = useOnboarding();
    return (
      <WrappedComponent
        {...{isOnboarding, finishOnboarding}}
        {...props}
      />
    )
  }
}
