import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const shippingOptions = [
  { label: 'Priority', value: 'PRIORITY' },
  { label: 'Standard', value: 'OVERSEAS_STANDARD' },
  { label: 'Pre-order', value: 'OVERSEAS_PRE_ORDER' },
  { label: 'Made to order', value: 'OVERSEAS_MADETOORDER' },
];

class ShippingOptionsFilter extends Widget {
  defaultParams = {
    title: 'Shipping Option',
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.ul = document.createElement('ul');

    this.mainElement.appendChild(this.ul);

    this.selected = [];
  }

  didMount() {
    this.renderShippingOptions();
  }

  didUpdate(prevState) {
    if (
      !isEqual(
        prevState.search?.filters?.shippingZone,
        this.state.search?.filters?.shippingZone
      )
    ) {
      this.renderShippingOptions();
    }
    else if (
      !isEqual(prevState.filter.params.shippingOption, this.state.filter.params.shippingOption)
    ) {
      const prevShippingOption = prevState.filter.params.shippingOption;
      const shippingOption = this.state.filter.params.shippingOption;
      const addedItems = difference(shippingOption, prevShippingOption);
      const removedItems = difference(prevShippingOption, shippingOption);

      addedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-shipping-option-id="${val}"]`);
        if (!el) return;

        el.classList.add('active');
      });
      removedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-shipping-option-id="${val}"]`);
        if (!el) return;

        el.classList.remove('active');
      });
    }
  }

  handleItemClick = (e, value) => {
    e.stopPropagation();
    const { categoryId, params } = this.state.filter;
    const newParams = { ...params };

    EventEmitter.emit('shippingOptionClick', {
      categoryId,
      shippingOption: value,
    });

    if (!Array.isArray(newParams.shippingOption) || !newParams.shippingOption) newParams.shippingOption = [];

    if (newParams.shippingOption.includes(value)) {
      newParams.shippingOption = newParams.shippingOption.filter(s => s !== value);

      EventEmitter.emit('shippingOptionRemoved', {
        categoryId,
        shippingOption: value,
      });
    }
    else {
      newParams.shippingOption = [
        ...newParams.shippingOption,
        value,
      ];

      EventEmitter.emit('shippingOptionApplied', {
        categoryId,
        shippingOption: value,
      });
    }

    if (newParams.shippingOption.length === 0) delete newParams.shippingOption;

    this.setFilter({
      params: newParams,
    });
  }

  isOptionAvailable = (shippingOption) => {
    if (!this.state.search?.filters?.shippingZone) return true;

    const options = Object.values(this.state.search?.filters?.shippingZone).map(o => o.value);

    return options.includes(shippingOption.value);
  }

  renderShippingOptions() {
    const { params } = this.state.filter;
    this.ul.innerHTML = '';

    shippingOptions.forEach((shippingOption, refIndex) => {
      if (!this.isOptionAvailable(shippingOption)) return;

      const li = document.createElement('li');
      li.innerHTML = `${shippingOption.label}`;
      li.setAttribute('data-shipping-option-id', shippingOption.value);
      li.setAttribute('data-shipping-option-ref-index', refIndex);
      this.ul.appendChild(li);

      if (params.shippingOption && params.shippingOption.includes(shippingOption.value)) {
        li.classList.add('active');
      }

      li.addEventListener('click', (e) => this.handleItemClick(e, shippingOption.value));
    });
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new ShippingOptionsFilter(params);
};
