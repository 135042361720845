import Handlebars from 'handlebars';
import Widget from '../modules/Widget';

const { document } = window;

const DEFAULT_LAYOUT = `
  <select>
    {{#each items}}
      <option value="{{this.value}}">{{this.label}}</option>
    {{/each}}
  </select>
`;

class ProductsPerPage extends Widget {
  defaultParams = {
    containerClassName: '',
    items: [
      { label: '20 per page', value: 20 },
      { label: '40 per page', value: 40 },
      { label: '60 per page', value: 60 },
      { label: '80 per page', value: 80 },
    ],
    templates: {
      layout: DEFAULT_LAYOUT,
    },
  };

  constructor(params) {
    super(params);

    this.layoutTemplate = Handlebars.compile(this.params.templates.layout);

    const element = document.createElement('span');
    element.className = this.params.containerClassName;
    this.mainElement = element;
  }

  didMount() {
    this.updateSelected();

    this.mainElement.querySelector('select')
      .addEventListener('change', this.handleChange);
  }

  didUpdate(pretState) {
    if (pretState.filter.limit !== this.state.filter.limit) {
      this.updateSelected();
    }
  }

  handleChange = (e) => {
    const value = e.target.value;
    this.setFilter({
      offset: 0,
      limit: value,
    });
  }

  updateSelected() {
    const { limit } = this.state.filter;
    const optionToSelect = this.mainElement.querySelector(`option[value="${limit}"`);
    if (optionToSelect) {
      optionToSelect.selected = true;
    }
  }

  render() {
    this.mainElement.innerHTML = this.layoutTemplate({
      items: this.params.items,
    });
    return this.mainElement;
  }
}

export default (params) => {
  return new ProductsPerPage(params);
};
