import React from 'react'
import PropTypes from 'prop-types'
import { 
  CATEGORY_WTOP, CATEGORY_WSHOES, CATEGORY_WPANTS, CATEGORY_WBAG, CATEGORY_MBAG, CATEGORY_WWALLET, CATEGORY_MWALLET
} from '../../config/constants'
import { ThumbnailPicker, ThumbnailPickerOption } from '../../ui-kits/selects'
import MaterialCottonSvg from '../../assets/svg/material-cotton.svg'
import MaterialSilkSvg from '../../assets/svg/material-silk.svg'
import MaterialWrinkleFreeSvg from '../../assets/svg/material-wrinkle-free.svg'
import MaterialDenimSvg from '../../assets/svg/material-denim.svg'
import MaterialSpandexSvg from '../../assets/svg/material-spandex.svg'
import MaterialWoolSvg from '../../assets/svg/material-wool.svg'
import MaterialSuedeSvg from '../../assets/svg/material-suede.svg'
import MaterialLeatherSvg from '../../assets/svg/material-leather.svg'
import MaterialGlossySvg from '../../assets/svg/material-glossy.svg'
import MaterialFabricSvg from '../../assets/svg/material-fabric.svg'

const MaterialSelect = ({ name, values, category, onChange }) => (
  <ThumbnailPicker name={name} values={values} onChange={onChange} selectedStyle='half' canUnselect>
    {getOptions(category)}
  </ThumbnailPicker>
)

MaterialSelect.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.array,
  category: PropTypes.string,
  onChange: PropTypes.func
}

MaterialSelect.defaultProps = {
  onChange: (name, value) => {}
}
const materialDisplayConfig = {
  [CATEGORY_WTOP]: {
    'cotton': ['Cotton', MaterialCottonSvg],
    'silk': ['Silk', MaterialSilkSvg],
    'wrinkle-free': ['Wrinkle Free', MaterialWrinkleFreeSvg]
  },
  [CATEGORY_WPANTS]: {
    'denim': ['Denim', MaterialDenimSvg],
    'spandex': ['Spandex', MaterialSpandexSvg],
    'wool': ['Wool', MaterialWoolSvg],
  },
  [CATEGORY_WSHOES]: {
    'suede': ['Suede', MaterialSuedeSvg],
    'leather': ['Leather', MaterialLeatherSvg],
    'glossy': ['Glossy', MaterialGlossySvg],
    'fabric': ['Fabric', MaterialFabricSvg]
  },
  [CATEGORY_WBAG]: {
    'leather': ['Leather', MaterialLeatherSvg],
    'polyester': ['Polyester', MaterialGlossySvg], // TODO: Use proper image
    'textile': ['Textile', MaterialFabricSvg]
  },
  [CATEGORY_MBAG]: {
    'leather': ['Leather', MaterialLeatherSvg],
    'polyester': ['Polyester', MaterialGlossySvg], // TODO: Use proper image
    'textile': ['Textile', MaterialFabricSvg]    
  },
  [CATEGORY_WWALLET]: {
    'leather': ['Leather', MaterialLeatherSvg],
    'animalskin': ['Animal', MaterialSuedeSvg], // TODO: Use proper image
    'embossy': ['Embossy', MaterialWoolSvg] // TODO: Use proper image
  },
  [CATEGORY_MWALLET]: {
    'leather': ['Leather', MaterialLeatherSvg],
    'animalskin': ['Animal', MaterialSuedeSvg], // TODO: Use proper image
    'polyester': ['Polyester', MaterialGlossySvg] // TODO: Use proper image
  }
}

const getOptions = (category) => {
  if (category in materialDisplayConfig) {
    var pickers = []
    let matMap = materialDisplayConfig[category]
    for (var name in matMap) {
      let label = matMap[name][0]
      let svg = matMap[name][1]
      pickers.push(
        <ThumbnailPickerOption key={name} label={label} value={name}>
        <img src={svg} alt={label} />
        </ThumbnailPickerOption>
      )
    }
    return pickers
  }
  return []
}

export default MaterialSelect
