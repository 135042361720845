import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

// core-redux
import {
  setDesktopOnboarding,
  setFilter,
  setLastBodyPart,
  saveFilterAsPreset,
  deleteFilterFromPreset
} from '@yesplz/core-redux/ducks/filters'

// core-web
import {isFilterSavedSelector} from "@yesplz/core-web/modules/filters/selectors";
import {CUSTOM_PRESET_NAME} from "@yesplz/core-web/config/constants";
import arrow1 from '@yesplz/core-web/assets/svg/desktop-onboarding-1.svg'
import arrow2 from '@yesplz/core-web/assets/svg/desktop-onboarding-2.svg'
import arrow3 from '@yesplz/core-web/assets/svg/desktop-onboarding-3.svg'
import {
  CATEGORY_WPANTS,
  CATEGORY_WSHOES,
  CATEGORY_WTOP
} from '@yesplz/core-web/config/constants'

import './TutorialOnboard.scss'

const { localStorage } = window

class TutorialOnboard extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      step: 0
    }
  }

  nextStep = () => {
    const { setDesktopOnboarding } = this.props
    const { step } = this.state

    if (step === 2) {
      this.handleFilterLike()
      setDesktopOnboarding(false)
      localStorage.setItem('desktop_onboarded', 1)
    } else if (step < 2) {
      this.handleFilter()
    }

    this.setState({
      step: step + 1
    })
  }

  handleFilter = () => {
    const { activeCategory, filters, setFilter, setLastBodyPart, lastBodyPart } = this.props
    const newFilters = { ...filters }

    if (activeCategory === CATEGORY_WTOP) {
      newFilters['sleeve_length'] = newFilters['sleeve_length'] < 4 ? newFilters['sleeve_length'] + 1 : 0
      newFilters['shoulder'] = 3
      if (lastBodyPart !== 'sleeve_length') {
        setLastBodyPart('sleeve_length')
      }
    } else if (activeCategory === CATEGORY_WPANTS) {
      newFilters['ankle'] = newFilters['ankle'] < 3 ? newFilters['ankle'] + 1 : 0
      newFilters['knee'] = newFilters['ankle'] < 3 ? 1 : 0
      if (lastBodyPart !== 'ankle') {
        setLastBodyPart('ankle')
      }
    } else if (activeCategory === CATEGORY_WSHOES) {
      newFilters['shafts'] = newFilters['shafts'] < 4 ? newFilters['shafts'] + 1 : 0
      newFilters['covers'] = 2
      if (lastBodyPart !== 'shafts') {
        setLastBodyPart('shafts')
      }
    }

    setFilter(activeCategory, newFilters)
  }

  handleFilterLike = () => {
    const {
      activeCategory,
      saveFilterAsPreset,
      deleteFilterFromPreset,
      filters,
      isFilterSaved
    } = this.props

    const filtersWithCategory = {
      ...filters,
      category: activeCategory
    }

    if (!isFilterSaved) {
      saveFilterAsPreset(filtersWithCategory, CUSTOM_PRESET_NAME)
    } else {
      deleteFilterFromPreset(filtersWithCategory, CUSTOM_PRESET_NAME)
    }
  }

  render () {
    const { step } = this.state

    return (
      <React.Fragment>
        <div className='ProductsPage position-fixed'>
          <div className='ProductFilters' onClick={this.nextStep} />
          <div className='Desktop-section' />
          {step === 0 &&
            <div className='step step-1'>
              <img src={arrow1} alt='' />
              <div className='detail'>
                Click the diamond button. <br />
                to change the design.
              </div>
            </div>
          }
          {step === 1 &&
            <div className='step step-2'>
              <img src={arrow2} alt='' />
              <div className='detail'>
                Try the thumbnails as a<br />
                shortcut.
              </div>
            </div>
          }
          {step === 2 &&
            <div className='step step-3'>
              <img src={arrow3} alt='' />
              <div className='detail'>
                All Done! <br /> <br /> 
                Click here to save the style
              </div>
            </div>
          }
        </div>
        <div className='fixed-header' />
      </React.Fragment>
    )
  }
}

export default connect(
  (state, props) => ({
    activeCategory: props.category,
    filters: state.filters[props.category].data,
    isFilterSaved: isFilterSavedSelector(state, {
      category: props.category,
      customPresetName: CUSTOM_PRESET_NAME
    }),
    lastBodyPart: state.filters.lastBodyPart,
    router: state.router
  }),
  {
    setDesktopOnboarding,
    setFilter,
    setLastBodyPart,
    saveFilterAsPreset,
    deleteFilterFromPreset
  }
)(TutorialOnboard)
