import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';

export default (flayout) => {
  const toLowerCase = (categoryName, gender) => {
    const categoryCopy = flayout[gender][categoryName]
    delete flayout[gender][categoryName]
    flayout[gender][categoryName.toLowerCase()] = categoryCopy
  }

  const removeEmptySubCategory = ({ subCategories }) => {
    remove(subCategories, function (subCategory) {
      return isEmpty(subCategory)
    })
  }

  const forEachCategories = (gender) => {
    const categoriesNames = Object.keys(flayout[gender])
    categoriesNames.forEach((categoryName) => {
      toLowerCase(categoryName, gender)
      removeEmptySubCategory({
        subCategories: flayout[gender][categoryName.toLowerCase()]
      })
    })
  }

  const genders = Object.keys(flayout)

  genders.forEach((gender) => {
    forEachCategories(gender)
  })

  return flayout
}
