import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import { findLabelByLng } from '@yesplz/core';
import client from '@yesplz/client';
import Widget from '../modules/Widget';

const CONTAINER_CLASS_NAME = `yesplz-brand-filter`;

function convertList(list = {}) {
  return Object.values(list).map(({ value }) => ({ label: value, value: value }));
}

class MaterialTextList extends Widget {
  defaultParams = {
    fitText: false,
    fitTextMaxSize: 15,
    showResetButton: false,
  }

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
  }

  didMount() {
    this.renderItems();
  }

  didUpdate(prevState) {
    if (!isEqual(convertList(prevState.search?.filter?.materials), convertList(this.state.search?.filter?.materials))) {
      this.renderItems();
    }
    else if (
      !isEqual(prevState.search.materials, this.state.filter.materials)
    ) {
      const materialText = this.state.filter.materials;
      const prevMaterialText = prevState.filter.materials;

      const selected = difference(materialText, prevMaterialText);
      const deselected = difference(prevMaterialText, materialText);

      selected.forEach(materialText => {
        const button = this.element.querySelector(`[data-material-text="${materialText}"]`);
        if (button) button.classList.add('is-selected');
      });
      deselected.forEach(materialText => {
        const button = this.element.querySelector(`[data-material-text="${materialText}"]`)
        if (button) button.classList.remove('is-selected');
      });
    }
  }

  get indexId() {
    const { categoryId, categorySlice, params } = this.state.filter;
    const { subcategory } = params;
    return (subcategory && subcategory.length ? `${subcategory.join('|')}` : categoryId) + (categorySlice ? `*${categorySlice}` : '');
  }

  async fetchAndRender() {
    const { searchAdditionalParams } = this.state.config;
    const id = this.indexId;
    if (!this.materialsByCategory[id]) {
      const data = await client.materialsByCategory(id, searchAdditionalParams);
      this.materialsByCategory[id] = data;
    }

    this.renderItems();
  }

  handleLabelClick = (e) => {
    const button = e.target;
    const newMaterialText = button.getAttribute('data-material-text');
    this.main.toggleList('materials', newMaterialText);
  }

  renderItems() {
    const { lng } = this.state.config;
    const materials = convertList(this.state.search?.filter?.materials);

    this.element.innerHTML = '';
    console.log('materials', materials);

    if (materials.length) {
      this.element.className = CONTAINER_CLASS_NAME;
      this.element.innerHTML = '';

      const materialsHtml = materials.map(({ label, value }) => {
        const className = (this.state.filter.materials || []).includes(value) ? ` class="is-selected"` : '';
        return `
          <li data-material-text="${value}"${className}>
            ${findLabelByLng(label, lng)}
          </li>
        `;
      });

      const html = `
        <ul>
          ${materialsHtml.join('')}
        </ul>
      `;

      this.element.insertAdjacentHTML('beforeend', html);

      this.element.querySelectorAll('[data-material-text]').forEach(button => {
        button.addEventListener('click', this.handleLabelClick);
      });
    }

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(materials);
    }
  }

  render() {
    this.renderItems();
    return this.element;
  }
}

export default (params) => {
  return new MaterialTextList(params);
};
