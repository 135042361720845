import Handlebars from 'handlebars';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import { formatCurrency } from '@yesplz/core';

const { document } = window;

const nothingFoundTemplate = `
<h2>Nothing found!</h2>
`;

const customItemTemplate = `<div><h3>Custom Item</h3></div>`;

const itemTemplate = `
<a href="{{srcUrl}}" target="{{linkTarget}}" class="ProductGrid">
  <div class="ProductGrid-thumbnail">
    <img src="{{frontImg}}" alt="{{brand}}" />
  </div>
  <div class="ProductGrid-detail">
    <h5>{{name}}</h5>
    {{{priceHtml}}}
  </div>
  {{#if customFields.likes}}
    <span>{{customFields.likes}}</span>
  {{/if}}
</a>
`;

const itemPriceTemplate = `<div class="ProductGrid-price">{{price}}</div>`;
const itemDiscountPriceTemplate = `
<div class="ProductGrid-price sale">{{price}}</div>
<div class="ProductGrid-originalPrice">{{originalPrice}}</div>
`;

const topMatchesTitleTemplate = `
<li class="text-item">
  <h2>
    Top search results
  </h2>
</li>
`;

const nextBestMatchingTitleTemplate = `
<li class="text-item">
  <h2>
    {{#if count}}
      How about the next best matches?
    {{else}}
      We couldn’t find the exact matches, but these items might interest you.
    {{/if}}
  </h2>
</li>
`;

const loadingBarTemplate = `
  <div id="loading-bar" class="loading-mask" data-role="loader" style="display: none;"><div class="loader"><img alt="Loading..." src="https://us.wconcept.com/static/version1661520181/frontend/Corra/wconcept/en_US/images/loader-2.gif"><p>Please wait...</p></div></div>
`

class ProductsList extends Widget {
  defaultParams = {
    imgBaseURL: '',
    containerClassName: 'ProductList-wrapper',
    rootTag: 'div',
    scrollContainer: window,
    productUrl: null,
    productLinkTarget: '_self',
    /**
     * Available options are:
     * - perRow: @number
     * - className: @string
     */
    row: false,
    displayNextBestMatchesTitle: true,
    templates: {
      customItem: customItemTemplate,
      item: itemTemplate,
      price: itemPriceTemplate,
      discountPrice: itemDiscountPriceTemplate,
      nextBestMatchingTitle: nextBestMatchingTitleTemplate,
      nothingFound: nothingFoundTemplate,
    },
    onRendered: null,
    modifyItemsBeforeRender: null,
    currency: {},
  };

  constructor(params) {
    super(params);

    const element = document.createElement(this.params.rootTag);
    element.className = this.params.containerClassName;
    this.mainElement = element;
  }

  didMount() {
    this.customItemTemplate = Handlebars.compile(this.params.templates.customItem);
    this.itemTemplate = Handlebars.compile(this.params.templates.item);
    this.itemPriceTemplate = Handlebars.compile(this.params.templates.price);
    this.itemDiscountPriceTemplate = Handlebars.compile(
      this.params.templates.discountPrice
    );
    this.nextBestMatchingTitleTemplate = Handlebars.compile(
      nextBestMatchingTitleTemplate,
      // this.params.templates.nextBestMatchingTitle
    );
    this.topMatchesTitleTemplate = Handlebars.compile(
      topMatchesTitleTemplate,
      // this.params.templates.nextBestMatchingTitle
    );
    this.nothingFoundTemplate = Handlebars.compile(this.params.templates.nothingFound);

    this.scrollContainer = (
      typeof this.params.scrollContainer === 'string'
        ? document.querySelector(this.params.scrollContainer)
        : this.params.scrollContainer
    );

    this.renderProducts(this.state.search);
  }

  didUpdate(prevState) {
    if (prevState.search.isLoading && prevState.search.isLoading === !this.state.search.isLoading) {
      this.renderProducts(this.state.search);
    }
  }

  createLoadingBar() {
    this.container.insertAdjacentHTML('afterbegin', loadingBarTemplate);
    this.loadingBar = this.container.querySelector('#loading-bar');
  }

  showLoadingBar() {
    if (!this.loadingBar) {
      this.createLoadingBar();
    }
    this.loadingBar.style.display = 'block';
  }

  hideLoadingBar() {
    if (!this.loadingBar) {
      this.createLoadingBar();
    }
    this.loadingBar.style.display = 'none';
  }

  formatCurrency(number) {
    return formatCurrency(number,  this.params.currency);
  }

  getProductUrl(product) {
    if (typeof this.params.productUrl === 'function') {
      return this.params.productUrl(product);
    }
    return product.productUrl;
  }

  renderCustomItem(item) {
    const element = document.createElement('div');

    element.innerHTML = this.customItemTemplate(item);

    return element.firstElementChild;
  }

  renderItem(item) {
    const element = document.createElement('div');

    const discountValue = (
      item.salePrice !== item.originalPrice
        ? 100 - Math.ceil((item.salePrice / item.originalPrice) * 100)
        : ''
    );

    const priceHtml = (
      item.originalPrice && item.salePrice !== item.originalPrice
        ? this.itemDiscountPriceTemplate({
            price: this.formatCurrency(item.salePrice),
            originalPrice: this.formatCurrency(item.originalPrice),
            discountValue,
          })
        : item.salePrice
          ? this.itemPriceTemplate({ price: this.formatCurrency(item.salePrice) })
          : ''
    );

    element.innerHTML = this.itemTemplate({
      ...item,
      srcUrl: this.getProductUrl(item),
      linkTarget: this.params.productLinkTarget,
      frontImg: (
        item.frontImgSrc
          ? item.frontImgSrc
          : item.frontImg
            ? `${this.params.imgBaseURL}/${item.frontImg}`
            : ''
      ),
      brand: item.brand || item.brandName,
      name: item.productName,
      priceHtml,
    });

    element.querySelectorAll('a')?.forEach(a => {
      a.addEventListener('click', () => {
        EventEmitter.emit('txtProductListItemClicked', item.productId);
      });
    });

    return element.firstElementChild;
  }

  renderProducts(data) {
    this.mainElement.innerHTML = '';

    if (!data.results) return;

    const products = data.results;

    if (products && products.length > 0) {
      products.forEach((item, index) => {
        if (this.params.row && index % this.params.row.perRow === 0) {
          currentRow = document.createElement('DIV');
          this.mainElement.appendChild(currentRow);
          if (this.params.row.className)
            currentRow.className = this.params.row.className;
        }

        const container = this.params.row ? currentRow : this.mainElement;

        const element = item.product ? this.renderItem(item.product) : this.renderCustomItem(item.params);

        container.appendChild(element);
      });
    }
    else {
      this.mainElement.innerHTML = this.nothingFoundTemplate();
    }

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(data);
    }
  }

  render() {
    return this.mainElement;
  }
}

export default (params) => {
  return new ProductsList(params);
};
