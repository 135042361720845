import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import client from '@yesplz/client';
import Fuse from 'fuse.js';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const { document } = window;

class BrandFilter extends Widget {
  defaultParams = {
    title: 'Brands',
    displaySearchBar: false,
    displayHeaders: false,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.brandsByCategories = {};

    this.fuse = new Fuse([], {
      includeScore: true,
      includeMatches: true,
      threshold: 0.4,
      keys: ['label'],
    });

    this.ul = document.createElement('ul');
    this.searchInput = null;
  }

  didMount() {
    if (this.params.filterBy === 'resultFilters') {
      this.renderResultBrands();
    }
    else {
      this.fetchAndRenderBrands();
    }
  }

  didUpdate(prevState) {
    if (
      this.params.filterBy === 'resultFilters'
      &&
      !isEqual(
        prevState.search?.filters?.brandName,
        this.state.search?.filters?.brandName
      )
    ) {
      this.renderResultBrands();
    }
    else if (this.params.filterBy !== 'resultFilters' && (
      prevState.filter.categoryId !== this.state.filter.categoryId
      ||
      !isEqual(prevState.filter.params.subcategory, this.state.filter.params.subcategory)
      ||
      prevState.filter.categorySlice !== this.state.filter.categorySlice
    )) {
      this.fetchAndRenderBrands();
    }
    else if (
      !isEqual(prevState.filter.params.brands, this.state.filter.params.brands)
    ) {
      const prevBrands = prevState.filter.params.brands;
      const brands = this.state.filter.params.brands;
      const addedItems = difference(brands, prevBrands);
      const removedItems = difference(prevBrands, brands);
      addedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-brand-id="${val}"]`);
        if (!el) return;

        el.classList.add('active');
      });
      removedItems.forEach(val => {
        const el = this.mainElement.querySelector(`[data-brand-id="${val}"]`);
        if (!el) return;

        el.classList.remove('active');
      });

      this.renderSelectedBrands();
    }
  }

  handleItemClick = (e, brand) => {
    e.stopPropagation();
    const { categoryId, params } = this.state.filter;

    EventEmitter.emit('brandClick', {
      categoryId,
      brand: brand.brandsFilter,
    });

    let { brands = [] } = params;
    if (brands.includes(brand.brandsFilter)) {
      brands = brands.filter(b => b !== brand.brandsFilter)
      EventEmitter.emit('brandRemoved', {
        categoryId,
        brand: brand.brandsFilter,
      });
    }
    else {
      brands = [...brands, brand.brandsFilter];
      EventEmitter.emit('brandApplied', {
        categoryId,
        brand: brand.brandsFilter,
      });
    }

    this.setFilter({
      params: {
        ...params,
        brands,
      },
    });
  }

  get indexId() {
    const { categoryId, categorySlice, params } = this.state.filter;
    const { subcategory } = params;
    return (subcategory && subcategory.length ? `${subcategory.join('|')}` : categoryId) + (categorySlice ? `*${categorySlice}` : '');
  }

  get brands() {
    if (this.params.filterBy === 'resultFilters') {
      return this.state.search?.filters?.brandName
        ? Object.values(this.state.search?.filters?.brandName).map(b => ({ brandsFilter: b.value, label: b.value }))
        : [];
    }
    return this.brandsByCategories[this.indexId];
  }

  set brands(brands) {
    this.brandsByCategories[this.indexId] = brands;
  }

  async fetchAndRenderBrands() {
    const { searchAdditionalParams } = this.state.config;
    const id = this.indexId;
    if (!this.brands) {
      let data = [];
      try {
        data = await client.brandsByCategories(id, searchAdditionalParams);
      } catch (e) {
        console.log(`Brand fetching failed: ${id}`);
      }
      this.brands = Array.isArray(data) ? data : isObject(data) && !isEmpty(data) ? Object.values(data)[0] : data;
    }

    this.fuse.setCollection(this.brands);
    this.renderBrands(this.brands);
    this.renderSelectedBrands();
  }

  renderResultBrands() {
    this.fuse.setCollection(this.brands);
    this.renderBrands(this.brands);
    this.renderSelectedBrands();
  }

  resetSearch() {
    this.searchInput.value = '';
    this.renderBrands(this.brands);
  }

  search = (e) => {
    this.isTyping = true;

    const query = e.target.value;
    if (!query) {
      this.renderBrands(this.brands);
      return;
    }
    const found = this.fuse.search(query);
    const items = found.map(f => f.item);
    this.query = query;

    this.renderBrands(items, true);
  }

  removeBrand(brand) {
    const { categoryId, params } = this.state.filter;
    const { brands } = params;

    EventEmitter.emit('brandRemoved', {
      categoryId, brand,
    });

    this.setFilter({
      params: {
        ...params,
        brands: brands.filter(b => b !== brand),
      },
    });
  }

  renderSearchBar() {
    const form = document.createElement('form');
    const input = document.createElement('input');
    input.placeholder = `Search brands`;
    form.appendChild(input);
    this.mainElement.appendChild(form);

    this.searchInput = input;

    input.addEventListener('keyup', this.search);
  }

  renderSelectedBrands() {
    const { brands } = this.state.filter.params;

    this.selected.innerHTML = '';

    if (!brands) return;

    brands.forEach(brand => {
      const element = document.createElement('span');
      element.innerHTML = brand;
      this.selected.appendChild(element);

      element.addEventListener('click', () => this.removeBrand(brand));
    });
  }

  renderBrands(brands, isSearch = false) {
    const { params: { brands: currentBrands = [] } } = this.state.filter;
    let displayHeaders = this.params.displayHeaders;

    this.ul.innerHTML = '';

    if (!brands) return;

    if (isSearch || brands.length <= 20) displayHeaders = false;

    let currentLetter = null;
    brands.forEach((brand, refIndex) => {
      const nextLetter = (
        !isNaN(parseInt(brand.label[0], 10)) || brand.label[0].match(/^[^a-zA-Z0-9]+$/)
          ? '#'
          : brand.label[0]
      );
      if (displayHeaders && currentLetter !== nextLetter.toUpperCase()) {
        currentLetter = nextLetter.toUpperCase();
        const li = document.createElement('li');
        li.classList.add('header');
        li.innerHTML = `<span>${currentLetter}</span>`;
        this.ul.appendChild(li);
      }

      const li = document.createElement('li');
      li.innerHTML = `<span>${brand.label}</span>`;
      li.setAttribute('data-brand-id', brand.brandsFilter);
      li.setAttribute('data-brand-ref-index', refIndex);
      this.ul.appendChild(li);

      if (currentBrands.includes(brand.brandsFilter)) {
        li.classList.add('active');
      }

      li.addEventListener('click', (e) => this.handleItemClick(e, brand));
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(brands);
    }
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);

    this.renderSearchBar();

    this.ul = document.createElement('ul');
    this.selected = document.createElement('div');
    this.selected.classList.add('brands-selected');

    this.mainElement.appendChild(this.selected);
    this.mainElement.appendChild(this.ul);

    return this.mainElement;
  }
}

export default (params) => {
  return new BrandFilter(params);
};
