import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import SidebarItem from './SidebarItem'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { VisualFilter } from 'modules/visual-filter'
import { CATEGORY_WTOP, CATEGORY_WSHOES, CATEGORY_WPANTS } from '@yesplz/core-web/config/constants'
import {
  StylesSelect, DesignSelect, MaterialSelect, FavoritesSelect, AdvancedFilterHandler
} from '@yesplz/core-web/modules/advanced-filters'
import ColorPallete from '@yesplz/core-web/modules/filters/ColorPallete'
import { Tabs, Tab } from '@yesplz/core-web/ui-kits/tabs'
import SidebarSecondaryFilters from './SidebarSecondaryFilters'
import './SidebarProductsFilter.scss'

class SidebarProductsFilter extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      config: undefined,
      categoryChanged: false,
      viewConfig: false,
      scrolled: false
    }
    this.visualFilter = null
  }

  setVisualFilter = visualFilter => {
    this.visualFilter = visualFilter
    this.setState({
      config: get(visualFilter, 'catdata.catcfg')
    })
  }

  componentDidMount () {
    this.setState({ viewConfig: this.props.viewConfig })
  }

  componentDidUpdate (prevProps, prevState) {
    const { category, viewConfig } = this.props

    if (!this.state.scrolled) {
      this.checkScroll()
    }

    if (prevProps.viewConfig !== viewConfig) {
      this.setState({ viewConfig: this.props.viewConfig })
    }

    if (!prevState.categoryChanged && category !== prevProps.category) {
      this.setState({categoryChanged: true})
      this.setState({viewConfig: false})
      setTimeout(() => {
        this.setState({categoryChanged: false})
      }, 100)
    }
  }

  checkScroll = () => {
    setTimeout(() => {
      const filter = document.querySelector('.SidebarProductsFilter-wrap-active')
      if (filter && filter.offsetTop !== 0) document.querySelector('.scrollbar-container').scrollTo(0, filter.offsetTop)
    }, 0)
}

  handleAdvancedFilterChange = (advancedFilters) => {
    const { filters, onFilterChange } = this.props
    onFilterChange({ ...filters, ...advancedFilters })
  }

  handlePresetPrev = () => {
    if (this.visualFilter) {
      this.visualFilter.moveToNextPreset(true)
    }
  }

  handleViewConfig = () => {
    if (this.state.viewConfig) {
      this.setState({viewConfig: false})
    } else {
      this.setState({viewConfig: true})
    }
  }

  handlePresetNext = () => {
    if (this.visualFilter) {
      this.visualFilter.moveToNextPreset()
    }
  }

  handleWrapClick = (category) => (e) => {
    const { handleChangeActiveCategory } = this.props

    e.preventDefault()

    handleChangeActiveCategory(category)
  }

  render () {
    const { category, lastBodyPart, filters, cats = [] } = this.props
    const { config, categoryChanged, viewConfig } = this.state

    const styleVisual = {
      position: 'relative',
      top: 0,
      padding: 0,
      border: 0
    }

    const styleTabsContent = {
      padding: '25px 0'
    }

    const styleTabsHeader = {
      position: 'relative'
    }

    return (
      <PerfectScrollbar className='SidebarProductsFilter' style={{ height: '100%' }}>
        <Tabs
          tabs={tabs}
          className='SidebarProductsFilter-tabs'
          style={styles.tabs}
          styleTabsContent={styleTabsContent}
          styleTabsHeader={styleTabsHeader}
        >
          <Tab tabKey='visual-filter' style={{ padding: 0 }}>
            {
              cats.map((_category, key) => <div className={
                category === _category
                  ? `SidebarProductsFilter-wrap-active`
                  : `SidebarProductsFilter-wrap`
              } onClick={this.handleWrapClick(_category)} key={key}>
                {!categoryChanged && (
                  <VisualFilter
                    isUnique={_category}
                    activeCategory={_category}
                    onPrev={this.handlePresetPrev}
                    onNext={this.handlePresetNext}
                    onInit={
                      category === _category ? this.setVisualFilter : () => {}
                    }
                    styleNewArray={styleVisual}
                  />
                )}
                <button className={`SidebarProductsFilter__btn ${category === _category ? 'active' : ''} ${viewConfig ? 'open' : ''}`} onClick={this.handleViewConfig}>Advanced filter</button>
                {category === _category && viewConfig ? (
                  <AdvancedFilterHandler
                    filters={filters}
                    onChange={this.handleAdvancedFilterChange}
                    render={(categorizedFilters, handleFilterChange) => (
                      <React.Fragment>
                        <StylesSelect
                          name='style'
                          value={categorizedFilters.style}
                          category={_category}
                          lastBodyPart={lastBodyPart}
                          config={config}
                          adaptiveHeight
                          onChange={handleFilterChange}
                        />
                        {/*<div className='separator' style={{ marginTop: 30 }} />*/}

                        <SidebarItem title='Design'>
                          <DesignSelect name='design' category={_category} value={categorizedFilters.design} onChange={handleFilterChange} />
                        </SidebarItem>
                        <SidebarItem title='Colors'>
                          <ColorPallete values={categorizedFilters.color} onColorClick={(values) => handleFilterChange('color', values)} />
                        </SidebarItem>
                        <SidebarItem title='Materials'>
                          <MaterialSelect name='material' category={_category} values={categorizedFilters.material} onChange={handleFilterChange} />
                        </SidebarItem>
                        <SidebarItem title={`My ${categoryTitles[category]}`}>
                          <FavoritesSelect name='favorite' category={_category} value={filters} onChange={handleFilterChange} />
                        </SidebarItem>
                      </React.Fragment>
                    )}
                  />
                ) : null }
              </div>)
            }
          </Tab>
          <Tab tabKey='secondary-filter' style={{ padding: 0 }}>
            <SidebarSecondaryFilters activeCategory={category} redirect='/comingsoon' />
          </Tab>
        </Tabs>
      </PerfectScrollbar>
    )
  }
}

const categoryTitles = {
  [CATEGORY_WTOP]: 'Tops',
  [CATEGORY_WPANTS]: 'Pants',
  [CATEGORY_WSHOES]: 'Shoes'
}

const tabs = [
  { label: 'Visual Filter', key: 'visual-filter' },
  // { label: 'Other', key: 'secondary-filter' }
]

const styles = {
  tabs: {
    marginTop: 32
  }
}

SidebarProductsFilter.propTypes = {
  category: PropTypes.string.isRequired,
  lastBodyPart: PropTypes.string.isRequired,
  filters: PropTypes.object,
  cats: PropTypes.array,
  onFilterChange: PropTypes.func,
  viewConfig: PropTypes.bool
}

export default SidebarProductsFilter
