import React from 'react'
import PropTypes from 'prop-types'
import { BASE_IMG_PATH } from '@yesplz/core-web/config/constants'
import './ProductGridCorp.scss'

const ProductGridCorp = ({ imgSrc, brand, price }) => (
  <div className='product-grid-corp'>
    <div className='product-grid-corp__img-container'>
      <img src={`${BASE_IMG_PATH}/${imgSrc}`} />
    </div>
    <div className='product-grid-corp__text-container'>
      <h3 className='product-grid-corp__brand'>{brand}</h3>
      {/* <div className='product-grid-corp__price'>{`$${price}`}</div> */}
    </div>
  </div>
)

ProductGridCorp.propTypes = {
  imgSrc: PropTypes.string,
  brand: PropTypes.string,
  price: PropTypes.number
}

export default ProductGridCorp
