import React from 'react';
import classnames from 'classnames';
import './FilterButtonsContainer.scss';

export default function FilterButtonsContainer({ align = "middle", buttonSize = 'small', noMargins = false, children }) {
  return (
    <div className={classnames(
      'FilterButtonsContainer',
      align,
      `${buttonSize}-buttons`,
      {
        'no-margins': noMargins,
      }
    )}>
      <div className="FilterButtonsContainer-right">
        { children }
      </div>
    </div>
  );
}
