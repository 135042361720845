import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import upperFirst from 'lodash/upperFirst';
import { categories } from 'config/categories';
import { Button } from '@yesplz/core-web/ui-kits/buttons'
import classnames from 'classnames';
import WomenPNG from 'assets/images/landing-intro.jpg'
import MenPNG from 'assets/images/men-landing-intro.png';
import { findLabelByLng } from '@yesplz/core';
import CategorySection from './CategorySection';
import styles from './Categories.module.scss';

export default function Categories({ match }) {
  const scrollBoxRef = useRef(null);
  const latestStyles = useSelector(({latestStyles}) => latestStyles);
  const gender = upperFirst(match.params.gender);

  return (
    <div id="MainTitle" className={classnames('Home', styles['Home'])} ref={scrollBoxRef}>
      <div className={styles['Landing-Block']}>
        <div className={styles['container-left']} />
        <div className={styles['container']}>
          <div className={styles['Landing-Block-left']}>
            <img src={gender === 'Women' ? WomenPNG : MenPNG} alt="" />
          </div>
          <div className={styles['Landing-Block-right']}>
            <div>
              <h2>FASHION FOR YOU</h2>
              <p>Get personalized recommendations</p>
              <span>We go above and beyond traditional product search experience. Go check out and let us know what you think.</span>
              <Button
                kind='secondary'
                to="mailto: hello@yesplz.ai"
              >
                Contact us
              </Button>
            </div>
          </div>
        </div>
        <div className={styles['container-right']} />
      </div>
      <div className={classnames('Home-section', styles['Categories'])}>
        <h2 className={styles['Categories-Title']}>ALL PRODUCT CATEGORIES</h2>
        <div className={styles['Categories-Sections']}>
          {Object.keys(categories)
            .filter(categoryId => findLabelByLng(categories[categoryId].label).includes(gender))
            .map(categoryId => (
            categories[categoryId]
            &&
            <CategorySection {...{
              key: categoryId,
              currentFilter: latestStyles[categoryId] || null,
              categoryId,
              config: categories[categoryId],
              title: findLabelByLng(categories[categoryId].label).replace(gender, ''),
              link: `/visualfilter/${categoryId}`,
            }} />
          ))}
        </div>
        <div className="CategorySection">
          <div className="CategorySection-VF"></div>
          <div className="CategorySection-Products">
            <Button kind='borderless' className="Button-backToTop" onClick={() => {
              scrollBoxRef.current.scrollTo(0,0);
            }}>
              Back to top
            </Button>
          </div>
        </div>
        {/* {isOnboardingCategoryRenderFinished && <Onboarding
          gender={gender}
          buttonElement={onboardingButtonRef.current}
        />} */}
      </div>
    </div>
  );
}
