import Handlebars from 'handlebars';
import Widget from '../modules/Widget';

const { document } = window;

class ParamLabels extends Widget {
  defaultParams = {
    containerClassName: 'ParamLabels',
    showPartNameFor: {},
    labels: {},
    templates: {
      label: '<span>{{label}}</span>',
    }
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName;
    this.mainElement = element;

    this.labelTemplate = Handlebars.compile(this.params.templates.label);
  }

  didUpdate(prevState) {
    if (
      this.state.filter.categoryId !== prevState.filter.categoryId
      ||
      this.state.config.layoutTitle !== prevState.config.layoutTitle
    ) {
      this.render();
    }
  }

  render() {
    const { params, categoryId } = this.state.filter;
    const category = this.main.categories[categoryId];
    Object.entries(params).forEach(([bodyPart, value], index) => {
      if (['color'].includes(bodyPart)) return;

      if (!category.tn[bodyPart]) return;

      // Assign Label prefix if necessary.
      let labelPrefix = '';
      if (
        this.params.showPartNameFor[categoryId]
        &&
        this.params.showPartNameFor[categoryId].includes(bodyPart)
      ) {
        const bodyPartLabel = category.partListLabels[
          category.partList.indexOf(bodyPart)
        ];
        labelPrefix = `${bodyPartLabel} - `;
      }

      // Generate value label. If custum label assigned
      // it will be used.
      let valueLabel = category.tn[bodyPart][value].label;
      if (
        this.params.labels[categoryId]
        &&
        this.params.labels[categoryId][bodyPart]
        &&
        this.params.labels[categoryId][bodyPart][value]
      ) {
        valueLabel = this.params.labels[categoryId][bodyPart][value];
      }

      const label = labelPrefix + valueLabel;

      this.mainElement.insertAdjacentHTML(
        'beforeend',
        this.labelTemplate({
          label
        })
      )
    });
    // this.mainElement.innerHTML = categoryId;
    return this.mainElement;
  }
}

export default (params) => {
  return new ParamLabels(params);
};
