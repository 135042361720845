import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import './Tabs.scss'

const Tabs = ({ tabs, children, className, style, styleTabsContent, styleTabsHeader }) => {
  const [ activeTab, setActiveTab ] = useState(tabs[0].key)

  const activeChild = React.Children.toArray(children).find(child => child.props.tabKey === activeTab)

  return (
    <div className={`Tabs ${className}`} style={style}>
      <PerfectScrollbar option={{ handlers: scrollbarHandlers }}>
        <ul className='Tabs-header' style={styleTabsHeader}>
          {
            tabs.map(tab => (
              <li
                key={tab.key}
                onClick={() => {
                  setActiveTab(tab.key)
                }}
                className={classNames({ 'is-active': tab.key === activeTab })}
              >
                {tab.label}
              </li>
            ))
          }
        </ul>
      </PerfectScrollbar>
      <div className='Tabs-content' style={styleTabsContent}>
        <PerfectScrollbar option={{ handlers: scrollbarHandlers }}>
          {activeChild}
        </PerfectScrollbar>
      </div>
    </div>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object
}

Tabs.defaultProps = {
  tabs: [],
  className: ''
}

const scrollbarHandlers = ['click-rail', 'drag-thumb', 'keyboard', 'touch']

export default Tabs
