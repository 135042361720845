import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Slider from 'react-slick'
// import ReactImageMagnify from 'react-image-magnify'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

// import { FloatButton } from '@yesplz/core-web/modules/filters'
import { toggleLikeProduct } from '@yesplz/core-redux/ducks/favoritesStorage'
// import { BASE_IMG_PATH } from '@yesplz/core-web/config/constants'
import { Button, LikeButton } from '@yesplz/core-web/ui-kits/buttons'
// import { withProductLike } from '../../hoc'
// import { WideSlider } from '@yesplz/core-web/ui-kits/sliders'
import { IS_MOBILE } from '../../config/constants'

// import ARROW_TOP from '../../assets/images/arrow-top.png'
// import ARROW_DOWN from '../../assets/images/arrow-down.png'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './product.scss'

const MAX_IMAGES_THUMBNAIL = 4

class Product extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    categoryId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    price: PropTypes.number,
    originalPrice: PropTypes.number,
    imgSrc: PropTypes.string.isRequired,
    extraImgs: PropTypes.array,
    thumbnailImgs: PropTypes.array,
    currency: PropTypes.string,
    favorite: PropTypes.bool,
    description: PropTypes.string,
    extraInfo: PropTypes.string,
    link: PropTypes.string,
    retailer: PropTypes.string,
    sizes: PropTypes.array,
    rawData: PropTypes.object,
    showArrows: PropTypes.bool,
    showDots: PropTypes.bool,
    tracker: PropTypes.object,
  }

  static defaultProps = {
    currency: '$',
    product: {},
    extraImgs: [],
    thumbnailImgs: [],
    sizes: [],
    showArrows: false,
    showDots: false
  }

  constructor (props) {
    super(props)
    this.state = {
      thumbnailPosition: 0,
      imageThumbnailSelected: props.thumbnailImgs[0] ? props.thumbnailImgs[0] : null,
      imagesThumbnail: props.thumbnailImgs.slice(0, MAX_IMAGES_THUMBNAIL)
    }
  }

  // componentDidMount () {
  //   const { tracker, id, brand } = this.props

  //   tracker.registerTrackLinks('#BuyNow', 'Buy Now Button', { product_id: id, brand: brand })
  // }

  componentWillReceiveProps (nextProps) {
    this.setState({
      imageThumbnailSelected: nextProps.thumbnailImgs[0] ? nextProps.thumbnailImgs[0] : null
    })
  }

  get sliderSettings () {
    const { showArrows, showDots } = this.props
    return {
      dots: showDots,
      arrows: showArrows,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }

  get toggleLike () {
    const { id, categoryId, toggleLikeProduct } = this.props
    return (e) => {
      e.preventDefault()
      toggleLikeProduct(categoryId, id)
    }
  }

  get isStrollEndThumbnail () {
    const { thumbnailImgs } = this.props
    return this.state.thumbnailPosition >= thumbnailImgs.length - MAX_IMAGES_THUMBNAIL
  }

  // eslint-disable-next-line camelcase
  renderSizes = (allSizes, sizes) => (
    <ul className='Product-sizes'>
      {allSizes && allSizes.map(size => (
        <li key={size} className={classNames({
          'size-available': sizes.indexOf(size) > -1
        })}>{size}</li>
      ))}
    </ul>
  )

  handleClickThumbail = imgSrc => () => {
    this.setState({
      imageThumbnailSelected: imgSrc
    })
  }

  handleScrollThumbnail = () => {
    const { thumbnailPosition } = this.state
    this.setState({
      thumbnailPosition: this.isStrollEndThumbnail ? 0 : thumbnailPosition + 1
    })
  }

  render () {
    const { imageThumbnailSelected, thumbnailPosition } = this.state

    const {
      id,
      name,
      description,
      brand,
      imgSrc,
      extraImgs,
      thumbnailImgs,
      price,
      originalPrice,
      currency,
      favorite,
      link,
      sizes,
      // eslint-disable-next-line react/prop-types
      all_sizes: allSizes,
      category = "wtop",
      history,
    } = this.props

    // sale is available if original price is different with price
    const isSale = originalPrice && originalPrice !== price
    const isOutOfStock = price === 0
    const sliderChildren = [
      ...renderExtraImages(extraImgs, name)
    ]

    let ProductMobile = (
      <React.Fragment>
        <div className='Product-images'>
          <div className='LikeButton-wrapper'>
            <LikeButton active={favorite} onClick={this.toggleLike} />
          </div>
          <Slider {...this.sliderSettings}>
            {sliderChildren}
          </Slider>
        </div>
        <div className='Product-detail'>
          <h3 dangerouslySetInnerHTML={{ __html: brand }} />
          {
            !isOutOfStock && (
              <div className='Product-pricing'>
                {isSale && <div className='Product-original-price'>{currency}{originalPrice}</div>}
                <div className={classNames('Product-price', { sale: isSale })}>{currency}{price}</div>
              </div>
            )
          }

          <h4 dangerouslySetInnerHTML={{ __html: name }} />

          {/* {retailer && <p className='Product-retailer'>from {retailer}</p>} */}

          <p dangerouslySetInnerHTML={{ __html: description }} className='Product-description' />

          {/* {!isOutOfStock && <p>Available Sizes:</p>} */}

          {
            this.renderSizes(allSizes, sizes)
          }

          {/* <h4 className={'Sizes-message'}>YOU WILL SEE IF YOUR SIZE IS AVAILABLE WHEN <a href=''>SIGN IN</a></h4> */}

        </div>
        <div className='Product-footer'>
          {isOutOfStock
            ? <p className='Product-out-of-stock'>Out of Stock</p>
            : <Button id='BuyNow' to={link}>Buy Now</Button>}
        </div>
        {/* <FloatButton category={category} onClick={() => {
          history.push(`/visualfilter/${category}`, {
            isVfOpen: true,
          })
        }} /> */}
      </React.Fragment>
    )

    let ProductDesktop = (
      <div className='ProductDetail-container'>
        <div className='ProductDetail-images'>
          <div className="Product-Slider">
            <Slider
              {...{
                dots: false,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                vertical: true,
                verticalSwiping: true,
                swipeToSlide: true,
                showArrows: true,
              }}
            >
              {
                thumbnailImgs.map((imgSrc, index) => {
                  return (
                    <div
                      key={index}
                      onClick={this.handleClickThumbail(imgSrc)}
                      className={classNames('Product-Slider-item', { active: imageThumbnailSelected === imgSrc })}
                    >
                      <img src={imgSrc} alt='' />
                    </div>
                  )
                })
              }
            </Slider>
          </div>
          <div className='Image-main-view'>
            <div className='LikeButton-wrapper'>
              <LikeButton active={favorite} onClick={this.toggleLike} />
            </div>
            <div className='Image-main-view-image'>
              <img src={imageThumbnailSelected} alt="" />
            </div>
            {/* <ReactImageMagnify
              {...{
                smallImage: {
                  alt: name,
                  isFluidWidth: true,
                  // height: 570,
                  src: imageThumbnailSelected
                },
                largeImage: {
                  src: imageThumbnailSelected,
                  width: 750,
                  height: 1125
                },
                enlargedImageContainerStyle: {
                  border: '2px solid #2F30EB',
                  marginLeft: 60,
                }
              }}
            /> */}
          </div>
        </div>
        <div className='ProductDetail-info'>
          <div className='Product-detail'>
            <div>
              <h3 dangerouslySetInnerHTML={{ __html: name }} />
              <h4 dangerouslySetInnerHTML={{ __html: brand }} />
              {
                !isOutOfStock && (
                  <div className='Product-pricing'>
                    {isSale && <div className='Product-original-price'>{currency}{originalPrice}</div>}
                    <div className={classNames('Product-price', { sale: isSale })}>{currency}{price}</div>
                  </div>
                )
              }
              <p dangerouslySetInnerHTML={{ __html: description }} className='Product-description' />
              {
                this.renderSizes(allSizes, sizes)
              }
              {/* <h5 className={'Sizes-message'}>YOU WILL SEE IF YOUR SIZE IS AVAILABLE WHEN <a href=''>SIGN IN</a></h5> */}
            </div>
            <div className='Product-footer'>
              {isOutOfStock ? <p className='Product-out-of-stock'>Out of Stock</p> : <Button id='BuyNow' to={link}>BUY</Button>}
            </div>
          </div>
        </div>
      </div>
    )

    return (
      <div id={id} className='Product'>
        <div className='container-wide'>
          {
            IS_MOBILE ? ProductMobile : ProductDesktop
          }
        </div>
      </div>
    )
  }
}

export default connect(
  ({ favoritesStorage }, { categoryId, id }) => ({
    favorite: favoritesStorage.products[categoryId] && favoritesStorage.products[categoryId].includes(id),
  }),
  { toggleLikeProduct },
)(withRouter(Product));

const renderExtraImages = (imgs = []) => (
  imgs.map((imgSrc, index) => (
    <div key={index} className='Product-imageWrapper'>
      <div key={imgSrc} style={{ backgroundImage: `url(${imgSrc})` }} className='Product-image' />
    </div>
  ))
)
