
class Hooks {
  hooks = {};

  register(name, callback) {
    if (!Array.isArray(this.hooks[name])) {
      this.hooks[name] = [];
    }

    this.hooks[name].push(callback);
  }

  call(name, value) {
    let newValue = value;
    if (Array.isArray(this.hooks[name])) {
      for (const callback of this.hooks[name]) {
        newValue = callback(newValue);
      }
    }
    return newValue;
  }
}

const hooks = new Hooks();

export default hooks;
