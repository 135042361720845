import React from 'react'
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import store from './config/store'
import App from './App'
import reportWebVitals from './reportWebVitals';
import initializeAxios from '@yesplz/core-web/utils/initializeAxios'

initializeAxios()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
