import { categories } from './categories';
import flatten from 'lodash/flatten';

function getSectionedMenu() {
  const categoriesByBase = {};

  Object.values(categories).forEach((category) => {
    if (!categoriesByBase[category.baseCategory]) categoriesByBase[category.baseCategory] = [];
    categoriesByBase[category.baseCategory].push({
      categoryId: category.categoryId,
    })
  });

  function getMenuItems(baseCategories) {
    return flatten(baseCategories.map(baseCategory => categoriesByBase[baseCategory])).filter(v => v);
  }

  return [
    {
      label: 'Women',
      to: '/women',
      children: [
        {
          label: 'Clothing',
          children: getMenuItems([ 'wtop', 'wpullover', 'wcardigan', 'wouter', 'wpaddings', 'wcoats' ]),
        },
        {
          label: '&nbsp;',
          children: getMenuItems([ 'wpants', 'wpants2', 'wshorts', 'wskirt', 'wdress' ]),
        },
        {
          label: 'Shoes',
          children: getMenuItems([ 'wdressshoes', 'wsneakers' ]),
        },
        {
          label: 'Others',
          children: getMenuItems([ 'wbag', 'wwallet', 'wearring' ]),
        },
      ],
    },
    {
      label: 'Men',
      to: '/men',
      children: [
        {
          label: 'Clothing',
          children: getMenuItems([ 'mtshirts', 'mshirts', 'mouter', 'mpaddings', 'mcoats' ]),
        },
        {
          label: '&nbsp;',
          children: getMenuItems([ 'mpullover', 'mcardigan', 'mpants', 'mpants2', 'mshorts' ]),
        },
        {
          label: 'Shoes',
          children: getMenuItems([ 'mdressshoes', 'msneakers' ]),
        },
        {
          label: 'Others',
          children: getMenuItems([ 'mbag', 'mwallet' ]),
        },
      ],
    },
  ];
}

export default getSectionedMenu;
