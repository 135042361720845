import history from "history/browser";
import qs from 'qs';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import set from 'lodash/set';
import Hooks from './Hooks';

// let unlisten = history.listen(({ location, action }) => {
//   console.log(action, location.pathname, location.search, location.state);
// });

export { history };

export function startHistoryListener(callback) {
  history.listen(callback);
}

export function removeEmptyValuesFromObject(baseFilter) {
  const filter = cloneDeep(baseFilter);

  delete filter._persist;

  Object.keys(filter).forEach((key) => {
    if ((isEmpty(filter[key]) && isObject(filter[key])) || !filter[key]) {
      delete filter[key];
    }
    else if (isPlainObject(filter[key])) {
      filter[key] = removeEmptyValuesFromObject(filter[key]);
    }
  });

  return filter;
}

let prevFilter = null;

export function setPrevFilter(filter) {
  prevFilter = cloneDeep(filter);
}

function shortenQueryFilter(filter, bodyParts) {
  const newFilter = cloneDeep(filter);

  if (newFilter.params) {
    // remove body parts all values
    if (bodyParts) {
      bodyParts.forEach((bodyPart) => {
        if (newFilter.params[bodyPart] === 'all') {
          delete newFilter.params[bodyPart];
        }
      });
    }

    // rename params to p
    newFilter.p = newFilter.params;
    delete newFilter.params;
  }

  if (newFilter.bodyPart) {
    delete newFilter.bodyPart;
  }

  return newFilter;
}

export function unshortenQueryFilter(filter, bodyParts) {
  const newFilter = cloneDeep(filter);

  // rename p to params
  if (newFilter.p) {
    newFilter.params = newFilter.p;
    delete newFilter.p;
  }
  else if (!newFilter.params) {
    newFilter.params = {};
  }

  // fill in body parts all values
  if (bodyParts) {
    bodyParts.forEach((bodyPart) => {
      if (!newFilter.params[bodyPart]) {
        newFilter.params[bodyPart] = 'all';
      }
    });
  }

  return newFilter;
}

export function pushFilterToHistory(baseFilter, bodyParts) {
  // console.log('pushFilterToHistory baseFilter', JSON.stringify(baseFilter, null, 2));
  let filter = removeEmptyValuesFromObject(cloneDeep(baseFilter));
  // console.log('pushFilterToHistory filter', JSON.stringify(baseFilter, null, 2));
  if (!isEqual(prevFilter, filter)) {
    filter = Hooks.call('history.filterBeforePush', filter);
    const queryString = qs.stringify(shortenQueryFilter(filter, bodyParts), {
      encodeValuesOnly: true,
      arrayFormat: 'comma',
      allowDots: true,
      skipNulls: true,
    });
    setPrevFilter(filter);
    history.push(`${window.location.pathname}?${queryString}`);
  }
}

const URL_LIST_PARAMS = [
  'params.subcategory',
  'params.brands',
  'params.color',
  'params.design',
  'params.materialText',
  'params.vibe',
  'occasion',
  'params.size',
  'params.discount',
  'params.shippingOption',
];

const NUMBER_PARAMS = [
  'offset',
  'limit',
];

export function parseUrlState(categories) {
  // console.log(window.location.search);
  let filter = Hooks.call('history.parseUrlState', qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    allowDots: true,
    comma: true,
    interpretNumericEntities: true,
  }));
  // console.log('parseUrlState filter', JSON.stringify(filter, null, 2));
  const partList = categories[filter.categoryId]?.partList;
  filter = unshortenQueryFilter(filter, partList);

  URL_LIST_PARAMS.forEach(path => {
    const param = get(filter, path);
    if (param && typeof param === 'string') {
      set(filter, path, param.split(','));
    }
  });

  NUMBER_PARAMS.forEach(path => {
    const param = get(filter, path);
    if (param && typeof param === 'string') {
      set(filter, path, parseInt(param, 10));
    }
  });

  if (filter.params['price'] && /^[0-9.]*-[0-9.]*$/.test(filter.params['price'])) {
    let priceText = filter.params['price'];
    if (priceText.indexOf('-') === 0) {
      priceText = priceText.slice(1);
    }
    const prices = priceText.split('-');
    const from = parseInt(prices[0], 10);
    const to = parseInt(prices[1], 10);
    
    filter.params['price'] = `${!isNaN(from) && from && from > 0 ? from : ''}-${!isNaN(to) && to && to > 0 ? to : ''}`;
  }
  else {
    delete filter.params['price'];
  }

  return filter;
}
