export default {
  "wtop": {
    "label": "Women Top",
    "category": "wtop",
    "partList" : ["neckline", "shoulder", "sleeves", "waist", "length"],
    "defaultVal" : {
      "waist": "straight",
      "length": "long",
      "neckline": "flat",
      "shoulder": "closed",
      "sleeves": "long",
    },
    "vfSvgSource": require('../assets/svg/common/vf_wtop_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Women-Tops.svg'),
    "tn": {
      "length": {
        "cropped": { "label": "Cropped", "tnClass": "tn-4-0" },
        "short": { "label": "Short", "tnClass": "tn-4-1" },
        "long": { "label": "Long", "tnClass": "tn-4-2" }
      },
      "neckline": {
        "plunge": { "label": "Plunge", "tnClass": "tn-0-3" },
        "low": { "label": "Low", "tnClass": "tn-0-0" },
        "flat": { "label": "Flat", "tnClass": "tn-0-1" },
        "high": { "label": "High", "tnClass": "tn-0-2" },
        "square": { "label": "Square", "tnClass": "tn-0-5" }
      },
      "shoulder": {
        "open": { "label": "Open", "tnClass": "tn-1-0" },
        "thin": { "label": "Thin", "tnClass": "tn-1-1" },
        "wide": { "label": "Wide", "tnClass": "tn-1-2" },
        "closed": { "label": "Closed", "tnClass": "tn-1-3" }
      },
      "sleeves": {
        "sleeveless": { "label": "Sleeveless", "tnClass": "tn-2-0" },
        "short": { "label": "Short", "tnClass": "tn-2-1" },
        "half": { "label": "Half", "tnClass": "tn-2-2" },
        "three_quarters": { "label": "3/4", "tnClass": "tn-2-3" },
        "long": { "label": "Long", "tnClass": "tn-2-4" },
        "wide": { "label": "Wide", "tnClass": "tn-2-5" }
      },
      "waist": {
        "tight": { "label": "Tight", "tnClass": "tn-3-0" },
        "straight": { "label": "Straight", "tnClass": "tn-3-1" },
        "wide": { "label": "Loose", "tnClass": "tn-3-2" }
      }
    },
    "presetList": [
      {"neckline": "flat", "shoulder": "closed", "sleeves": "long", "waist": "straight", "length": "long"},
      {"neckline": "low", "shoulder": "closed", "sleeves": "short", "waist": "straight", "length": "long"},
      {"neckline": "low", "shoulder": "thin", "sleeves": "sleeveless", "waist": "wide", "length": "short"},
      {"neckline": "low", "shoulder": "closed", "sleeves": "half", "waist": "tight", "length": "cropped"},
      {"neckline": "plunge", "shoulder": "open", "sleeves": "long", "waist": "wide", "length": "short"}
    ],
    overrideBodyPartGroupName(state, prop, val) {
      if (prop === 'shoulder') {
        if (val === 'closed' && state['sleeve_length'] === 'sleeveless') {
          return 'shoulder_closed_for_sleeves_sleeveless'
        }
      }
      if (prop === 'length' && ['short', 'long'].includes(val)) {
        return 'length_' + val + '_waist_' + state['waist']
      }
      return null
    }
  },
  "wshoes": {
    "label": "Women Shoes",
    "category": "wshoes",
    "partList": ["bottoms","counters","covers","toes","shafts"],
    "defaultVal": {
      "toes": "pointy","covers": "low","shafts": "open","counters": "full",
      "bottoms": "stiletto","solid": 0,"pattern": 0,"details": 0,"color": []
    },
    "vfSvgSource": require('../assets/svg/common/vf_wshoes_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Women-Shoes.svg'),
    "presetList": [
      {"toes": "pointy","covers": "low","shafts": "open","counters": "full","bottoms": "stiletto"},
      {"toes": "open","covers": "low","shafts": "low","counters": "back","bottoms": "stiletto"},
      {"toes": "round","covers": "full","shafts": "open","counters": "full","bottoms": "wedge"},
      // {"toes": "pointy","covers": 2,"shafts": "ankles","counters": "full","bottoms": "kitten"},
      // {"toes": "pointy","covers": 2,"shafts": "knees","counters": "full","bottoms": "low_block"},
      // {"toes": "open","covers": 2,"shafts": "ankles","counters": "open","bottoms": "low_block"},
      {"toes": "open","covers": "low","shafts": "open","counters": "open","bottoms": "flat"}
    ],
    "tn": {
      "toes": {
        "open": {"label": "Open","tnClass": "tn-3-0"},
        "pointy": {"label": "Pointy","tnClass": "tn-3-1"},
        "round": {"label": "Round","tnClass": "tn-3-2"}
      },
      "bottoms": {
        "flat": {"label": "Flat","tnClass": "tn-0-0"},
        "wedge": {"label": "Wedge","tnClass": "tn-0-1"},
        "low_block": {"label": "Low Block","tnClass": "tn-0-2"},
        "high_block": {"label": "High Block","tnClass": "tn-0-3"},
        "kitten": {"label": "Kitten","tnClass": "tn-0-4"},
        "stiletto": {"label": "Stiletto","tnClass": "tn-0-5"}
      },
      "covers": {
        "low": {"label": "Low","tnClass": "tn-2-0"},
        "full": {"label": "Full","tnClass": "tn-2-1"}
      },
      "shafts": {
        "open": {"label": "Open","tnClass": "tn-4-0"},
        "low": {"label": "Low","tnClass": "tn-4-1"},
        "ankles": {"label": " Ankles","tnClass": "tn-4-2"},
        "calves": {"label": "Calves","tnClass": "tn-4-3"},
        "knees": {"label": "Knees","tnClass": "tn-4-4"}
      },
      "counters": {
        "open": {"label": "Open","tnClass": "tn-1-0"},
        "back": {"label": "Back","tnClass": "tn-1-1"},
        "full": {"label": "Full","tnClass": "tn-1-2"}
      }
    }
  },
  "wpants": {
    "label": "Women Pants",
    "category": "wpants",
    "partList" : ["rise", "thigh", "knee", "ankle"],
    "defaultVal" : {"rise": "low", "thigh": "tight", "knee": "tight", "ankle": "short"},
    "vfSvgSource": require('../assets/svg/common/vf_wpants_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Women-Pants.svg'),
    "tn" : {
      "rise": {
        "low": { "label": "Low", "tnClass": "tn-0-0" },
        "medium_high": { "label": "Medium/High", "tnClass": "tn-0-1" }
      },
      "thigh": {
        "short": { "label": "Short", "tnClass": "tn-1-0" },
        "tight": { "label": "Tight", "tnClass": "tn-1-1" },
        "wide": { "label": "Loose", "tnClass": "tn-1-2" }
      },
      "knee": {
        "short": { "label": "Short", "tnClass": "tn-2-0" },
        "tight": { "label": "Tight", "tnClass": "tn-2-1" },
        "wide": { "label": "Loose", "tnClass": "tn-2-2" }
      },
      "ankle": {
        "short": { "label": "Short", "tnClass": "tn-3-0" },
        "tight": { "label": "Tight", "tnClass": "tn-3-1" },
        "straight": { "label": "Straight", "tnClass": "tn-3-2" },
        "wide": { "label": "Wide", "tnClass": "tn-3-3" }
      }
    },
    "presetList": [
      {"rise": "low", "thigh": "tight", "knee": "tight", "ankle": "short"},
      {"rise": "medium_high", "thigh": "tight", "knee": "tight", "ankle": "straight"},
      {"rise": "medium_high", "thigh": "tight", "knee": "tight", "ankle": "tight"},
      {"rise": "medium_high", "thigh": "wide", "knee": "wide", "ankle": "short"},
      {"rise": "low", "thigh": "wide", "knee": "short", "ankle": "short"}
    ],
    sanitizePropChange (filters, prop, sel) {
      let thigh = filters['thigh']
      let knee = filters['knee']
      let ankle = filters['ankle']

      if (prop === 'thigh') {
        if (sel === 'short') { // Short pants. No knee, ankle
          filters['knee'] = 'short';
          filters['ankle'] = 'short';
        } else if (sel === 'tight') {
          if (knee === 'tight' && ankle === 'wide') { // Thigh 1, Knee 1, Ankle 3 is not possible combo
            filters['ankle'] = 'straight'
          } else if (knee === 'wide') {
            if (ankle === 'tight') { // Thigh 1, Knee 2, Ankle 1 is not possible combo
              filters['ankle'] = 'straight'
            }
          }
        }
      }
      if (prop === 'knee') {
        if (thigh === 'short' && sel !== 'short') {
          filters['thigh'] = 'tight'
        }
        if (sel === 'short') { // No knee means no ankle also
          filters['ankle'] = 'short'
        } else if (sel === 'tight' && ankle === 'wide') {
          filters['ankle'] = 'straight'
        } else if (thigh === 'tight' && sel === 'wide') {
          if (ankle === 'tight') { // Thigh 1, Knee 2, Ankle 1 is not possible combo
            filters['ankle'] = 'straight'
          }
        } else if (thigh === 'wide' && sel === 'wide') {
          if (ankle !== 'short') {
            filters['ankle'] = 'wide'
          }
        }
      }

      if (prop === 'ankle') {
        if (thigh === 'short' && sel !== 'short') {
          filters['thigh'] = 'tight'
        }
        if (knee === 'short' && sel !== 'short') {
          filters['knee'] = 'tight'
        }
        if (knee === 'tight' && sel === 'wide') {
          filters['knee'] = 'wide'
        }
        if (thigh === 'tight' && knee === 'wide' && sel === 'tight') {
          filters['knee'] = 'tight'
        }
        if (thigh === 'wide' && knee === 'wide' && (sel === 'tight' || sel === 'straight')) {
          filters['knee'] = 'tight'
        }
      }
      filters[prop] = sel
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'knee' && val !== 'short') {
        return 'knees_' + val + '_thigh_' + state['thigh']
      }
      if (prop === 'ankle' && val !== 'short') {
        return 'ankle_' + val + '_knees_' + state['knee'] + '_thigh_' + state['thigh']
      }
      return null
    }
  },
  "wskirt": {
    "label": "Women Skirt",
    "category": "wskirt",
    "partList": ["length","thigh","style"],
    "defaultVal": {"length": "knees","thigh": "tight", "style": "plain"},
    "presetList": [
      {"length": "knees","thigh": "tight","style": "plain"},
      {"length": "semi_long","thigh": "wide","style": "pleated"},
      {"length": "very_long","thigh": "wide","style": "slit"},
      {"length": "semi_long","thigh": "wide","style": "slit"},
      {"length": "short","thigh": "wide","style": "plain"}
    ],
    "vfSvgSource": require('../assets/svg/common/vf_wskirt_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Women-Skirts.svg'),
    "tn": {
      "length": {
        "short": {"label": "Short","tnClass": "tn-1-0"},
        "knees": {"label": "Knees","tnClass": "tn-1-1"},
        "semi_long": {"label": "Semi-Long","tnClass": "tn-1-2"},
        "very_long": {"label": "Very-Long","tnClass": "tn-1-3"}
      },
      "thigh": {
        "tight": {"label": "Tight","tnClass": "tn-2-0"},
        "wide": {"label": "Loose","tnClass": "tn-2-1"}
      },
      "style": {
        "plain": {"label": "None","tnClass": "tn-3-0"}, // Merged with slit, flare in Kolon
        "pleated": {"label": "Pleat","tnClass": "tn-3-1"},
        //"slit": {"label": "Slit","tnClass": "tn-3-2"},
        "ruffle": {"label": "Ruffle Hem","tnClass": "tn-3-3"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_thigh_' + state['thigh']
      }
      if (prop === 'style') {
        if (['pleated', 'ruffle'].includes(val)) {
          return 'style_' + val + '_length_' + state['length'] + '_thigh_' + state['thigh']
        } else {
          return 'style_' + val
        }
      }
      return null
    }
  },
  "wouter": {
    "label": "Women Outer",
    "category": "wouter",
    "partList": ["necktype","length","sleeves","waist"],
    "defaultVal": {"necktype": "notch","length": "half","waist": "tight","sleeves": "short"},
    "presetList": [
      {"necktype": "notch","sleeves": "short","waist": "tight","length": "half"},
      {"necktype": "notch","sleeves": "short","waist": "tight","length": "short"},
      {"necktype": "collar","sleeves": "short","waist": "wide","length": "half"},
      {"necktype": "collar","sleeves": "short","waist": "tight","length": "long"},
      {"necktype": "hoodie","sleeves": "short","waist": "wide","length": "half"}
    ],
    "vfSvgSource": require('../assets/svg/common/vf_wouter_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Women-Outer.svg'),
    "tn": {
      "necktype": {
        "notch": {"label": "Notch","tnClass": "tn-0-0"},
        "flat": {"label": "Flat","tnClass": "tn-0-1"},
        "collar": {"label": "Bal","tnClass": "tn-0-2"},
        "hoodie": {"label": "Hoodie","tnClass": "tn-0-3"},
        "high": {"label": "High","tnClass": "tn-0-4"}
      },
      "length": {
        "short": {"label": "Short","tnClass": "tn-1-0"},
        "half": {"label": "Half","tnClass": "tn-1-1"},
        "long": {"label": "Long","tnClass": "tn-1-2"}
      },
      "waist": {
        "tight": {"label": "Tight","tnClass": "tn-3-0"},
        "wide": {"label": "Loose","tnClass": "tn-3-1"}
      },
      "sleeves": {
        "sleeveless": {"label": "Sleeveless","tnClass": "tn-2-0"},
        "short": {"label": "Short","tnClass": "tn-2-1"},
        "long": {"label": "Long","tnClass": "tn-2-2"}
      }
    },
    "extraBodyGroups": ["sleeves_frame"],
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_waist_' + state['waist']
      }
      return null
    }
  },
  "wdress": {
    "label": "Women Dress",
    "category": "wdress",
    "partList": ["neckline","shoulder","waist","sleeve","length","style"],
    "defaultVal": {"neckline": "flat","shoulder": "closed","sleeve": "wrist","length": "short","waist": "tight","style": "plain"},
    "presetList": [
      {"neckline": "flat","shoulder": "full","sleeve": "wrist","waist": "tight","length": "short","style": "plain"},
      {"neckline": "low","shoulder": "full","sleeve": "wrist","waist": "tight","length": "very_long","style": "ruffle"},
      {"neckline": "low","shoulder": "full","sleeve": "wide","waist": "tight","length": "knees","style": "slit"},
      {"neckline": "low","shoulder": "open","sleeve": "wrist","waist": "tight","length": "semi_long","style": "pleated"},
      {"neckline": "flat","shoulder": "full","sleeve": "upper_arm","waist": "tight","length": "knees","style": "ruffle"}
    ],
    "vfSvgSource": require('../assets/svg/common/vf_wdress_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Women-Dress.svg'),
    "tn": {
      "neckline": {
        "low": {"label": "Open","tnClass": "tn-0-0"},
        "flat": {"label": "Flat","tnClass": "tn-0-1"},
        "high": {"label": "High","tnClass": "tn-0-2"}
      },
      "shoulder": {
        "open": {"label": "Open","tnClass": "tn-1-0"},
        "thin": {"label": "Thin","tnClass": "tn-1-1"},
        "wide": {"label": "Half","tnClass": "tn-1-2"},
        "closed": {"label": "Full","tnClass": "tn-1-3"}
      },
      "sleeve": {
        "none": {"label": "None","tnClass": "tn-3-0"},
        "upper_arm": {"label": "Upper-arm","tnClass": "tn-3-1"},
        "elbow": {"label": "Elbow","tnClass": "tn-3-2"},
        "wrist": {"label": "Wrist","tnClass": "tn-3-3"},
        "wide": {"label": "Loose","tnClass": "tn-3-4"}
      },
      "length": {
        "short": {"label": "Short","tnClass": "tn-4-0"},
        "knees": {"label": "Above knees","tnClass": "tn-4-1"},
        "semi_long": {"label": "Under knees","tnClass": "tn-4-2"},
        "very_long": {"label": "Maxi","tnClass": "tn-4-3"}
      },
      "waist": {
        "tight": {"label": "Tight","tnClass": "tn-2-0"},
        "wide": {"label": "Loose","tnClass": "tn-2-1"}
      },
      "style": {
        "plain": {"label": "None","tnClass": "tn-5-0"},
        "pleated": {"label": "Pleat","tnClass": "tn-5-1"},
        "slit": {"label": "Slit","tnClass": "tn-5-2"},
        "ruffle": {"label": "Ruffle Hem","tnClass": "tn-5-3"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'style' && val !== 'plain') {
        if (val === 'slit') {
          return 'style_slit'
        }
        return 'style_' + val + '_for_length_' + state['length']
      }
      return null
    }
  },
  "wknit": {
    "label": "Wommen Knit",
    "category": "wknit",
    "partList": ["cardigan","neckline","sleeves","waist","length"],
    "defaultVal": {"neckline": "flat","waist": "wide","length": "long","sleeves": "short","cardigan": "sweater"},
    "presetList": [
      {"sleeves": "short","neckline": "low","waist": "tight","cardigan": "sweater","length": "long"},
      {"neckline": "high","sleeves": "short","waist": "tight","cardigan": "sweater","length": "medium"},
      {"neckline": "high","sleeves": "sleeveless","waist": "tight","cardigan": "sweater","length": "medium"},
      {"neckline": "flat","sleeves": "short","waist": "tight","cardigan": "cardigan","length": "medium"},
      {"neckline": "low","sleeves": "short","waist": "tight","cardigan": "cardigan","length": "long"}
    ],
    "vfSvgSource": require('../assets/svg/common/vf_wknit_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Women-Knit.svg'),
    "tn": {
      "neckline": {
        "low": {"label": "Low","tnClass": "tn-0-0"},
        "flat": {"label": "Flat","tnClass": "tn-0-1"},
        "high": {"label": "High","tnClass": "tn-0-2"}
      },
      "waist": {
        "tight": {"label": "Tight","tnClass": "tn-1-0"},
        "wide": {"label": "Wide","tnClass": "tn-1-1"}
      },
      "length": {
        "short": {"label": "Short","tnClass": "tn-2-0"},
        "medium": {"label": "Medium","tnClass": "tn-2-1"},
        "long": {"label": "Long","tnClass": "tn-2-2"}
      },
      "sleeves": {
        "sleeveless": {"label": "Sleeveless","tnClass": "tn-3-0"},
        "short": {"label": "Short","tnClass": "tn-3-1"},
        "long": {"label": "Long","tnClass": "tn-3-2"}
      },
      "cardigan": {
        "sweater": {"label": "Sweater","tnClass": "tn-4-0"},
        "cardigan": {"label": "Cardigan","tnClass": "tn-4-1"}
      }
    }
  },
  "wearring": {
    "label": "Earrings",
    "category": "wearring",
    "partList": ["shape","size"],
    "defaultVal": {"size": "small","shape": "abstract"},
    "presetList": [
      {"shape": "abstract","size": "small"},
      {"shape": "ring","size": "small"},
      {"shape": "ring","size": "large"},
      {"shape": "ring","size": "small"},
      {"shape": "specific","size": "medium"}
    ],
    "vfSvgSource": require('../assets/svg/common/vf_wearring_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Women-Earrings.svg'),
    "tn": {
      "size": {
        "small": {"label": "small","tnClass": "tn-2-0"},
        "medium": {"label": "medium","tnClass": "tn-2-1"},
        "large": {"label": "large","tnClass": "tn-2-2"}
      },
      "shape": {
        "abstract": {"label": "Abstract","tnClass": "tn-0-0"},
        "specific": {"label": "Specific","tnClass": "tn-0-1"},
        "ring": {"label": "Ring","tnClass": "tn-0-2"},
        "dropdown": {"label": "Dropdown","tnClass": "tn-0-3"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'shape') {
        return 'shape_'+ val + '_size_' + state['size']
      }
      return null
    }
  },
  "mpants": {
    "label": "Men Pants",
    "category": "mpants",
    "partList" : ["thigh", "knee", "ankle"],
    "defaultVal" : {"thigh": "wide","knee": "wide","ankle": "long"},
    "vfSvgSource": require('../assets/svg/common/vf_mpants_core.svg'),
    "tn" : {
      "thigh": {
        "tight": { "label": "Tight", "icon": require('../assets/svg/common/tn-mpants-thigh-tight.svg') },
        "wide": { "label": "Wide", "icon": require('../assets/svg/common/tn-mpants-thigh-wide.svg') }
      },
      "knee": {
        "open": { "label": "Open", "icon": require('../assets/svg/common/tn-mpants-knee-open.svg') },
        "tight": { "label": "Tight", "icon": require('../assets/svg/common/tn-mpants-knee-tight.svg') },
        "wide": { "label": "Wide", "icon": require('../assets/svg/common/tn-mpants-knee-wide.svg') }
      },
      "ankle": {
        "short": { "label": "Short", "icon": require('../assets/svg/common/tn-mpants-ankle-short.svg') },
        "long": { "label": "Long", "icon": require('../assets/svg/common/tn-mpants-ankle-long.svg') }
      }
    },
    "presetList": [
      {"thigh": "wide", "knee": "wide", "ankle": "long"},
      {"thigh": "tight", "knee": "tight", "ankle": "short"},
      {"thigh": "tight", "knee": "tight", "ankle": "long"},
      {"thigh": "wide", "knee": "open", "ankle": "short"},
      {"thigh": "tight", "knee": "wide", "ankle": "long"}
    ],
    sanitizePropChange (filters, prop, new_value) {
      if (prop === 'thigh' && new_value === 'tight') {
        // Short pants. No knee, ankle
        filters['knee'] = 'tight'
      }
      if (prop === 'knee' && new_value === 'open') {
        // No knee means no ankle also
        filters['ankle'] = 'short'
      }
      if (prop === 'ankle' && new_value == 'long' && filters['knee'] === 'open') {
        filters['knee'] = 'tight'
      }
      filters[prop] = new_value
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'knee' && val !== 'open') {
        return 'knee_' + val + '_thigh_' + state['thigh']
      }
      if (prop === 'ankle' && val !== 'short') {
        return 'ankle_' + val + '_knee_' + state['knee'] + '_thigh_' + state['thigh']
      }
      return null
    }
  },
  "mshirts": {
    "label": "Men Shirts",
    "category": "mshirts",
    "partList": ["neckline","waist","sleeves","length"],
    "defaultVal": {"neckline": "camp","waist": "wide","length": "short","sleeves": "long"},
    "presetList": [
      {"neckline": "camp","sleeves": "long","waist": "wide","length": "short"},
      {"neckline": "large","sleeves": "long","waist": "tight","length": "long"},
      {"neckline": "henley","sleeves": "long","waist": "wide","length": "long"},
      {"neckline": "camp","sleeves": "short","waist": "wide","length": "short"},
      {"neckline": "henley","sleeves": "short","waist": "tight","length": "short"}
    ],
    "vfSvgSource": require('../assets/svg/common/vf_mshirts_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Men-Shirts.svg'),
    "tn": {
      "length": {
        "short": {"label": "Short","tnClass": "tn-3-0"},
        "long": {"label": "Long","tnClass": "tn-3-1"}
      },
      "waist": {
        "tight": {"label": "Tight","tnClass": "tn-1-0"},
        "wide": {"label": "Wide","tnClass": "tn-1-1"}
      },
      "sleeves": {
        "short": {"label": "Short","tnClass": "tn-2-0"},
        "long": {"label": "Long","tnClass": "tn-2-1"}
      },
      "neckline": {
        "short": {"label": "Short","tnClass": "tn-0-0"},
        "large": {"label": "Large","tnClass": "tn-0-1"},
        "henley": {"label": "Henley","tnClass": "tn-0-2"},
        "camp": {"label": "Camp","tnClass": "tn-0-3"}
      }
    },
    "extraBodyGroups": ["button"],
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_waist_' + state['waist']
      }
      return null
    }
  },
  "mknit": {
    "label": "Men Knit",
    "category": "mknit",
    "partList": ["neckline","cardigan","sleeves","waist"],
    "defaultVal": {"neckline": "flat","waist": "wide_long","sleeves": "long","cardigan": "sweater"},
    "presetList": [
      {"neckline": "flat","sleeves": "long","waist": "wide_long","cardigan": "sweater"},
      {"neckline": "low","sleeves": "long","waist": "tight_short","cardigan": "sweater"},
      {"neckline": "collar","sleeves": "long","waist": "tight_short","cardigan": "cardigan"},
      {"neckline": "low","sleeves": "long","waist": "tight_short","cardigan": "cardigan"},
      {"neckline": "flat","sleeves": "half","waist": "tight_short","cardigan": "sweater"}
    ],
    "vfSvgSource": require('../assets/svg/common/vf_mknit_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Men-Knit.svg'),
    "tn": {
      "neckline": {
        "low": {"label": "Low","tnClass": "tn-0-0"},
        "flat": {"label": "Flat","tnClass": "tn-0-1"},
        "collar": {"label": "Collar","tnClass": "tn-0-2"},
        "high": {"label": "High","tnClass": "tn-0-3"}
      },
      "waist": {
        "tight_short": {"label": "Tight-Short","tnClass": "tn-1-0"},
        "wide_long": {"label": "Wide-Long","tnClass": "tn-1-1"}
      },
      "sleeves": {
        "half": {"label": "Half","tnClass": "tn-2-0"},
        "long": {"label": "Long","tnClass": "tn-2-1"}
      },
      "cardigan": {
        "sweater": {"label": "Sweater","tnClass": "tn-3-0"},
        "cardigan": {"label": "Cardigan","tnClass": "tn-3-1"}
      }
    }
  },
  "mouter": {
    "label": "Men Outer",
    "category": "mouter",
    "partList": ["neckline", "waist", "sleeves", "length"],
    "defaultVal": {"neckline": "low","sleeves": "long","waist": "tight","length": "short"},
    "presetList": [
      {"neckline": "low","sleeves": "long","waist": "tight","length": "short"},
      {"neckline": "collar","sleeves": "long","waist": "wide","length": "short"},
      {"neckline": "high","sleeves": "long","waist": "wide","length": "short"},
      {"neckline": "collar","sleeves": "long","waist": "tight","length": "long"},
      {"neckline": "hood","sleeves": "long","waist": "wide","length": "medium"}
    ],
    "vfSvgSource": require('../assets/svg/common/vf_mouter_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Men-Outer.svg'),
    "tn": {
      "length": {
        "short": {"label": "Short","tnClass": "tn-3-0"},
        "medium": {"label": "Medium","tnClass": "tn-3-1"},
        "long": {"label": "Long","tnClass": "tn-3-2"}
      },
      "waist": {
        "tight": {"label": "Tight","tnClass": "tn-1-0"},
        "wide": {"label": "Wide","tnClass": "tn-1-1"}
      },
      "neckline": {
        "low": {"label": "Low","tnClass": "tn-0-0"},
        "flat": {"label": "Round","tnClass": "tn-0-1"},
        "collar": {"label": "Collar","tnClass": "tn-0-2"},
        "hood": {"label": "Hood","tnClass": "tn-0-3"},
        "high": {"label": "High","tnClass": "tn-0-4"}
      },
      "sleeves": {
        "sleeveless": {"label": "Sleeveless","tnClass": "tn-2-0"},
        "long": {"label": "Long","tnClass": "tn-2-2"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_waist_' + state['waist']
      }
      if (prop === 'sleeves' && val === 'long') {
        return 'sleeves_long_waist_' + state['waist']
      }
      return null
    }
  },
  "mshoes": {
    "label": "Men Shoes",
    "category": "mshoes",
    "partList": ["toes","neck","sole","toe_style"],
    "defaultVal": {"toes": "round","sole": "heel","neck": "full","toe_style": "cap"},
    "presetList": [
      {"toes": "round","neck": "full","sole": "heel","toe_style": "cap"},
      {"toes": "apron","neck": "low","sole": "heel","toe_style": "plain"},
      {"toes": "pointy","neck": "low","sole": "heel","toe_style": "plain"},
      {"toes": "pointy","neck": "low","sole": "solid","toe_style": "plain"},
      {"toes": "round","neck": "high","sole": "heel","toe_style": "plain"}
    ],
    "vfSvgSource": require('../assets/svg/common/vf_mshoes_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Men-Shoes.svg'),
    "tn": {
      "toes": {
        "pointy": {"label": "Pointy","tnClass": "tn-0-0"},
        "round": {"label": "Round","tnClass": "tn-0-1"},
        "apron": {"label": "Apron","tnClass": "tn-0-2"},
        "open": {"label": "Open","tnClass": "tn-0-3"}
      },
      "toe_style": {
        "plain": {"label": "Plain","tnClass": "tn-3-0"},
        "cap": {"label": "Cap","tnClass": "tn-3-1"},
        "wingtip": {"label": "Wingtip","tnClass": "tn-3-2"},
        "medallion": {"label": "Medallion","tnClass": "tn-3-3"}
      },
      "sole": {
        "heel": {"label": "Heel","tnClass": "tn-2-0"},
        "solid": {"label": "Solid","tnClass": "tn-2-1"},
        "chunky": {"label": "Chunky","tnClass": "tn-2-2"}
      },
      "neck": {
        "low": {"label": "Low","tnClass": "tn-1-0"},
        "full": {"label": "Full","tnClass": "tn-1-1"},
        "high": {"label": "High","tnClass": "tn-1-2"},
        "long": {"label": "Long","tnClass": "tn-1-3"}
      }
    },
    "extraBodyGroups": ["shadow"],
    sanitizePropChange (filters, prop, sel) {
      let toes = filters['toes']
      if (prop === 'neck' && toes === 'open') {
        filters['toes'] = 'round'
      }
      if (prop === 'toes' && (sel === 'apron' || sel === 'open')) {
        filters['toe_style'] = 'plain'
      }
      if (prop === 'toe_style' && (toes == 'apron' || toes == 'open')) {
        sel = 'plain' // Only plain can be selected for Apron & Open toe style
      }
      filters[prop] = sel
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'sole') {
        return 'sole_' + val + '_toe_' + state['toes']
      }
      return null
    }
  },
  "mtshirts": {
    "label": "Men T-shirts",
    "category": "mtshirts",
    "partList": ["neckline","sleeves","waist"],
    "defaultVal": {"waist": "straight","neckline": "flat","sleeves": "sleeveless"},
    "presetList": [
      {"neckline": "collar","sleeves": "sleeveless","waist": "straight"},
      {"neckline": "hood","sleeves": "short","waist": "wide"},
      {"neckline": "flat","sleeves": "short","waist": "straight"},
      {"neckline": "flat","sleeves": "sleeveless","waist": "tight"},
      {"neckline": "flat","sleeves": "short","waist": "wide"}
    ],
    "vfSvgSource": require('../assets/svg/common/vf_mtshirts_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Men-Tshirts.svg'),
    "tn": {
      "waist": {
        "tight": {"label": "Tight","tnClass": "tn-2-0"},
        "straight": {"label": "Straight","tnClass": "tn-2-1"},
        "wide": {"label": "Wide","tnClass": "tn-2-2"}
      },
      "sleeves": {
        "sleeveless": {"label": "Sleeveless","tnClass": "tn-1-0"},
        "short": {"label": "Short","tnClass": "tn-1-1"},
        "long": {"label": "Long","tnClass": "tn-1-2"}
      },
      "neckline": {
        "flat": {"label": "Flat","tnClass": "tn-0-0"},
        "collar": {"label": "Polo","tnClass": "tn-0-1"},
        "hood": {"label": "Hood","tnClass": "tn-0-2"},
        "high": {"label": "High","tnClass": "tn-0-3"}
      }
    }
  },
  "wwallet": {
    "label": "Women Wallet",
    "category": "wwallet",
    "partList": ["size","closure"],
    "defaultVal": {"size": "medium","closure": "envelope"},
    "presetList": [
      {"size": "medium","closure": "envelope"},
      {"size": "tall","closure": "zipper"},
      {"size": "large","closure": "zipper"},
      {"size": "small","closure": "pocket"},
      {"size": "tall","closure": "envelope"}
    ],
    "vfSvgSource": require('../assets/svg/common/vf_wwallet_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Women-Wallets.svg'),
    "tn": {
      "size": {
        "small": {"label": "Small","tnClass": "tn-0-0"},
        "medium": {"label": "Medium","tnClass": "tn-0-1"},
        "tall": {"label": "Tall","tnClass": "tn-0-2"},
        "large": {"label": "Large","tnClass": "tn-0-3"}
      },
      "closure": {
        "envelope": {"label": "Envelope","tnClass": "tn-1-0"},
        "zipper": {"label": "Zipper","tnClass": "tn-1-1"},
        "pocket": {"label": "Pocket","tnClass": "tn-1-2"},
        "none": {"label": "None","tnClass": "tn-1-3"}
      }
    }
  },
  "mbag": {
    "label": "Men Bag",
    "category": "mbag",
    "partList" : ["direction", "size", "handle", "style"],
    "defaultVal" : {"size": "small", "handle": "none", "style": "flap", "direction": "wide"},
    "vfSvgSource": require('../assets/svg/common/vf_mbag_core.svg'),
    "tnSprite": require('../assets/svg/common/TN-Men-Bags.svg'),
    "tn": {
      "direction" : {
        "wide": {"label": "Wide", "tnClass": "tn-0-0"},
        "tall": {"label": "Tall", "tnClass": "tn-0-1"}
      },
      "size" : {
        "small": {"label": "Small", "tnClass": "tn-1-0"},
        "medium": {"label": "Medium", "tnClass": "tn-1-1"},
        "large": {"label": "Large", "tnClass": "tn-1-2"}
      },
      "handle": {
        "none": {"label": "None", "tnClass": "tn-2-0"},
        "short": {"label": "Short", "tnClass": "tn-2-1"},
        "medium": {"label": "Medium", "tnClass": "tn-2-2"},
        "long": {"label": "Long", "tnClass": "tn-2-3"}
      },
      "style": {
        "none": {"label": "None", "tnClass": "tn-3-0"},
        "flap": {"label": "Flap", "tnClass": "tn-3-1"},
        "zipper": {"label": "Zipper", "tnClass": "tn-3-2"}
      }
    }
  }
};
