import difference from 'lodash/difference';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { findLabelByLng } from '@yesplz/core';
import client from '@yesplz/client';
import Widget from '@yesplz/visualfilter/src/modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const CONTAINER_CLASS_NAME = `yesplz-brand-filter`;

class MaterialTextList extends Widget {
  defaultParams = {
    fitText: false,
    fitTextMaxSize: 15,
    showResetButton: false,
    filterBy: 'categoryId',
  }

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.textMaterials = [];
    this.labelElements = {};

    this.allMaterials = {};

    this.params.filterByOriginal = this.params.filterBy;
    this.params.filterBy =  ['brands'].includes(this.params.filterBy) ? `params.${this.params.filterBy}` : this.params.filterBy;
  }

  didMount() {
    if (this.params.filterBy === 'resultFilters') {
      this.renderItems();
    }
    else {
      this.fetchAndRender();
    }
  }

  didUpdate(prevState) {
    if (
      this.params.filterBy === 'resultFilters'
      &&
      !isEqual(
        prevState.search?.filters?.materials,
        this.state.search?.filters?.materials
      )
    ) {
      this.renderItems();
    }
    else if (
      this.state.filter.categoryId !== prevState.filter.categoryId
      ||
      !isEqual(prevState.filter.params.subcategory, this.state.filter.params.subcategory)
      ||
      prevState.filter.categorySlice !== this.state.filter.categorySlice
    ) {
      this.fetchAndRender();
    }
    else if (this.state.filter.params.materialText !== prevState.filter.params.materialText) {
      const materialText = this.state.filter.params.materialText;
      const prevMaterialText = prevState.filter.params.materialText;

      const selected = difference(materialText, prevMaterialText);
      const deselected = difference(prevMaterialText, materialText);

      selected.forEach(materialText => {
        const button = this.element.querySelector(`[data-material-text="${materialText}"]`);
        if (button) button.classList.add('is-selected');
      });
      deselected.forEach(materialText => {
        const button = this.element.querySelector(`[data-material-text="${materialText}"]`)
        if (button) button.classList.remove('is-selected');
      });
    }
  }

  get indexId() {
    const { filterBy } = this.params;
    if (filterBy === 'categoryId') {
      const { categoryId, categorySlice, params } = this.state.filter;
      const { subcategory } = params;
      return (subcategory && subcategory.length ? `${subcategory.join('|')}` : categoryId) + (categorySlice ? `*${categorySlice}` : '');
    }
    else {
      const value = get(this.state.filter, filterBy);
      return Array.isArray(value) ? value.join(',') : value;
    }
  }

  get materials() {
    const { categoryId } = this.state.filter;
    const categorySettings = this.main.categories[categoryId];

    if (this.params.filterBy === 'resultFilters' && this.state.search?.filters?.materials) {
      return [
        ...Object.values(this.state.search?.filters?.materials || {}).map(m => ({ ...m, value: m.value, label: m.value })),
        ...(categorySettings.textMaterials || []),
      ]
    }

    const id = this.indexId;

    return [
      ...(this.allMaterials[id] || []).map(m => ({ ...m, value: m.filter })),
      ...(categorySettings.textMaterials || []),
    ];
  }

  async fetchAndRender() {
    const { filterBy } = this.params;
    const id = this.indexId;
    if (!this.allMaterials[id]) {
      this.allMaterials[id] = await (() => {
        if (filterBy === 'categoryId') {
          return client.materialsByCategory(id);
        }
        else {
          return client.materialFilters({
            [this.params.filterByOriginal]: get(this.state.filter, filterBy),
          });
        }
      })();
    }

    this.renderItems();
  }

  handleLabelClick = (e) => {
    const button = e.target;
    const newMaterialText = button.getAttribute('data-material-text');
    const { materialText: currentMaterialText } = this.state.filter.params;

    let materialText = [...(currentMaterialText || [])];

    EventEmitter.emit('textMaterialClick', {
      categoryId: this.state.filter.categoryId,
      material: newMaterialText,
    });

    if (materialText.includes(newMaterialText)) {
      materialText = materialText.filter(c => c !== newMaterialText);

      EventEmitter.emit('textMaterialRemoved', {
        categoryId: this.state.filter.categoryId,
        material: newMaterialText,
      });
    }
    else {
      materialText.push(newMaterialText);

      EventEmitter.emit('textMaterialApplied', {
        categoryId: this.state.filter.categoryId,
        material: newMaterialText,
      });
    }

    this.setFilter({
      params: {
        ...this.state.filter.params,
        materialText,
      },
    });
  }

  renderItems() {
    let materials = this.materials;

    if (
      materials && materials.length
    ) {
      const { lng } = this.state.config;
      const { params } = this.state.filter;

      this.element.className = CONTAINER_CLASS_NAME;
      this.element.innerHTML = '';

      const materialsHtml = materials.map(({ label, value }) => {
        const className = (params.materialText || []).includes(value) ? ` class="is-selected"` : '';
        return `
          <li data-material-text="${value}"${className}>
            ${findLabelByLng(label, lng)}
          </li>
        `;
      });

      const html = `
        <ul>
          ${materialsHtml.join('')}
        </ul>
      `;

      this.element.insertAdjacentHTML('beforeend', html);

      this.element.querySelectorAll('[data-material-text]').forEach(button => {
        button.addEventListener('click', this.handleLabelClick);
      });
    }

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(materials);
    }
  }

  render() {
    this.renderItems();
    return this.element;
  }
}

export default (params) => {
  return new MaterialTextList(params);
};
