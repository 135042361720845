import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';
import Fuse from 'fuse.js';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const { document } = window;

function convertBrands(brands = {}) {
  return sortBy(
    Object.values(brands).map(({ value, isActive }) => ({ label: value, brandFilter: value, isActive })),
    'brandFilter'
  );
}

class BrandFilter extends Widget {
  defaultParams = {
    title: 'Brands',
    displaySearchBar: true,
    displayHeaders: true,
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-brand-filter';
    this.mainElement = element;

    this.brandsByCategories = {};

    this.fuse = null;
    this.ul = document.createElement('ul');
  }

  didMount() {
    this.updateBrands();
  }

  didUpdate(prevState) {
    if (!isEqual(
      convertBrands(prevState.search?.filter?.brandName),
      convertBrands(this.state.search?.filter?.brandName)
    )) {
      this.updateBrands();
    }
    else if (
      !isEqual(prevState.search.brand, this.state.filter.brands)
    ) {
      const prevBrands = prevState.filter.brands;
      const brands = this.state.filter.brands;
      this.updateSelectedBrands(prevBrands, brands);
    }
  }

  updateSelectedBrands(prevBrands, brands) {
    const addedItems = difference(brands, prevBrands);
    const removedItems = difference(prevBrands, brands);
    addedItems.forEach(val => {
      const el = this.mainElement.querySelector(`[data-brand-id="${val}"]`);
      if (!el) return;

      el.classList.add('active');
    });
    removedItems.forEach(val => {
      const el = this.mainElement.querySelector(`[data-brand-id="${val}"]`);
      if (!el) return;

      el.classList.remove('active');
    });

    this.renderSelectedBrands();
  }

  handleItemClick = (e, brand) => {
    e.stopPropagation();
    if (brand.isActive) {
      this.main.toggleBrand(brand.brandFilter);
      EventEmitter.emit(
        this.state.filter.brands.includes(brand.brandFilter)
          ? 'txtSidebarBrandApplied'
          : 'txtSidebarBrandRemoved',
        brand.brandFilter
      );
    }
  }

  removeBrand(brandName) {
    this.main.toggleBrand(brandName);
    EventEmitter.emit('txtSidebarBrandRemoved', brand.brandFilter);
  }

  get indexId() {
    const { categoryId, categorySlice, params } = this.state.filter;
    const { subcategory } = params;
    return (subcategory && subcategory.length ? `${subcategory.join('|')}` : categoryId) + (categorySlice ? `*${categorySlice}` : '');
  }

  updateBrands() {
    const brands = convertBrands(this.state.search?.filter?.brandName);

    this.fuse = new Fuse(brands, {
      includeScore: true,
      includeMatches: true,
      threshold: 0.4,
      keys: ['label'],
    });

    this.mainElement.innerHTML = '';
    this.ul = document.createElement('ul');
    this.selected = document.createElement('div');
    this.selected.classList.add('brands-selected');

    if (
      brands
      &&
      brands.length > 20
      &&
      this.params.displaySearchBar
    ) this.renderSearchBar(brands);

    this.mainElement.appendChild(this.selected);
    this.mainElement.appendChild(this.ul);

    this.renderBrands(brands, this.params.displayHeaders);
    this.renderSelectedBrands();
    setTimeout(() => {
      this.updateSelectedBrands([], this.state.filter.brands);
    }, 10);
  }

  search = (e) => {
    const query = e.target.value;
    const found = this.fuse.search(query);
    const items = found.map(f => f.item);
    if (items.length) {
      this.renderBrands(items);
    }
    else {
      this.renderBrands(this.brandsByCategories[this.indexId], this.params.displayHeaders);
    }
  }

  renderSearchBar(brands) {
    const form = document.createElement('form');
    const input = document.createElement('input');
    input.placeholder = `Search ${brands.length} brands`;
    form.appendChild(input);
    this.mainElement.appendChild(form);

    input.addEventListener('keyup', this.search);
  }

  renderSelectedBrands() {
    const { brands = [] } = this.state.filter;

    this.selected.innerHTML = '';

    if (!brands) return;

    brands.forEach(brand => {
      const element = document.createElement('span');
      element.innerHTML = brand;
      this.selected.appendChild(element);

      element.addEventListener('click', () => this.removeBrand(brand));
    });
  }

  renderBrands(brands, displayHeaders) {
    const { brands: currentBrands = [] } = this.state;

    if (!brands) return;

    this.ul.innerHTML = '';

    let currentLetter = null;
    brands.forEach((brand, refIndex) => {
      const nextLetter = (
        !isNaN(parseInt(brand.label[0], 10)) || brand.label[0].match(/^[^a-zA-Z0-9]+$/)
          ? '#'
          : brand.label[0]
      );
      if (displayHeaders && currentLetter !== nextLetter.toUpperCase()) {
        currentLetter = nextLetter.toUpperCase();
        const li = document.createElement('li');
        li.classList.add('header');
        li.innerHTML = `<span>${currentLetter}</span>`;
        this.ul.appendChild(li);
      }

      const li = document.createElement('li');
      li.innerHTML = `<span>${brand.label}</span>`;
      li.setAttribute('data-brand-id', brand.brandFilter);
      li.setAttribute('data-brand-ref-index', refIndex);
      this.ul.appendChild(li);

      if (currentBrands.includes(brand.brandFilter)) {
        li.classList.add('active');
      }
      if (!brand.isActive) {
        li.classList.add('is-disabled');
      }

      li.addEventListener('click', (e) => this.handleItemClick(e, brand));
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(brands);
    }
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new BrandFilter(params);
};
