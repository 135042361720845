import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

// libs
import PropTypes from 'prop-types'
import classnames from 'classnames'
import queryString from 'query-string'
import omit from 'lodash/omit'

// core web
import { SectionTitle } from '@yesplz/core-web/ui-kits/misc'
import { ProductList } from '@yesplz/core-web/modules/products'
import { ListView } from '@yesplz/core-web/modules/filters'

// core redux
import { fetchPresets, fetchNewProducts } from '@yesplz/core-redux/ducks/products'
import { fetchPresets as fetchPresetsEditorPicks, setFilter, syncFilter } from '@yesplz/core-redux/ducks/filters'

// HOC
import withTrackingProvider from '@yesplz/core-web/hoc/withTrackingProvider'

// constants
import {
  CATEGORY_WSHOES,
  CATEGORY_WTOP
} from '@yesplz/core-web/config/constants'

// utils
import { formatPresetName, parsePresetName } from '@yesplz/core-web/utils/index'
import SidebarProductsFilterList from './SidebarProductsFilterList'

import './ProductsPage.scss'

// onboard tutorial
import { TutorialNewArrival } from 'modules/tutorials'

const defaultProductsCategories = {
  men: 'mshirts', women: 'wtop'
}

class ProductsPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      defaultColType: false,
      menActiveCategory: defaultProductsCategories.men,
      womenActiveCategory: defaultProductsCategories.women,
      type: props.match.params.type,
      viewConfig: false
    }
  }

  componentDidMount () {
    const { syncFilter } = this.props
    syncFilter()
    this.handleFetchPresets()
    this.handleFetchProducts()
  }

  handleChangeActiveCategory = (category) => {
    this.setState({
      [`${this.state.type}ActiveCategory`]: category
    })
  }

  handleFetchPresets = () => {
    if (this.qsValues.preset) {
      const { fetchPresetsEditorPicks, fetchPresets } = this.props
      if (this.qsValues.page === 'editorspick') {
        fetchPresetsEditorPicks(this.currentCategory)
      } else {
        fetchPresets(this.currentCategory)
      }
    }
  }

  handleFetchProducts = () => {
    const { filters, fetchNewProducts } = this.props
    fetchNewProducts(this.currentCategory, filters, 60)
  }

  get qsValues () {
    const { location } = this.props
    const qsValues = queryString.parse(location.search)
    return qsValues
  }

  get productTitle () {
    const { location } = this.props
    const qsValues = queryString.parse(location.search)
    if (!qsValues.page) return 'ALL SEARCH RESULTS'
    switch (qsValues.page) {
      case 'new': {
        return 'ALL NEW ARRIVALS'
      }

      case 'editorspick': {
        return `ALL ${parsePresetName(qsValues.preset)}`
      }

      default: return 'ALL SEARCH RESULTS'
    }
  }

  get currentCategory () {
    const { match } = this.props
    return defaultProductsCategories[match.params.type] || CATEGORY_WTOP
  }

  get isSaleOnly () {
    const { location } = this.props
    const qsValues = queryString.parse(location.search)

    if (qsValues.sale && qsValues.sale === '1') {
      return true
    }

    return false
  }

  get customFilters () {
    const { location, presets } = this.props
    const qsValues = queryString.parse(location.search)
    let options = {
      new: qsValues.page === 'new' ? 1 : 0,
      preset: qsValues.preset ? qsValues.preset : null
    }
    if (qsValues.preset) {
      let presetExtra = presets.find(preset => formatPresetName(preset.name) === qsValues.preset)

      options = {
        ...options,
        ...(presetExtra ? omit(presetExtra, ['name', 'category']) : {})
      }
    }
    return options
  }

  onColTypeChange = data => {
    this.setState({
      defaultColType: data === 'single'
    })
  }

  handleViewConfig = () => {
    this.setState({viewConfig: true})
  }

  handleFilterChange = (filters) => {
    const { setFilter } = this.props
    const activeCategory = this.state[`${this.state.type}ActiveCategory`]

    // set filter to store
    setFilter(activeCategory, filters)


    // fetch products based selected filter
    // fetchProducts(undefined, undefined, undefined, true)
    // set wrapper scrolltop to 0
    // window.scrollTo(0, 0)
  }

  render () {
    const { match, filters, lastBodyPart, onboardDesktop, cats = [] } = this.props
    const activeCategory = this.state[`${this.state.type}ActiveCategory`]
    const activeFiltres = filters[activeCategory].data

    return (
      <div id='MainScroll' className='ProductsPage'>
        <div className='ProductFilters'>
          <SidebarProductsFilterList
            category={activeCategory}
            filters={activeFiltres}
            lastBodyPart={lastBodyPart}
            cats={cats}
            handleChangeActiveCategory={this.handleChangeActiveCategory}
            onFilterChange={this.handleFilterChange}
            viewConfig={this.state.viewConfig}
          />
        </div>
        <div className='Desktop-section'>
          <div className='ProductsPage-HeaderBar'>
            <SectionTitle
              title={this.productTitle}
              withoutContainer
              small
            />
            <div className='GridView'>
              <ListView colType={this.state.defaultColType ? 'single' : 'double'} onChange={this.onColTypeChange} />
            </div>
          </div>
          <div className={classnames('ProductsPage-ProductList', {
            ColTypeDouble: !this.state.defaultColType,
            contained: this.state[`${this.state.type}ActiveCategory`] === CATEGORY_WSHOES
          })}>
            <ProductList
              category={this.state[`${this.state.type}ActiveCategory`]}
              customFilters={activeFiltres}
              location={match.location}
              className='ProductsPage-products'
              style={{ overflow: 'hidden' }}
              salesOnly={this.isSaleOnly}
              showOriginalPrice
              // showHighResImage
              show
              // combined
              useButton
            />
          </div>
        </div>
        { onboardDesktop && <TutorialNewArrival handleViewConfig={this.handleViewConfig} /> }
      </div>
    )
  }
}

ProductsPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  presets: PropTypes.array.isRequired,
  fetchPresets: PropTypes.func.isRequired,
  fetchPresetsEditorPicks: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  syncFilter: PropTypes.func.isRequired,
  fetchNewProducts: PropTypes.func.isRequired,
  filters: PropTypes.object,
  lastBodyPart: PropTypes.string
}

const mapStateToProps = (state, props) => {
  const { location } = props
  const qsValues = queryString.parse(location.search)
  const activeCategory = defaultProductsCategories[props.match.params.type]
  let cats = []

  if (props.match.params.type === 'men') {
    cats = ['mshirts', 'mtshirts', 'mpants', 'mknit', 'mouter', 'mshoes', 'mbag', 'mwallet']
  } else {
    cats = ['wtop', 'wskirt', 'wpants', 'wdress', 'wshoes', 'wbag', 'wearring', 'wouter', 'wknit']
  }

  return {
    cats,
    presets: qsValues.page === 'editorspick' ? state.filters.presets : state.products[activeCategory].presets,
    // filters
    filters: state.filters,
    // filters: state.filters[activeCategory].data,
    lastBodyPart: state.filters.lastBodyPart,
    onboardDesktop: state.filters.onboardDesktop
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      fetchPresets,
      fetchPresetsEditorPicks,
      setFilter,
      syncFilter,
      fetchNewProducts
    }
  ),
  withTrackingProvider('ProductsPage')
)(ProductsPage)
