import Handlebars from 'handlebars';
import { findLabelByLng } from '@yesplz/core';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import find from 'lodash/find';

const { document } = window;

const DEFAULT_LAYOUT = `
  {{#each items}}
    <a href="#" data-sort="{{this.value}}">{{this.label}}</a>
  {{/each}}
`;

class SortBy extends Widget {
  defaultParams = {
    containerClassName: '',
    mainElementTag: 'span',
    activeClass: 'is-active',
    items: [
      { label: 'From lowest price', value: 'price:asc' },
      { label: 'Name', value: 'name' },
      { label: 'Newest', value: 'newest' },
    ],
    templates: {
      layout: DEFAULT_LAYOUT,
    },
    onRendered: () => {},
  };

  constructor(params) {
    super(params);

    this.layoutTemplate = Handlebars.compile(this.params.templates.layout);

    const element = document.createElement(this.params.mainElementTag);
    element.className = this.params.containerClassName;
    this.mainElement = element;
  }

  didMount() {
    this.mainElement.querySelectorAll('[data-sort]')
      .forEach(element => {
        const sort = element.getAttribute('data-sort');
        element.addEventListener('click', () => this.setSort(sort));
      });

    if (this.state.filter.sort) this.toggleSelected(this.state.filter.sort);
  }

  didUpdate(prevState) {
    const { sort: prevSort } = prevState.filter;
    const { sort } = this.state.filter;
    if (sort !== prevSort) {
      if (prevSort) this.toggleSelected(prevSort);
      if (sort) this.toggleSelected(sort);
    }
  }

  setSort(sort) {
    this.setFilter({
      sort,
    });
  }

  toggleSelected(sort) {
    const sortElement = this.mainElement.querySelector(
      `[data-sort="${sort}"]`
    );
    if (sortElement) {
      sortElement.classList.toggle(this.params.activeClass);
    }
  }

  render() {
    const { lng } = this.state.config;

    const activeItem = find(this.params.items, { value: this.state.filter.sort });
    this.mainElement.innerHTML = this.layoutTemplate({
      items: this.params.items.map(i => ({
        ...i,
        label: findLabelByLng(i.label, lng),
      })),
      activeItem,
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(
        this.params.items,
        activeItem
      );
    }

    return this.mainElement;
  }
}

export default (params) => {
  return new SortBy(params);
};
