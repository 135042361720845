import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { findIndex } from 'lodash'
import { Product, ProductPlaceholder, ProductGrid } from '@yesplz/core-web/modules/products'
import { fetchProduct, fetchRelatedProducts, resetProduct, setScrollBellowTheFold } from '@yesplz/core-redux/ducks/product'
import history from '@yesplz/core-web/config/history'
import { DotLoader } from '@yesplz/core-web/ui-kits/loaders'
import { SectionTitle } from '../../ui-kits/misc'
import { withTrackingProvider } from '../../hoc'
import { IS_MOBILE } from '../../config/constants'

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button } from '@yesplz/core-web/ui-kits/buttons'

// Redux
import { setActiveCategory } from '@yesplz/core-redux/ducks/products'

import './product-page.scss'

const MAX_YML_COUNT = 60;

class ProductPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    isProductFetched: PropTypes.bool,
    isRelatedProductsFetched: PropTypes.bool,
    productId: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    relatedProducts: PropTypes.array.isRequired,
    totalCount: PropTypes.number.isRequired,
    nextOffset: PropTypes.number,
    scrollBellowTheFold: PropTypes.bool,
    showArrows: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
    renderExtraItem: PropTypes.func,
    fetchProduct: PropTypes.func.isRequired,
    fetchRelatedProducts: PropTypes.func.isRequired,
    resetProduct: PropTypes.func.isRequired,
    setScrollBellowTheFold: PropTypes.func.isRequired,
    setActiveCategory: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this._scrollBarRef = null;
  }

  static defaultProps = {
    renderExtraItem: () => (null)
  }

  componentDidMount () {
    this.fetchData();
  }

  componentDidUpdate (prevProps) {
    const { resetProduct } = this.props;
    // if productId changed, fetch new product and related product data
    if (prevProps.productId !== this.props.productId) {
      resetProduct();
      this.fetchData();
    }
  }

  componentWillUnmount () {
    const { resetProduct } = this.props
    // cancel requests
    this.productRequest.cancel()
    this.relatedsRequest.cancel()

    // reset store data
    resetProduct()
  }

  fetchData() {
    const { productId, fetchProduct, fetchRelatedProducts } = this.props;
    this.productRequest = fetchProduct(productId);
    this.relatedsRequest = fetchRelatedProducts(productId);
    this.scrollInToView();
  }

  fetchMoreRelatedProducts = () => {
    const { productId, fetchRelatedProducts } = this.props;
    this.relatedsRequest = fetchRelatedProducts(productId);
  }

  get activeCategory () {
    return this.props.match.params.category
  }

  /**
   * only applicable on next fetch, if available
   */
  get handleFetchNext () {
    const { productId, relatedProducts, fetchRelatedProducts, totalCount } = this.props
    return () => {
      if (relatedProducts.length < totalCount) {
        // fetch next related products
        this.relatedsRequest = fetchRelatedProducts(productId)
        return this.relatedsRequest
      }
    }
  }

  get handleScrollBellowTheFold () {
    const { scrollBellowTheFold, setScrollBellowTheFold } = this.props
    return (scrollState) => {
      // boolean can only be compared by casting it to string (js)
      if (scrollState.toString() !== scrollBellowTheFold.toString()) {
        history.push('#')
        setScrollBellowTheFold(scrollState)
      }
    }
  }

  scrollInToView = () => {
    if (process.env.REACT_APP_IS_MOBILE === 'true') {
      // setTimeout(() => {
      //   let idElement = process.env.REACT_APP_IS_MOBILE === 'true' ? 'Base-mobile' : 'ProductPage-desktop'
      //   ReactDOM.findDOMNode(document.getElementById(idElement)).scrollIntoView(0, 0)
      // }, 200)
    }
  }

  getRelatedProducts = () => {
    const { product, relatedProducts } = this.props
    const products = [...relatedProducts]
    const id = findIndex(relatedProducts, p => p.product_id === product.product_id)
    if (id) {
      products.splice(id, 1)
    }
    return products
  }

  render () {
    const {
      product, isProductFetched, relatedProducts, categoryId,
      totalCount, renderExtraItem, showArrows, className
    } = this.props;
    const images = product.variants
      ? product.variants[0].images.map(imgObj => imgObj.src_uri)
      : [];

    let productBox = <ProductPlaceholder />

    if (isProductFetched) {
      const ymlTitle = <>You May Also Like <span>powered by <a href="https://yesplz.ai">YesPlz.AI</a></span></>;
      productBox = (
        <div className='ProductPage-top-wrapper'>
          {renderExtraItem(this)}
          <Product
            id={product.product_id}
            categoryId={categoryId}
            name={product.product_name}
            brand={product.brand_name}
            price={product.sale_price}
            originalPrice={product.retail_price}
            imgSrc={images[0]}
            extraImgs={images}
            thumbnailImgs={images}
            description={product.description}
            favorite={product.favorite}
            link={product.product_url}
            retailer={product.retailer}
            sizes={product.sizes}
            all_sizes={product.all_sizes}
            extraInfo={product.extra_info}
            rawData={product}
            showArrows={showArrows}
            category={product.retailer_category[0]}
            showDots
          />
          {
            process.env.REACT_APP_IS_MOBILE === 'true'
              ? <SectionTitle
                title={ymlTitle}
                style={{ marginTop: 15, marginBottom: 30 }}
              /> : <div className='SectionTitle container'>
                <div className='title'>{ymlTitle}</div>
              </div>
          }
        </div>
      )
    }

    const ymlMaxCount = totalCount < MAX_YML_COUNT ? totalCount : MAX_YML_COUNT;

    const fullContent = (
      <div id={this.props.id} className={`ProductPage ${className}`}>
        {productBox}
        {
          relatedProducts.length
            ? <div className="container">
                <div className="ProductList-wrapper">
                  {relatedProducts.map((product, index) => (
                    <ProductGrid
                      key={`${index}${product.product.product_id}`}
                      {...{
                        categoryId,
                        id: product.product.product_id,
                        brand: product.product.brand_name,
                        name: product.product.product_name,
                        imgSrc: product.product.front_img_src,
                        price: product.product.price,
                        originalPrice: product.product.original_price,
                      }}
                    />
                  ))}
                </div>

                {
                  relatedProducts.length < ymlMaxCount
                  &&
                  <Button kind='secondary' className="Button-showMore" onClick={this.fetchMoreRelatedProducts}>
                    Show more
                  </Button>
                }

                <Button kind='borderless' className="Button-backToTop" onClick={() => {
                  if (IS_MOBILE) {
                    window.scrollTo(0, 0);
                  }
                  else {
                    this._scrollBarRef._container.scrollTop = 0
                  }
                }}>
                  Back to top
                </Button>
              </div>
            : <DotLoader visible />
        }
      </div>
    );

    if (IS_MOBILE) {
      return fullContent;
    }

    return (
      <PerfectScrollbar
        className='ProductsList'
        ref={(ref) => { this._scrollBarRef = ref }}
      >
        {fullContent}
      </PerfectScrollbar>
    )
  }
}

const mapStateToProps = (state, props) => ({
  categoryId: props.match.params.categoryId,
  product: state.product.data,
  productId: props.match.params.productId,
  isProductFetched: state.product.fetched,
  isRelatedProductsFetched: state.product.relatedProductsFetched,
  relatedProducts: state.product.relatedProducts,
  totalCount: state.product.totalCount,
  nextOffset: state.product.nextOffset,
  scrollBellowTheFold: state.product.scrollBellowTheFold
})

const mapPropsToTrackingProps = (props) => ({
  product_id: props.match.params.productId,
  preset: props.match.params.presetName
})

export default compose(
  connect(
    mapStateToProps,
    {
      fetchProduct,
      fetchRelatedProducts,
      resetProduct,
      setScrollBellowTheFold,
      setActiveCategory
    }
  ),
  withTrackingProvider('Product Detail', mapPropsToTrackingProps)
)(ProductPage)
