import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import includes from 'lodash/includes'
import { ProductListCorp, ProductGridCorp } from '@yesplz/core-web/modules/products'
import withTrackingProvider from '@yesplz/core-web/hoc/withTrackingProvider'
import ProductsNotFound from '@yesplz/core-web/modules/products/ProductsNotFound'
// import { HelpButton } from '@yesplz/core-web/ui-kits/buttons';
import './ProductsCorpPage.scss'

import Slider from 'react-slick'

import { categories } from 'config/categories';

import visualfilter from '@yesplz/visualfilter';
import '@yesplz/visualfilter/src/styles/styles.scss';

const availableCategories = ['wtop']
const devices = ['desktop', 'mobile']

class ProductsCorpPage extends PureComponent {
  state = {
    defaultColType: false,
    products: [],
  }

  static propTypes = {
    match: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.visualFilter = null;
  }

  componentDidMount () {
    this.renderVisualFilter();

    if (!includes(devices, this.device) || !includes(availableCategories, this.categoryId)) {
      this.props.history.push('/not-found')
    }
  }

  componentWillUnmount() {
    this.visualFilter.off('searchFinished', this.handleSearchFinished);
  }

  get device () {
    return this.props.match.params.device
  }

  get categoryId () {
    return this.props.match.params.categoryId
  }

  get sliderSettings () {
    return {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2.3,
      slidesToScroll: 1
    }
  }

  handleSearchFinished = (results) => {
    this.setState({
      products: results.results,
    });
  }

  getSlides () {
    const { products } = this.state
    return products.map((product) => {
      const {
        frontImg: imgSrc,
        brandName: brand,
        price,
        productId: id
      } = product.product

      return (
        <div
          className='product-list-corp__item-container'
          key={id}
        >
          <ProductGridCorp
            imgSrc={imgSrc}
            brand={brand}
            price={price}
          />
        </div>
      )
    })
  }

  toggleTooltips = () => {
    this.visualFilter.toggleTooltips();
  }

  renderVisualFilter() {
    const vs = visualfilter({
      categories,
      searchFinishedReturnFields: null,
      usePersistentFilter: true,
      initialFilter: {
        categoryId: this.categoryId,
        limit: this.device === 'desktop' ? 4 : 8,
      },
    });

    vs.addWidget(
      vs.widgets.VisualFilter({
        container: "#visual-filter",
        svgViewBox: [0, 0, 362, 254],
        svgViewBoxMobile: [0, 0, 362, 254],
        svgScale: 1,
        presetNavigation: false,
        showTooltipsToggler: false,
        showResetButton: false,
        showParamTags: true,
        useOnboarding: true,
      })
    );

    vs.addWidget(
      vs.widgets.StyleFilter({
        container: '#visual-filter-style',
        title: 'Design',
      })
    );
    vs.on('searchFinished', this.handleSearchFinished);
    this.visualFilter = vs;
  }

  render () {
    const { products } = this.state;
    return (
      <div className={classnames('products-corp-page', {
        mobile: this.device === 'mobile'
      })}
      >
        <div className='products-corp-page__filtres'>
          <div className='products-corp-page__header'>
            <h2
              className='products-corp-page__title-filter'
            >
              TOPS
            </h2>
            {/* <HelpButton onClick={this.toggleTooltips} /> */}
          </div>
          <div id='MainScroll'>
            <div
              id="visual-filter"
              className='products-corp-page__vf-container'
            />
            <div
              id="visual-filter-style"
              className='products-corp-page__advanced-container'
            />
          </div>
        </div>
        <div className='products-corp-page__line' />
        <div className='products-corp-page__products'>
          <h2
            className='products-corp-page__title-products'
          >
            RESULTS
          </h2>
          {(this.device === 'desktop') && <ProductListCorp products={products} />}
          {(this.device === 'mobile') &&
            <Slider {...this.sliderSettings}>
              {this.getSlides()}
            </Slider>
          }
          {!products.length && <ProductsNotFound />}
        </div>
      </div>
    )
  }
}

export default withTrackingProvider('ProductsCorpPage')(ProductsCorpPage)
