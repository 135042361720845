/* eslint-disable react/prop-types */
import React from 'react'
import { withRouter } from "react-router";
import classnames from 'classnames';
import './DropDown.scss'

class Dropdown extends React.PureComponent {
  state = {
    showList: false
  }

  hanldeClick (option) {
    this.setState({ showList: false });

    this.props.onChange(option);
  }

  render () {
    const { showList } = this.state;
    const { value, options, defaultLabel } = this.props;
    const selectedValue = value ? options.filter(o => o.value === value).shift() : null;

    return <div className="ProductsDropDown">
      <div className={classnames('ProductsDropDown__btn', {
        active: showList,
      })} onClick={() => {
        this.setState({ showList: !showList });
      }}>
        {selectedValue && selectedValue.label}
        {!selectedValue && defaultLabel} <span />
      </div>
      {showList && <ul className='ProductsDropDown__list'>
        {options.map((option) => {
          const { label, value } = option;
          return (
            <li key={value} value={value}>
              <a href="#product" onClick={(e) => {
                e.preventDefault();
                this.hanldeClick(option);
              }}>
                {label}
              </a>
            </li>
          )
        })
        }
      </ul>}
    </div>
  }
}

export default withRouter(Dropdown)
