import Handlebars from 'handlebars';
import { findLabelByLng } from '@yesplz/core';
import Widget from '../modules/Widget';
import { store } from '../store';
import { setConfigValue } from '../store/actions/configActions';

const { document } = window;

export class TreeViewMenu extends Widget {
  defaultParams = {
    breadcrumbsContainer: null,
    templates: {
      wrapper: '{{{text}}}',
      breadcrumbsItem: '<a href="#" data-category-id="{{{categoryId}}}">{{label}}</a>',
      breadcrumbsSeparator: ' > ',
    }
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-tree-view';
    this.mainElement = element;

    this.visualFilterContainer = document.querySelector(
      this.params.visualFilterContainer
    );

    this.breadcrumbsIndex = null;
    this.breadcrumbsContainer = params.breadcrumbsContainer
      ? document.querySelector(params.breadcrumbsContainer)
      : null;

    this.breadcrumbsItemTemplate = Handlebars.compile(
      this.params.templates.breadcrumbsItem
    );
    this.wrapperTemplate = Handlebars.compile(
      this.params.templates.wrapper
    );
  }

  didMount() {
    this.updateSelected();
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.renderBreadcrumbs();
      this.updateSelected();
    }
  }

  setCategoryId(categoryId) {
    if (categoryId && this.main.categories[categoryId]) {
      this.main.setCategory(categoryId);
      this.showVisualFilter();
    }
    else {
      this.setFilter({
        categoryId: categoryId,
        category: null,
        bodyPart: null,
        params: {},
        offset: 0,
        limit: this.state.filter.limit,
      });
      this.hideVisualFilter();
    }
  }

  handleItemClick = (e, item) => {
    e.stopPropagation();

    this.setCategoryId(item.categoryId);

    if (typeof this.params.onItemClick === 'function') {
      this.params.onItemClick(item);
    }

    store.dispatch(setConfigValue({
      key: 'layoutTitle',
      value: item.label,
    }));
  }

  showVisualFilter() {
    if (!this.visualFilterContainer) return;

    this.visualFilterContainer.style.display = 'block';
  }

  hideVisualFilter() {
    if (!this.visualFilterContainer) return;

    this.visualFilterContainer.style.display = 'none';
  }

  buildBreadcrumbsIndex() {
    this.breadcrumbsIndex = {};

    const buildBreadcrumbs = (items, prefix) => {
      items.forEach(item => {
        const itemHtml = this.breadcrumbsItemTemplate({
          ...item,
          label: findLabelByLng(item.label, this.state.config.lng),
        });
        this.breadcrumbsIndex[item.categoryId] = prefix + itemHtml; // + (item.showAll ? ' > 전체' : '');

        if (item.children) {
          buildBreadcrumbs(
            item.children,
            `${prefix}${itemHtml}${this.params.templates.breadcrumbsSeparator}`
          )
        }
      });
    }

    buildBreadcrumbs(this.params.items, '');
  }

  renderBreadcrumbs() {
    if (!this.breadcrumbsContainer) return;

    const { categoryId } = this.state.filter;    
    if (!this.breadcrumbsIndex) {
      this.buildBreadcrumbsIndex();
    }

    if (!this.breadcrumbsIndex[categoryId]) {
      this.breadcrumbsContainer.innerHTML = '';
      return;
    }

    this.breadcrumbsContainer.innerHTML = this.wrapperTemplate({
      text: findLabelByLng(this.breadcrumbsIndex[categoryId], this.state.config.lng),
    });

    this.breadcrumbsContainer.querySelectorAll('[data-category-id]')
      .forEach(node => node.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        const categoryId = e.target.getAttribute('data-category-id');
        if (categoryId) {
          this.setCategoryId(categoryId);
        }
      }))
  }

  activateItemAndParents(currentLi, baseLastParentFound) {
    if (!currentLi) return;

    let lastParentFound = baseLastParentFound;
    currentLi.classList.add('active');

    if (!lastParentFound) {
      const nestedList = currentLi.querySelector('ul');
      if (nestedList) {
        currentLi.classList.add('last-active-parent');
        lastParentFound = true;
      }
    }

    const listParent = currentLi.parentElement.parentElement;
    if (listParent.tagName === 'LI') {
      this.activateItemAndParents(listParent, lastParentFound);
    }
  }

  updateSelected() {
    const { categoryId } = this.state.filter;
    const oldActives = this.mainElement.querySelectorAll('li.active');
    oldActives.forEach(li => {
      li.classList.remove('active');
      li.classList.remove('last-active-parent');
    });

    const li = this.mainElement.querySelector(`[data-tree-item-catid="${categoryId}"]`);
    // li.classList.add('last-active-parent');

    this.activateItemAndParents(li, false);
  }

  renderTree(items) {
    const ul = document.createElement('ul');
    items.forEach(item => {
      const li = document.createElement('li');
      li.innerHTML = `<span>${findLabelByLng(item.label, this.state.config.lng)}</span>`;
      if (item.categoryId) {
        li.setAttribute('data-tree-item-catid', item.categoryId);
      }
      if (Array.isArray(item.children) && item.children.length) {
        li.appendChild(this.renderTree(item.children));
      }
      ul.appendChild(li);

      li.querySelector('span')
        .addEventListener('click', (e) => this.handleItemClick(e, item));
    });

    return ul;
  }

  render() {
    this.mainElement.appendChild(this.renderTree(this.params.items));
    this.renderBreadcrumbs();
    return this.mainElement;
  }
}

export default (params) => {
  return new TreeViewMenu(params);
};
